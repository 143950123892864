<h4 class="display-5">Mantenimiento de Auxiliares</h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <div class="form-row">
        <div class="form-group col-md-3">
            <label>Material ID</label>
            <input name="AuxMatID" #AuxMatID="ngModel" [(ngModel)]="auxiliaryService.auxiliaryList.AuxMatID" class="form-control"
            readonly>  
        </div>
        <div class="form-group col-md-3">
            <label>Nombre</label>
            <input name="AuxMatName" id="AuxMatName" #AuxMatName="ngModel" [(ngModel)]="auxiliaryService.auxiliaryList.AuxMatName" 
            class="form-control" autofocus>  
        </div>
        <div class="form-group col-md-3">
            <label>Consumo</label>
            <input name="AuxMatRatCon" #AuxMatRatCon="ngModel" [(ngModel)]="auxiliaryService.auxiliaryList.AuxMatRatCon" 
            class="form-control" type="number" min="0" style="text-align: right;">  
        </div>
        <div class="form-group col-md-3">
            <label>Unidad Consumo</label>
            <input name="AuxMatUniCon" #AuxMatUniCon="ngModel" [(ngModel)]="auxiliaryService.auxiliaryList.AuxMatUniCon" 
            class="form-control">  
        </div>
    </div>  

    <div class="form-row">
        <div class="form-group col-md-3">
            <label>Costo</label>
            <input name="AuxMatCost" #AuxMatCost="ngModel" [(ngModel)]="auxiliaryService.auxiliaryList.AuxMatCost" 
            class="form-control" type="number" min="0" style="text-align: right;">  
        </div>
        <div class="form-group col-md-3">
            <label>Unidad Costo</label>
            <input name="AuxMatCostUni" #AuxMatCostUni="ngModel" [(ngModel)]="auxiliaryService.auxiliaryList.AuxMatCostUni" 
            class="form-control">  
        </div>
        <div class="form-group col-md-3">
            <label>Desperdicio %</label>
            <div class="input-group">
                
                <input name="AuxMatWaste" #AuxMatWaste="ngModel" [(ngModel)]="auxiliaryService.auxiliaryList.AuxMatWaste" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">%</div>
                </div> 
            </div>
        </div>
        <div class="form-group col-md-3">
            <label>Vida Útil (mes)</label>
            <input name="AuxLifeTime" #AuxLifeTime="ngModel" [(ngModel)]="auxiliaryService.auxiliaryList.AuxLifeTime" 
            class="form-control" type="number" min="0" placeholder="aplica para herramentales" style="text-align: right;">  
        </div>
    </div>

    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i> Guardar</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i> Cerrar</button>
    </div>
</form>
 

