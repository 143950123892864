import { Component, OnInit } from '@angular/core';
import { QuoteService } from 'src/app/shared/quote.service';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { QuoteItemsComponent } from '../quote-items/quote-items.component';
import { QuoteSheetComponent } from '../quote-items/quote-sheet.component';
import { CustomerService } from 'src/app/shared/customer.service';
import { Customer } from 'src/app/shared/customer.model';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Destiny } from 'src/app/shared/destiny.model';
import { DestinyService } from 'src/app/shared/destiny.service';
import { InterestrateService } from 'src/app/shared/interestrate.service';
import { Interestrate } from 'src/app/shared/interestrate.model';
import { TruckType } from 'src/app/shared/TruckType.model';
import { TruckTypeService } from 'src/app/shared/TruckType.service';
import { Freight } from 'src/app/shared/freight.model';
import { FreightService } from 'src/app/shared/freight.service';
import { Board } from 'src/app/shared/board.model';
import { BoardService } from 'src/app/shared/board.service';
import { Boardcost } from 'src/app/shared/boardcost.model';
import { BoardcostService } from 'src/app/shared/boardcost.service';
import { WorkstationService } from 'src/app/shared/workstation.service';
import { Workstation } from 'src/app/shared/workstation.model';
import { Product } from 'src/app/shared/product.model';
import { ProductService } from 'src/app/shared/product.service';
import { CostrateService } from 'src/app/shared/costrate.service';
import { Costrate } from 'src/app/shared/costrate.model';
import { AuxiliaryService } from 'src/app/shared/auxiliary.service';
import { Auxiliary } from 'src/app/shared/auxiliary.model';
import { InkService } from 'src/app/shared/ink.service';
import { Ink } from 'src/app/shared/ink.model';
import { exit } from 'process';
import { PalletService } from 'src/app/shared/pallet.service';
import { Pallet } from 'src/app/shared/pallet.model';
import { QuoteKitsComponent } from '../quote-items/quote-kits.component';
import { Button } from 'protractor';
import { DialogConfirm } from "../quote/confirm/dialog-confirm-component";


@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styles: []
})
export class QuoteComponent implements OnInit {
  customerList : Customer [];
  destinyList: Destiny [];
  truckTypeList: TruckType [];
  palletList: Pallet [];
  freightList: Freight [];
  //capacitytruckList: Capacitytruck [];
  capacityTruck: number;
  interestrateList: Interestrate [];
  boardList: Board [];
  boardQuote: Board [];
  boardCost: Boardcost;
  workstationList: Workstation [];
  productList: Product [];
  costrateList: Costrate [];
  auxiliaryList: Auxiliary [];
  inkList: Ink [];
  isValid: boolean = true;
  averageOrder: number;
  isBoxOrSheet: boolean = true;
  dateQuote: Date = new Date();

  
  constructor(public service: QuoteService, private dialog: MatDialog,
     private customerService: CustomerService, private destinyService: DestinyService,
     public trucktypeService: TruckTypeService, private palletService: PalletService, 
     private freightService: FreightService, private workstationService: WorkstationService,
     private boardService: BoardService, private auxiliaryService: AuxiliaryService,
     private boardcostService: BoardcostService, private inkService: InkService, 
     private interestrateService: InterestrateService, private toastr: ToastrService, 
     private productService: ProductService, private costrateService: CostrateService,
     private router: Router, private currentRoute: ActivatedRoute) { }

  ngOnInit(): void {
    //this.onviewBoxSheetBotton();
    let quoteID = this.currentRoute.snapshot.paramMap.get('id');
    if(quoteID == null)
      this.resetForm();
    else {
      this.service.getQuoteByID(parseInt(quoteID)).then(res => {
        this.service.formData = res.quote;
        this.service.quoteItems = res.quoteDetails;
        this.boardcostService.getBoardCostListId(this.service.quoteItems[0].BoardID).subscribe((boardCosts: Boardcost) => {
          this.boardCost = boardCosts, this.service.formData.MonthlyOrderTon = parseFloat((this.service.formData.MonthlyOrder * this.service.quoteItems[0].BoxWeight / 1000000).toFixed(2)), this.service.originalQuantityShipment = this.service.formData.BoxShipment;
          this.service.originalCapacityTruck = this.service.formData.UsedCapacityTruck;
          //this.onContactName(); 
          this.service.formData.BoardID = this.service.quoteItems[0].BoardID;}, error => console.error(error));
      });
    };
    if(quoteID == null){  
      this.service.viewBoxSheetBotton = true;
    } else{
      this.service.viewBoxSheetBotton = false;
    };
    this.customerService.getCustomerList().then(res => this.customerList = res as Customer[]);
    this.destinyService.getDestinyList().then(res => this.destinyList = res as Destiny[]);
    this.trucktypeService.getTruckTypeList().then(res => this.truckTypeList = res as TruckType[]);
    this.palletService.getPalletList().then(res => this.palletList = res as Pallet[]);
    this.interestrateService.getInterestRateList().then(res => this.interestrateList = res as Interestrate[]);
    this.boardService.getBoardList().then(res => this.boardList = res as Board[]);
    this.auxiliaryService.getAuxiliaryList().then(res => this.auxiliaryList = res as Auxiliary[]);
    this.inkService.getInkList().then(res => this.inkList = res as Ink[]);
    this.workstationService.getWorkstationList().then(res => this.workstationList = res as Workstation[]);
    this.productService.getProductList().then(res => this.productList = res as Product[]);
    this.costrateService.getCostRateList().then(res => this.costrateList = res as Costrate[]);
    this.service.getQuoteMax().then(res => this.service.numOpenNewQuote = parseInt(res.toString()));

    //this.onviewBoxSheetBotton();
    
    this.service.boardflute = this.boardList.find(boardFlute => boardFlute.BoardID == this.service.quoteItems[0].BoardID).BoardFlute;
    this.boardService.getBoardListBoardFluteAlternative(this.service.boardflute, "quoteSales", 1).then(res => this.boardQuote = res as Board[]);

    this.freightService.getFreightList(1, 1).subscribe((freights: Freight[]) => {
      this.freightList = freights}, error => console.error(error));

    /* this.capacitytruckService.getCapacityTruckList(1, 1, 1).subscribe((capacitytrucks: Capacitytruck[]) => {
      this.capacitytruckList = capacitytrucks}, error => console.error(error)); */
    
      //this.onContactName();
      this.onQuoteBoxOrSheet();
  };

  resetForm(form?: NgForm){
    if (form = null)
    form.resetForm();
    this.service.formData = {
      QuoteID: null,
      QuoteNumber: Math.floor(1000000 + Math.random()*9000000).toString(),
      QuoteDate: this.dateQuote,
      CustomerID: 0,
      CustomerName:"",
      CustomerSKU: '',
      DestinyID: 0,
      TruckID: 0,
      CargoType: 'Granel',
      PalletID: 0,
      PalletLength: 0,
      PalletWidth: 0,
      PalletHeight: 0,
      PalletProduced: 0,
      PalletNumber: 0,
      BundleVolum: 0,
      BundlePallet: 0,
      IdealTruck: '',
      UsedCapacityTruck: 0,
      RateID: 3,
      Commission: 1,
      AverageOrder: 10000,
      MonthlyOrder : 10000,
      BoxShipment : 10000,
      FreightCost: 0,
      SaleCommission: 0,
      FinancialExpense: 0,
      ManufacturingCost: 0,
      TotalCost: 0,
      CostMBox: 0,
      CostMM2: 0,
      CostTon: 0,
      TargetProfit: 0,
      PriceWithoutFreight: 0,
      PriceWithFreight: 0,
      ProfitWithoutFreight: 0,
      ProfitWithFreight: 0,
      PriceMBox : 0,
      PriceMM2 : 0,
      PriceTon : 0,
      DeletedQuoteItemIDs:'',
      State: 0,
      TruckName: '',
      Rate: 0,
      ContactName: '',
      MonthlyOrderTon : 0,
      //search
      CustomerFilter: '',
      CustomerSKUFilter: '',
      //only calculating
      BoardID: '',
      AddedValue: 0,
      ContributionMargin: 0,
    }
    this.service.quoteItems=[];
    
    //this.onQuoteBoxOrSheet();
  };

  AddOrEditQuoteItem(quoteItemIndex, QuoteID){
    this.onTypeCargoTarima();
    this.onviewLayer();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="100%";
    dialogConfig.height="95%";
    dialogConfig.data = { quoteItemIndex, QuoteID }
    
    this.service.NewOrEdit = true;
    this.dialog.open(QuoteItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.updateGrandTotal(); 
      //this.onFreightList(); 
      //actualización del flete
      if(this.service.NewOrEdit==true){
        this.onFreightList();
      }
      this.averageOrder = this.service.formData.AverageOrder;
    });   
    
    
    //test
   /* if (this.service.quoteItems.length == 0){
      this.dialog.open(QuoteItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
        this.updateGrandTotal(); this.onFreightList(); this.averageOrder = this.service.formData.AverageOrder;
      });
    } else {
      this.service.NewOrEdit = true;
      if (this.isBoxOrSheet == true && this.service.quoteItems[0].StyleID != 6){
        this.dialog.open(QuoteItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
          this.updateGrandTotal(); this.onFreightList(); this.averageOrder = this.service.formData.AverageOrder;
        });
      } else {
        dialogConfig.height="65%";
        this.dialog.open(QuoteSheetComponent, dialogConfig).afterClosed().subscribe(res =>{
          this.updateGrandTotal(); this.onFreightList(); this.averageOrder = this.service.formData.AverageOrder;
        });
      };
    };*/
  };

  AddOrEditQuoteSheet(quoteItemIndex, QuoteID){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="100%";
    dialogConfig.height="65%";
    dialogConfig.data = { quoteItemIndex, QuoteID }
    this.dialog.open(QuoteSheetComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.updateGrandTotal(); this.onFreightList();; this.averageOrder = this.service.formData.AverageOrder;
    });
    this.isBoxOrSheet = false;
  };

  
//test 19/11/2023
  AddOrEditQuoteKit(quoteItemIndex, QuoteID){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="100%";
    dialogConfig.height="95%";
    dialogConfig.data = { quoteItemIndex, QuoteID }
    this.dialog.open(QuoteKitsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.updateGrandTotal(); this.onFreightList();; this.averageOrder = this.service.formData.AverageOrder;
    });
    //this.isBoxOrSheet = false;
  };
//end test

//test edit sheet and boxes
  onEditSheetBox(quoteItemIndex, QuoteID){
    if (this.service.quoteItems[0].StyleID == 6){
      this.AddOrEditQuoteSheet(quoteItemIndex, QuoteID)
    } else {
      this.AddOrEditQuoteItem(quoteItemIndex, QuoteID)
    }
  }

  onDeleteQuoteItem(quoteItemID: number, i: number){
    if(quoteItemID != null)
    this.service.formData.DeletedQuoteItemIDs += quoteItemID + ",";
    this.service.quoteItems.splice(i,1);
    this.updateGrandTotal();
  };

  updateGrandTotal(){
    this.service.formData.ManufacturingCost = this.service.quoteItems.reduce((prev, curr) => {
      return prev + curr.Total;
    }, 0);
    
    this.service.formData.ManufacturingCost = parseFloat(this.service.formData.ManufacturingCost.toFixed(2));
  };

  validateForm(){
    this.isValid = true;
    if (this.service.formData.CustomerID == 0)
      this.isValid=false;
    else if (this.service.quoteItems.length == 0)
      this.isValid=false;
    return this.isValid;
  };

  onsaveQuote(){
    if (this.validateForm()){
      if(this.service.quoteItems[0].PlateDie == false){
        this.service.quoteItems[0].CostDie = 0;
        this.service.quoteItems[0].CostPlate = 0;
      }
      if(this.service.formData.CustomerSKU == ""){
        this.service.formData.CustomerSKU = this.service.quoteItems[0].StyleName;
      }
      //this.onTypeCargo()
      this.service.saveOrUpdateQuote().subscribe(res => {
        this.resetForm();
        this.toastr.success('Grabado Exitosamente!', 'Corrugated Quote.');
        this.router.navigate(['./quotes']);
      });
    };
  };

  onSubmit(form: NgForm){
    if(this.service.formData.UsedCapacityTruck > 100){
        this.toastr.warning('Por favor seleccionar un transporte de mayor capacidad o disminuir la cantidad por embarque.', 'Corrugated Quote.');
    }else {
      this.onsaveQuote();
    };
  };

  updateState(ctrl){
    if (ctrl.selectedIndex == 0) {
      this.service.formData.State = 0;
    } 
    else {
      this.service.formData.State = this.destinyList[ctrl.selectedIndex-1].StateID;
      this.onFreightList();
    }
  }

  updateFreight(ctrl){
    if (ctrl.selectedIndex == 0) {
      this.service.formData.FreightCost = 0;
    } 
    else {
      this.onFreightList();
      //let volIdealTruck: number; let volTruckID: number;
      //volIdealTruck = this.truckTypeList[].CapacityM3
      //volTruckID = this.truckTypeList.find(voltruckID => voltruckID.TruckID == this.service.formData.TruckID).CapacityM3;
      //volIdealTruck = this.truckTypeList.find(volidealTruck => volidealTruck.TruckName == this.service.formData.IdealTruck).CapacityM3;
      //this.service.formData.UsedCapacityTruck = Math.floor(this.service.originalCapacityTruck * volIdealTruck / volTruckID)
      if(this.service.quoteItems.length == 1){
        this.onCargoEditTruck(ctrl)
      }
      this.service.originalQuantityShipment = this.service.formData.BoxShipment;
      //this.service.formData.IdealTruck = this.truckTypeList.find(voltruckID => voltruckID.TruckID == this.service.formData.TruckID).TruckName;
    };
  };

  onFreightList(){
    if(this.service.formData.TruckID != 0)
    this.freightService.getFreightList(+this.service.formData.DestinyID, +this.service.formData.TruckID).subscribe((freights: Freight[]) => {
      this.freightList = freights; this.onFreight(); this.updateTotalCost();}, error => console.error(error));
      ;
  };

  onFreight(){
    this.service.formData.FreightCost = this.freightList[0].FreightCost;
  };

  //this funtion is not working, let see for delete.
  onGetPriceCost(){
    if (this.service.quoteItems.length >= 1){
/*       this.capacitytruckService.getCapacityTruckList(+this.service.formData.TruckID, +this.service.quoteItems[0].FluteID, 1).subscribe(capacitytrucks => {
        //this.capacitytruckList = capacitytrucks; this.onCapacityTruck(); this.onFreightList(); this.updateTotalCost();
        this.capacitytruckList = capacitytrucks; */ 
      this.updateTotalCost();
    };
  };
  //en de delete
  onCapacityTruck(){
    //this.capacityTruck = +this.capacitytruckList[0].CapacityMM2;
  };

  updateTotalCost(){
    let interestRate: number = 0;
    //this.onCapacityTruck();
    interestRate = this.interestrateList.find(interestrate => interestrate.RateID == this.service.formData.RateID).Rate;
    this.service.formData.FinancialExpense = parseFloat((interestRate * this.service.formData.PriceMBox * this.service.formData.AverageOrder / 100000).toFixed(4));
    this.service.formData.SaleCommission = parseFloat((this.service.formData.Commission * this.service.formData.PriceMBox * this.service.formData.AverageOrder / 100000).toFixed(4));
    this.service.formData.TotalCost = parseFloat((this.service.formData.ManufacturingCost + (this.service.formData.FreightCost * this.service.formData.AverageOrder / this.service.formData.BoxShipment) + this.service.formData.SaleCommission + this.service.formData.FinancialExpense).toFixed(4));
    this.service.formData.CostMBox = parseFloat((this.service.formData.TotalCost * 1000 / this.service.formData.AverageOrder).toFixed(4));
    this.service.formData.CostMM2 = parseFloat((this.service.formData.TotalCost * 1000 / (this.service.quoteItems[0].BoxArea * this.service.formData.AverageOrder)).toFixed(4));
    this.service.formData.CostTon = parseFloat((this.service.formData.TotalCost / (this.service.quoteItems[0].BoxWeight * this.service.formData.AverageOrder / 1000000)).toFixed(4));
    this.service.formData.PriceMM2 = parseFloat((this.service.formData.PriceMBox / this.service.quoteItems[0].BoxArea).toFixed(4));
    this.service.formData.PriceTon = parseFloat((this.service.formData.PriceMBox * 1000 / this.service.quoteItems[0].BoxWeight).toFixed(4));
    this.service.formData.PriceWithoutFreight = parseFloat(((this.service.formData.CostMBox - (this.service.formData.FreightCost * 1000 / this.service.formData.BoxShipment)) / (1-(this.service.formData.TargetProfit / 100))).toFixed(4)); 
    this.service.formData.PriceWithFreight = parseFloat((this.service.formData.CostMBox  / (1-(this.service.formData.TargetProfit / 100))).toFixed(4)); 
    this.service.formData.ProfitWithoutFreight = parseFloat(((this.service.formData.PriceMBox - (this.service.formData.CostMBox - (this.service.formData.FreightCost * 1000 / this.service.formData.BoxShipment))) * 100 / this.service.formData.PriceMBox).toFixed(2));
    this.service.formData.ProfitWithFreight = parseFloat(((this.service.formData.PriceMBox - this.service.formData.CostMBox) * 100 / this.service.formData.PriceMBox).toFixed(2));
    this.service.formData.MonthlyOrderTon = parseFloat((this.service.formData.MonthlyOrder * this.service.quoteItems[0].BoxWeight / 1000000).toFixed(2));
  };

  updateTotalCostFreight(){
    this.updateTotalCost()
    this.service.formData.PriceMBox = 0;
  };

  updateQuoteItem(){
    if(this.service.formData.AverageOrder <= 0 || this.service.formData.AverageOrder == null){
      //this.service.formData.AverageOrder = 1;
      this.service.formData.MonthlyOrderTon = 0;
    };
    if(this.service.formData.MonthlyOrder <= 0 || this.service.formData.MonthlyOrder == null){
      //this.service.formData.MonthlyOrder = 1;
      this.service.formData.MonthlyOrderTon = 0;
    };
    if (this.service.quoteItems.length >= 1){
      let currentTruck: number;
      currentTruck = this.service.formData.TruckID;
      this.service.editUsoCapacityTruck = false;
      if(this.service.quoteItems[0].StyleID == 6){
        this.onCargoTypeQuoteSheet();
      }else {
        this.onCargoTypeQuote();
      };
      this.onBoardCost();
      this.service.quoteItems[0].Total = parseFloat((this.service.quoteItems[0].SubTotConverting + this.service.quoteItems[0].SubTotCorrugator).toFixed(4));
      this.service.formData.ManufacturingCost = this.service.quoteItems[0].Total;
      this.service.formData.TruckID = currentTruck;
      this.updateTotalCost();
    };
  };

  updateAreaShipment(){
    /* if(this.service.formData.BoxShipment <= 0 || this.service.formData.BoxShipment == null){
      this.service.formData.BoxShipment = 1;
    } */
    if (this.service.quoteItems.length >= 1){
      this.service.quoteItems[0].AreaShipment = parseFloat((this.service.quoteItems[0].TotalAreaRequired * this.service.formData.BoxShipment / 1000).toFixed(4));
      this.updateTotalCost();
      this.onCargoEditShipment();
    };
  };

  /* onGetCustomerProfit(ctrl){
    if (ctrl.selectedIndex == 0) {
      this.service.formData.TargetProfit = 0;
    } 
    else {
      this.service.formData.TargetProfit = this.customerList[ctrl.selectedIndex-1].TargetProfitability;
      this.onContactName();
    };
  }; */

  onGetCustomerProfit(){
    this.service.formData.CustomerID = this.customerList.find(customerID => customerID.CustomerName == this.service.formData.CustomerName).CustomerID;
    this.service.formData.TargetProfit = this.customerList.find(targetProfit => targetProfit.CustomerID == this.service.formData.CustomerID).TargetProfitability;
    this.service.formData.ContactName = this.customerList.find(contactName => contactName.CustomerID == this.service.formData.CustomerID).ContactName;
    //this.onContactName();
  };

  onBoardCost(){
    let productionTimeCorr: number; let productionTimeConv: number; 
    let productionTimeFolding: number; let productionTimePartition: number;
    let costMacFolding: number; let costMacPartition: number = 0;
    let costInk01: number = 0; let costInk02: number = 0; let costInk03: number = 0; let costInk04: number = 0; 
    let costInk05: number = 0; let costInk06: number = 0; let inkCostTotalArea: number = 0;
    let costPVA01: number = 0; let costCover01: number = 0; let indexSelected: number;
    let costStrapping01: number = 0; let costStrapping02: number = 0; 
    let thickness: number = 0; let costPlateCM2: number = 0; let costPlate: number = 0;
    let costDieCM: number = 0; let costDie: number = 0; let boxArea: number = 0;
    let workstation: number; let workstationPartition: number = 4; let workstationFolding: number = 3;
    let plateRatCon: number = 0; let costTape: number = 0;
    let costFilm: number = 0; let costBoardPackaging: number = 0;
    let costPallet: number = 0; let smOrder: number;
    let consDieCM: number = 0; let consTape: number = 0; let consStrapping5mm: number = 0;
    let consStrapping12mm: number = 0; let consCover: number = 0; let consPallet: number = 0;
    let consStrechFilm: number = 0; let consBoardPackaging: number = 0;

    smOrder = this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired
    
    //in this block, we are going to calculated the production Time for corrugator
    indexSelected = this.boardList.findIndex(typeDensity => typeDensity.BoardID == this.service.quoteItems[0].BoardID);
    if(this.boardList[indexSelected].SheetOrigin == "Propia"){
      if (this.boardList[indexSelected].TypeDensity == 0) {
        productionTimeCorr = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenLow * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
      } else if(this.boardList[indexSelected].TypeDensity == 1) {
        productionTimeCorr = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * (1 + +(this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenMed * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
      } else {
        productionTimeCorr = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenHig * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
      };
    } else {
      productionTimeCorr = 0;
    };   
    
    //in this block, we are going to calculated the production Time for converting
    //for now, we use the machine 2-IMP003, but next we should determine the manufacturing route.
    if (this.service.quoteItems[0].StyleID == 1){
      workstation = 1;
      boxArea = this.service.quoteItems[0].BoxArea;
    } else if (this.service.quoteItems[0].StyleID == 2){
      workstation = 1;
      boxArea = this.service.quoteItems[0].BoxArea;
    } else if (this.service.quoteItems[0].StyleID == 3){
      workstation = 1;
      boxArea = this.service.quoteItems[0].BoxArea;
    } else if (this.service.quoteItems[0].StyleID == 4){
      workstation = 1;
      boxArea = this.service.quoteItems[0].BoxArea;
    } else if (this.service.quoteItems[0].StyleID == 5){
      workstation = 2;
      boxArea = (this.service.quoteItems[0].LengthBoard * this.service.quoteItems[0].WidthBoard / 1000000);
    } else if (this.service.quoteItems[0].StyleID == 6){
      workstation = 0;
      boxArea = (this.service.quoteItems[0].LengthBoard * this.service.quoteItems[0].WidthBoard / 1000000);
    } else if(this.service.quoteItems[0].StyleID == 7){
      workstation = 2;
      workstationPartition = 4;
      boxArea = (this.service.quoteItems[0].LengthBoard * this.service.quoteItems[0].WidthBoard / 1000000);
    } else if (this.service.quoteItems[0].StyleID == 8){
      workstation = 1;
      boxArea = this.service.quoteItems[0].BoxArea;
    } else if (this.service.quoteItems[0].StyleID == 9){
      workstation = 1;
      boxArea = this.service.quoteItems[0].BoxArea;
    } else if (this.service.quoteItems[0].StyleID == 10){
      workstation = 2
      workstationFolding = 3;
      boxArea = (this.service.quoteItems[0].LengthBoard * this.service.quoteItems[0].WidthBoard / 1000000);
    } else if (this.service.quoteItems[0].StyleID == 11){
      workstation = 5;
      workstationPartition = 4;
      boxArea = (this.service.quoteItems[0].LengthBoard * this.service.quoteItems[0].WidthBoard / 1000000);
    };
    //call board cost
    this.boardcostService.getBoardCostListId(this.service.quoteItems[0].BoardID).subscribe((boardCosts: Boardcost) => {
      this.boardCost = boardCosts}, error => console.error(error));
    //if (this.boardCost.BoardID != null){
    if (this.service.quoteItems[0].StyleID <= 5 || this.service.quoteItems[0].StyleID >= 7){
      if (this.boardList[indexSelected].TypeDensity == 0 && this.service.quoteItems[0].Register =='Bajo'){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegLowDenLow * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegLowDenLow * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegLowDenLow * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 0 && this.service.quoteItems[0].Register =='Medio'){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegMedDenLow * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegMedDenLow * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegMedDenLow * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 0 && this.service.quoteItems[0].Register =='Alto'){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegHigDenLow * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegHigDenLow * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegHigDenLow * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if (this.boardList[indexSelected].TypeDensity == 1 && this.service.quoteItems[0].Register =='Bajo' ){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegLowDenMed * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegLowDenMed * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegLowDenMed * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 1 && this.service.quoteItems[0].Register =='Medio'){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegMedDenMed * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegMedDenMed * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegMedDenMed * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 1 && this.service.quoteItems[0].Register =='Alto'){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegHigDenMed * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegHigDenMed * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegMedDenMed * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if (this.boardList[indexSelected].TypeDensity == 2 && this.service.quoteItems[0].Register =='Bajo' ){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegLowDenHig * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegLowDenHig * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegLowDenHig * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 2 && this.service.quoteItems[0].Register =='Medio'){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegMedDenHig * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegMedDenHig * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegMedDenHig * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 2 && this.service.quoteItems[0].Register =='Alto'){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegHigDenHig * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegHigDenHig * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegHigDenHig * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      };
      if(this.boardList[indexSelected].SheetOrigin == "Propia"){
        productionTimeCorr = productionTimeCorr + 1;
        };
      productionTimeConv = productionTimeConv + 10;
      //this.service.quoteItems[0].ProductionTimeCorr = productionTimeCorr;
      //this.service.quoteItems[0].ProductionTimeConv = productionTimeConv;

      //in this block, we will calculate production cost
      this.service.quoteItems[0].CostMP = parseFloat((this.boardCost.BoardPaperCostMM2 * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100)) * this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder / 1000).toFixed(4));
      this.service.quoteItems[0].CostMPNeto = this.service.quoteItems[0].CostMP;
      //time and costo if the product is a box
      if (smOrder <= 1000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeCorr01;
        productionTimeConv = productionTimeConv + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeConv01;
        this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 1).Waste01 / 100));
      } else if(smOrder <= 2000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeCorr02;
        productionTimeConv = productionTimeConv + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeConv02;
        this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 1).Waste02 / 100));
      } else if(smOrder <= 3000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeCorr03;
        productionTimeConv = productionTimeConv + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeConv03;
        this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 1).Waste03 / 100));
      } else if(smOrder <= 4000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeCorr04;
        productionTimeConv = productionTimeConv + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeConv04;
        this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 1).Waste04 / 100));
      };
      if(this.boardList[indexSelected].SheetOrigin == "Terceros"){
        productionTimeCorr = 0;
      };
      //this.service.quoteItems[0].ProductionTimeCorr = productionTimeCorr;
      //this.service.quoteItems[0].ProductionTimeConv = productionTimeConv + productionTimePartition;
      //The CostAuxCorrM2 is the cost by production sheetboard and sales sheetboard.
      this.service.quoteItems[0].CostAuxCorr = parseFloat(((this.boardCost.StarchCostMM2 + +this.boardCost.CoatingCostMM2 + +this.boardCost.OtherAuxCostMM2) * (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder / 1000)).toFixed(4));        
      this.service.quoteItems[0].CostCorrToConv = parseFloat(((this.costrateList[0].DirLaborCostHR + +this.costrateList[0].IndLaborCostHR + +this.costrateList[0].MaintenanceCostHR + +this.costrateList[0].EnergyCostoHR + +this.costrateList[0].WaterCostHR + +this.costrateList[0].GasCostHR + +this.costrateList[0].IndManCostHR) * (productionTimeCorr / 60)).toFixed(4));
      this.service.quoteItems[0].CostFixed = parseFloat((this.costrateList[0].CorpMiscCostHR * productionTimeCorr / 60).toFixed(4));
      if(this.boardList[indexSelected].SheetOrigin == "Terceros"){
        this.service.quoteItems[0].CostFixed = parseFloat((this.costrateList[workstation].CorpMiscCostHR * (+this.service.quoteItems[0].ProductionTimeConv / 60)).toFixed(4));
      };
      if(this.service.quoteItems[0].StyleID == 7){
        if(this.service.quoteItems[0].InkNumber == 0){
          if (this.service.quoteItems[0].DieType == "Plano desplegado" || this.service.quoteItems[0].DieType == "Plano desplegado complejo"){
            costMacPartition = parseFloat(((this.costrateList[workstationPartition].DirLaborCostHR + +this.costrateList[workstationPartition].IndLaborCostHR + +this.costrateList[workstationPartition].MaintenanceCostHR + +this.costrateList[workstationPartition].EnergyCostoHR + +this.costrateList[workstationPartition].WaterCostHR + +this.costrateList[workstationPartition].GasCostHR + +this.costrateList[workstationPartition].IndManCostHR + +this.costrateList[workstationPartition].CorpMiscCostHR) * (productionTimePartition / 60)).toFixed(4));        
            costMacFolding = 0;
            productionTimeFolding = 0;
            productionTimeConv = 30;
            this.service.quoteItems[0].CostMacConverting = costMacPartition;
          } else {
            productionTimeConv = 30;
            costMacPartition = parseFloat(((this.costrateList[workstationPartition].DirLaborCostHR + +this.costrateList[workstationPartition].IndLaborCostHR + +this.costrateList[workstationPartition].MaintenanceCostHR + +this.costrateList[workstationPartition].EnergyCostoHR + +this.costrateList[workstationPartition].WaterCostHR + +this.costrateList[workstationPartition].GasCostHR + +this.costrateList[workstationPartition].IndManCostHR + +this.costrateList[workstationPartition].CorpMiscCostHR) * (productionTimePartition / 60)).toFixed(4));        
            costMacFolding = parseFloat(((this.costrateList[workstationFolding].DirLaborCostHR + +this.costrateList[workstationFolding].IndLaborCostHR + +this.costrateList[workstationFolding].MaintenanceCostHR + +this.costrateList[workstationFolding].EnergyCostoHR + +this.costrateList[workstationFolding].WaterCostHR + +this.costrateList[workstationFolding].GasCostHR + +this.costrateList[workstationFolding].IndManCostHR + +this.costrateList[workstationFolding].CorpMiscCostHR) * (productionTimeFolding / 60)).toFixed(4));        
            this.service.quoteItems[0].CostMacConverting = costMacPartition + costMacFolding;
          };
          this.service.quoteItems[0].CostFixed = 0.2 * this.service.quoteItems[0].CostFixed
        } else {
            if (this.service.quoteItems[0].DieType == "Plano desplegado" || this.service.quoteItems[0].DieType == "Plano desplegado complejo"){
              costMacPartition = parseFloat(((this.costrateList[workstationPartition].DirLaborCostHR + +this.costrateList[workstationPartition].IndLaborCostHR + +this.costrateList[workstationPartition].MaintenanceCostHR + +this.costrateList[workstationPartition].EnergyCostoHR + +this.costrateList[workstationPartition].WaterCostHR + +this.costrateList[workstationPartition].GasCostHR + +this.costrateList[workstationPartition].IndManCostHR + +this.costrateList[workstationPartition].CorpMiscCostHR) * (productionTimePartition / 60)).toFixed(4));        
              costMacFolding = 0;
              productionTimeFolding = 0;
              this.service.quoteItems[0].CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR) * (productionTimeConv / 60)).toFixed(4)) + costMacPartition;        
            } else {
              costMacPartition = parseFloat(((this.costrateList[workstationPartition].DirLaborCostHR + +this.costrateList[workstationPartition].IndLaborCostHR + +this.costrateList[workstationPartition].MaintenanceCostHR + +this.costrateList[workstationPartition].EnergyCostoHR + +this.costrateList[workstationPartition].WaterCostHR + +this.costrateList[workstationPartition].GasCostHR + +this.costrateList[workstationPartition].IndManCostHR + +this.costrateList[workstationPartition].CorpMiscCostHR) * (productionTimePartition / 60)).toFixed(4));        
              costMacFolding = parseFloat(((this.costrateList[workstationFolding].DirLaborCostHR + +this.costrateList[workstationFolding].IndLaborCostHR + +this.costrateList[workstationFolding].MaintenanceCostHR + +this.costrateList[workstationFolding].EnergyCostoHR + +this.costrateList[workstationFolding].WaterCostHR + +this.costrateList[workstationFolding].GasCostHR + +this.costrateList[workstationFolding].IndManCostHR + +this.costrateList[workstationFolding].CorpMiscCostHR) * (productionTimeFolding / 60)).toFixed(4));        
              this.service.quoteItems[0].CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR) * (productionTimeConv / 60)).toFixed(4)) + costMacPartition + costMacFolding;        
            };
        };
      } else if (this.service.quoteItems[0].StyleID == 11){
        if(this.service.quoteItems[0].InkNumber == 0){
          this.service.quoteItems[0].InkNumber = 1;
        } else {
            if (this.service.quoteItems[0].DieType == "Plano desplegado" || this.service.quoteItems[0].DieType == "Plano desplegado complejo"){
              costMacPartition = parseFloat(((this.costrateList[workstationPartition].DirLaborCostHR + +this.costrateList[workstationPartition].IndLaborCostHR + +this.costrateList[workstationPartition].MaintenanceCostHR + +this.costrateList[workstationPartition].EnergyCostoHR + +this.costrateList[workstationPartition].WaterCostHR + +this.costrateList[workstationPartition].GasCostHR + +this.costrateList[workstationPartition].IndManCostHR + +this.costrateList[workstationPartition].CorpMiscCostHR) * (productionTimePartition / 60)).toFixed(4));        
              costMacFolding = 0;
              productionTimeFolding = 0;
              this.service.quoteItems[0].CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR) * (productionTimeConv / 60)).toFixed(4)) + costMacPartition;        
            } else {
              costMacPartition = parseFloat(((this.costrateList[workstationPartition].DirLaborCostHR + +this.costrateList[workstationPartition].IndLaborCostHR + +this.costrateList[workstationPartition].MaintenanceCostHR + +this.costrateList[workstationPartition].EnergyCostoHR + +this.costrateList[workstationPartition].WaterCostHR + +this.costrateList[workstationPartition].GasCostHR + +this.costrateList[workstationPartition].IndManCostHR + +this.costrateList[workstationPartition].CorpMiscCostHR) * (productionTimePartition / 60)).toFixed(4));        
              costMacFolding = parseFloat(((this.costrateList[workstationFolding].DirLaborCostHR + +this.costrateList[workstationFolding].IndLaborCostHR + +this.costrateList[workstationFolding].MaintenanceCostHR + +this.costrateList[workstationFolding].EnergyCostoHR + +this.costrateList[workstationFolding].WaterCostHR + +this.costrateList[workstationFolding].GasCostHR + +this.costrateList[workstationFolding].IndManCostHR + +this.costrateList[workstationFolding].CorpMiscCostHR) * (productionTimeFolding / 60)).toFixed(4));        
              this.service.quoteItems[0].CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR) * (productionTimeConv / 60)).toFixed(4)) + costMacPartition + costMacFolding;        
            };
        };
      } else if (this.service.quoteItems[0].StyleID == 5){
          if (this.service.quoteItems[0].DieType == "Curvo pegado"){
            costMacPartition = 0;
            productionTimePartition = 0;
            costMacFolding = parseFloat(((this.costrateList[workstationFolding].DirLaborCostHR + +this.costrateList[workstationFolding].IndLaborCostHR + +this.costrateList[workstationFolding].MaintenanceCostHR + +this.costrateList[workstationFolding].EnergyCostoHR + +this.costrateList[workstationFolding].WaterCostHR + +this.costrateList[workstationFolding].GasCostHR + +this.costrateList[workstationFolding].IndManCostHR + +this.costrateList[workstationFolding].CorpMiscCostHR) * (productionTimeFolding / 60)).toFixed(4));        
            this.service.quoteItems[0].CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR) * (productionTimeConv / 60)).toFixed(4)) + costMacFolding;        
          } else {
            costMacPartition = 0;
            costMacFolding = 0;
            productionTimePartition = 0;
            productionTimeFolding = 0;
            this.service.quoteItems[0].CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR) * (productionTimeConv / 60)).toFixed(4));        
          };   
        } else if (this.service.quoteItems[0].StyleID == 10){
            productionTimeConv = (productionTimeConv + 50);
            costMacPartition = 0;
            productionTimePartition = 0;
            costMacFolding = parseFloat(((this.costrateList[workstationFolding].DirLaborCostHR + +this.costrateList[workstationFolding].IndLaborCostHR + +this.costrateList[workstationFolding].MaintenanceCostHR + +this.costrateList[workstationFolding].EnergyCostoHR + +this.costrateList[workstationFolding].WaterCostHR + +this.costrateList[workstationFolding].GasCostHR + +this.costrateList[workstationFolding].IndManCostHR + +this.costrateList[workstationFolding].CorpMiscCostHR) * (productionTimeFolding / 60)).toFixed(4));        
            this.service.quoteItems[0].CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR) * (productionTimeConv / 60)).toFixed(4)) + costMacFolding; 
      } else {
        costMacPartition = 0;
        costMacFolding = 0;
        productionTimePartition = 0;
        productionTimeFolding = 0;
        this.service.quoteItems[0].CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR + +this.costrateList[workstation].CorpMiscCostHR) * (productionTimeConv / 60)).toFixed(4));        
      };
      //productionTimeConv = productionTimeConv;
      this.service.quoteItems[0].ProductionTimeCorr = productionTimeCorr;
      this.service.quoteItems[0].ProductionTimeConv = productionTimeConv + productionTimePartition + productionTimeFolding;
      //in this block we are going to calculated the inks cost for each colors.
      if (this.service.quoteItems[0].InkNumber == 1){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.service.quoteItems[0].InkID01).Cost;
        costInk02 == 0;
        costInk03 == 0;
        costInk04 == 0;
        costInk05 == 0;
        costInk06 == 0;
        this.service.quoteItems[0].PerInk02 == 0;
        this.service.quoteItems[0].PerInk03 == 0;
        this.service.quoteItems[0].PerInk04 == 0;
        this.service.quoteItems[0].PerInk05 == 0;
        this.service.quoteItems[0].PerInk06 == 0;
        this.service.quoteItems[0].PerPrintingPlate02 == 0;
        this.service.quoteItems[0].PerPrintingPlate03 == 0;
        this.service.quoteItems[0].PerPrintingPlate04 == 0;
        this.service.quoteItems[0].PerPrintingPlate05 == 0;
        this.service.quoteItems[0].PerPrintingPlate06 == 0;
      } else if(this.service.quoteItems[0].InkNumber == 2){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.service.quoteItems[0].InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.service.quoteItems[0].InkID02).Cost;
        costInk03 == 0;
        costInk04 == 0;
        costInk05 == 0;
        costInk06 == 0;
        this.service.quoteItems[0].PerInk03 == 0;
        this.service.quoteItems[0].PerInk04 == 0;
        this.service.quoteItems[0].PerInk05 == 0;
        this.service.quoteItems[0].PerInk06 == 0;
        this.service.quoteItems[0].PerPrintingPlate03 == 0;
        this.service.quoteItems[0].PerPrintingPlate04 == 0;
        this.service.quoteItems[0].PerPrintingPlate05 == 0;
        this.service.quoteItems[0].PerPrintingPlate06 == 0;
      } else if(this.service.quoteItems[0].InkNumber == 3){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.service.quoteItems[0].InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.service.quoteItems[0].InkID02).Cost;
        costInk03 = this.inkList.find(ink03 => ink03.InkID == this.service.quoteItems[0].InkID03).Cost;
        costInk04 === 0;
        costInk05 === 0;
        costInk06 === 0;
        this.service.quoteItems[0].PerInk04 == 0;
        this.service.quoteItems[0].PerInk05 == 0;
        this.service.quoteItems[0].PerInk06 == 0;
        this.service.quoteItems[0].PerPrintingPlate04 == 0;
        this.service.quoteItems[0].PerPrintingPlate05 == 0;
        this.service.quoteItems[0].PerPrintingPlate06 == 0;
      } else if(this.service.quoteItems[0].InkNumber == 4){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.service.quoteItems[0].InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.service.quoteItems[0].InkID02).Cost;
        costInk03 = this.inkList.find(ink03 => ink03.InkID == this.service.quoteItems[0].InkID03).Cost;
        costInk04 = this.inkList.find(ink04 => ink04.InkID == this.service.quoteItems[0].InkID04).Cost;
        costInk05 == 0;
        costInk06 == 0;
        this.service.quoteItems[0].PerInk05 == 0;
        this.service.quoteItems[0].PerInk06 == 0;
        this.service.quoteItems[0].PerPrintingPlate05 == 0;
        this.service.quoteItems[0].PerPrintingPlate06 == 0;
      } else if(this.service.quoteItems[0].InkNumber == 5){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.service.quoteItems[0].InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.service.quoteItems[0].InkID02).Cost;
        costInk03 = this.inkList.find(ink03 => ink03.InkID == this.service.quoteItems[0].InkID03).Cost;
        costInk04 = this.inkList.find(ink04 => ink04.InkID == this.service.quoteItems[0].InkID04).Cost;
        costInk05 = this.inkList.find(ink05 => ink05.InkID == this.service.quoteItems[0].InkID05).Cost;
        costInk06 == 0;
        this.service.quoteItems[0].PerInk06 == 0;
        this.service.quoteItems[0].PerPrintingPlate06 == 0;
      } else if(this.service.quoteItems[0].InkNumber == 6){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.service.quoteItems[0].InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.service.quoteItems[0].InkID02).Cost;
        costInk03 = this.inkList.find(ink03 => ink03.InkID == this.service.quoteItems[0].InkID03).Cost;
        costInk04 = this.inkList.find(ink04 => ink04.InkID == this.service.quoteItems[0].InkID04).Cost;
        costInk05 = this.inkList.find(ink05 => ink05.InkID == this.service.quoteItems[0].InkID05).Cost;
        costInk06 = this.inkList.find(ink06 => ink06.InkID == this.service.quoteItems[0].InkID06).Cost;
      };
      //The number 2 represent the consumption per m2 (2gr/m2), and 1.1 is the waste factor, 10% over the consumption.
      inkCostTotalArea = 2 * this.service.quoteItems[0].BoxArea * this.service.formData.AverageOrder * 1.1 / 1000;//total consumption Kg / by order.
      this.service.quoteItems[0].CostInk = parseFloat(((costInk01 * ((this.service.quoteItems[0].PerInk01 * inkCostTotalArea / 100) + 3)) + (costInk02 * ((this.service.quoteItems[0].PerInk02 * inkCostTotalArea / 100) + 3))  + (costInk03 * ((this.service.quoteItems[0].PerInk03 * inkCostTotalArea / 100) + 3))  + (costInk04 * ((this.service.quoteItems[0].PerInk04 * inkCostTotalArea / 100) + 3))  + (costInk05 * ((this.service.quoteItems[0].PerInk05 * inkCostTotalArea / 100) + 3))  + (costInk06 * ((this.service.quoteItems[0].PerInk06 * inkCostTotalArea / 100) + 3))).toFixed(4));
      //in this block we are going to calculated the cost plate and Die Cuter
      //Plate Cost / cm2 and Die Cost / cm
      costPlateCM2 = (this.auxiliaryList.find(costplate => costplate.AuxMatID == 10).AuxMatCost) * (1 + ((this.auxiliaryList.find(costplate => costplate.AuxMatID == 10).AuxMatWaste)/100));
      //Total Plate Cost, all color and total Die
      costPlate = parseFloat((((+this.service.quoteItems[0].PerPrintingPlate01 + +this.service.quoteItems[0].PerPrintingPlate02 + +this.service.quoteItems[0].PerPrintingPlate03 + +this.service.quoteItems[0].PerPrintingPlate04 + +this.service.quoteItems[0].PerPrintingPlate05 + +this.service.quoteItems[0].PerPrintingPlate06)/100) * ((this.service.quoteItems[0].WidthBoard * this.service.quoteItems[0].LengthBoard / 100) * costPlateCM2) / (this.auxiliaryList.find(costplate => costplate.AuxMatID == 10).AuxLifeTime)).toFixed(4))
      this.service.quoteItems[0].CostPlateTotal = parseFloat((((+this.service.quoteItems[0].PerPrintingPlate01 + +this.service.quoteItems[0].PerPrintingPlate02 + +this.service.quoteItems[0].PerPrintingPlate03 + +this.service.quoteItems[0].PerPrintingPlate04 + +this.service.quoteItems[0].PerPrintingPlate05 + +this.service.quoteItems[0].PerPrintingPlate06)/100) * ((this.service.quoteItems[0].WidthBoard * this.service.quoteItems[0].LengthBoard / 100 ) * costPlateCM2)).toFixed(4))
      //section costDie
      if(this.service.quoteItems[0].StyleID == 5){
        costDieCM = (this.auxiliaryList.find(costdie => costdie.AuxMatID == 11).AuxMatCost) * (1 + ((this.auxiliaryList.find(costdie => costdie.AuxMatID == 11).AuxMatWaste)/100));
        consDieCM = parseFloat((((2 * this.service.quoteItems[0].Length / 10) + (2 * this.service.quoteItems[0].Width / 10)) * (this.service.quoteItems[0].Output)).toFixed(4));
        costDie = parseFloat(((consDieCM * costDieCM) / (this.auxiliaryList.find(costdie => costdie.AuxMatID == 11).AuxLifeTime)).toFixed(4));
        this.service.quoteItems[0].CostDieTotal = parseFloat((consDieCM * costDieCM).toFixed(4));
        if (this.service.quoteItems[0].DieType =="Curvo desplegado complejo"){
          costDie = costDie * 1.25;
          consDieCM = consDieCM * 1.25;
          this.service.quoteItems[0].CostDieTotal = this.service.quoteItems[0].CostDieTotal * 1.25;
        } else if (this.service.quoteItems[0].DieType =="Curvo pegado"){
          costDie = costDie * 1.10;
          consDieCM = consDieCM * 1.10;
          this.service.quoteItems[0].CostDieTotal = this.service.quoteItems[0].CostDieTotal * 1.10;
        };
      } else if (this.service.quoteItems[0].StyleID == 7 || this.service.quoteItems[0].StyleID == 11){
        costDieCM = (this.auxiliaryList.find(costdie => costdie.AuxMatID == 12).AuxMatCost) * (1 + ((this.auxiliaryList.find(costdie => costdie.AuxMatID == 12).AuxMatWaste)/100));
        consDieCM = parseFloat((((2 * this.service.quoteItems[0].Length / 10) + (2 * this.service.quoteItems[0].Width / 10)) * (this.service.quoteItems[0].Output)).toFixed(4));
        costDie = parseFloat(((consDieCM * costDieCM) / (this.auxiliaryList.find(costdie => costdie.AuxMatID == 12).AuxLifeTime)).toFixed(4));
        this.service.quoteItems[0].CostDieTotal = parseFloat((consDieCM * costDieCM).toFixed(4));
        if(this.service.quoteItems[0].DieType == "Plano desplegado complejo"){
          costDie = costDie * 1.25;
          consDieCM = consDieCM * 1.25;
          this.service.quoteItems[0].CostDieTotal = this.service.quoteItems[0].CostDieTotal * 1.25;
        } else if (this.service.quoteItems[0].DieType == "Plano pegado"){
          costDie = costDie * 1.10;
          consDieCM = consDieCM * 1.10;
          this.service.quoteItems[0].CostDieTotal = this.service.quoteItems[0].CostDieTotal * 1.10;
        };
      } else if (this.service.quoteItems[0].StyleID == 10){
        costDieCM = (this.auxiliaryList.find(costdie => costdie.AuxMatID == 11).AuxMatCost) * (1 + ((this.auxiliaryList.find(costdie => costdie.AuxMatID == 11).AuxMatWaste)/100));
        consDieCM = parseFloat(((2 * this.service.quoteItems[0].LengthBoard / 10) + (2 * this.service.quoteItems[0].WidthBoard / 10)).toFixed(4))
        costDie = parseFloat(((consDieCM * costDieCM) / (this.auxiliaryList.find(costdie => costdie.AuxMatID == 11).AuxLifeTime)).toFixed(4))
        this.service.quoteItems[0].CostDieTotal = parseFloat((consDieCM * costDieCM).toFixed(4));
      } else {
        costDieCM = 0;
        costDie = 0;
        consDieCM = 0;
      };
      //Total Plate Cost per order
      if(this.service.quoteItems[0].StyleID == 11){
        plateRatCon = this.auxiliaryList.find(costplate => costplate.AuxMatID == 20).AuxMatRatCon;
      } else {
        plateRatCon = this.auxiliaryList.find(costplate => costplate.AuxMatID == 10).AuxMatRatCon; 
      };
      if (plateRatCon <= this.service.formData.MonthlyOrder){
        this.service.quoteItems[0].CostPlate = (costPlate / plateRatCon) * this.service.formData.AverageOrder;
        this.service.quoteItems[0].CostDie = (costDie / plateRatCon) * this.service.formData.AverageOrder;
      } else {
        this.service.quoteItems[0].CostPlate = (costPlate / this.service.formData.MonthlyOrder) * this.service.formData.AverageOrder;
        this.service.quoteItems[0].CostDie = (costDie / this.service.formData.MonthlyOrder) * this.service.formData.AverageOrder;
      };
      //in this block we are going to calculated the PVA cost and rate consuptiom.
      if (this.service.quoteItems[0].ClosureID > 1){
        costPVA01 = this.auxiliaryList.find(PVA01 => PVA01.AuxMatID == 8).AuxMatCost * this.auxiliaryList.find(PVA01 => PVA01.AuxMatID == 8).AuxMatRatCon * (1 + (this.auxiliaryList.find(PVA01 => PVA01.AuxMatID == 8).AuxMatWaste / 100));
        this.service.quoteItems[0].CostPVA = parseFloat((costPVA01 * this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder / 1000).toFixed(4));
      }else {
        this.service.quoteItems[0].CostPVA = 0;
      };
      //in this block, we are going to calculated the Strech Film cost.
      if (this.service.quoteItems[0].StrechFilm == true){
        consStrechFilm =  (this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatRatCon) * (1 + (this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatWaste / 100)) * (this.service.formData.PalletProduced);
        costFilm = this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatCost;
        this.service.quoteItems[0].CostFilm = parseFloat((costFilm * consStrechFilm).toFixed(4));
      }else {
        this.service.quoteItems[0].CostFilm = 0;
        consStrechFilm = 0;
      };

      //in this block, we are gonna calculated the Board for Packaging cost
      consBoardPackaging = this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatRatCon * (1 + (this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatWaste / 100)) * (this.service.formData.PalletProduced);
      costBoardPackaging = this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatCost;
      this.service.quoteItems[0].CostBoardPackaging = parseFloat((costBoardPackaging * consBoardPackaging).toFixed(4));
      //in this block, we are gonna calculated the Pallet cost
      if (this.service.quoteItems[0].Pallet == true){
        consPallet = Math.ceil(this.service.formData.PalletProduced);
        costPallet = this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatCost * this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatRatCon * (1 + (this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatWaste / 100));
        this.service.quoteItems[0].CostPallet = parseFloat((costPallet * consPallet).toFixed(4));
      }else {
        this.service.quoteItems[0].CostPallet = 0;
        consPallet = 0;
      };
      
      //in this block we are calculated the Tape
      if (this.service.quoteItems[0].TapeID != 1){
        consTape = this.auxiliaryList.find(constape => constape.AuxMatID == this.service.quoteItems[0].TapeID).AuxMatRatCon * (1 + (this.auxiliaryList.find(constape => constape.AuxMatID == this.service.quoteItems[0].TapeID).AuxMatWaste / 100));
        costTape = this.auxiliaryList.find(tape => tape.AuxMatID == this.service.quoteItems[0].TapeID).AuxMatCost;
        if ((this.service.quoteItems[0].StyleID <= 4) || (this.service.quoteItems[0].StyleID >= 8)){
          this.service.quoteItems[0].CostTape = parseFloat((costTape * this.service.quoteItems[0].LengthBoard * this.service.formData.AverageOrder * this.service.quoteItems[0].TapeQuantity / 1000).toFixed(4));
          consTape = parseFloat((consTape * this.service.quoteItems[0].LengthBoard * this.service.formData.AverageOrder * this.service.quoteItems[0].TapeQuantity / 1000).toFixed(4));
        } else if((this.service.quoteItems[0].StyleID == 5) || (this.service.quoteItems[0].StyleID == 7) || (this.service.quoteItems[0].StyleID == 11)){
          this.service.quoteItems[0].CostTape = parseFloat((costTape * this.service.quoteItems[0].LengthBoard * (this.service.formData.AverageOrder / this.service.quoteItems[0].Output) * this.service.quoteItems[0].TapeQuantity / 1000).toFixed(4));
          consTape = parseFloat((consTape * this.service.quoteItems[0].LengthBoard * (this.service.formData.AverageOrder / this.service.quoteItems[0].Output) * this.service.quoteItems[0].TapeQuantity / 1000).toFixed(4));
        }
      }else{
        consTape = 0;
        this.service.quoteItems[0].CostTape = 0;
        this.service.quoteItems[0].TapeQuantity = 0;
      };

      //in this block we are going to calculated the Cover.
      costCover01 = this.auxiliaryList.find(Cover01 => Cover01.AuxMatID == 9).AuxMatCost
      if ((this.service.quoteItems[0].StyleID <= 4) || (this.service.quoteItems[0].StyleID == 6) || (this.service.quoteItems[0].StyleID >= 8)){
        this.service.quoteItems[0].CostCover = 0;
        consCover = 0;
      } else {
        this.service.quoteItems[0].CostCover = parseFloat((costCover01 * this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder / 1000).toFixed(4)); 
        consCover = parseFloat((this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder / 1000).toFixed(4)); 
      };
      //this block we are gonna calculated the strapping consumptions
      costStrapping01 = this.auxiliaryList.find(Strapping01 => Strapping01.AuxMatID == 1).AuxMatCost * (1 + (this.auxiliaryList.find(Strapping01 => Strapping01.AuxMatID == 1).AuxMatWaste / 100))
      thickness = this.boardList.find(thicknes => thicknes.BoardID == this.service.quoteItems[0].BoardID).Thickness;
      if(this.service.quoteItems[0].StrapDirection == "al ancho del atado"){
        consStrapping5mm = parseFloat((((2 * this.service.widthBundle * this.service.quoteItems[0].StrapNumber / 1000) + (4 * thickness * this.service.quoteItems[0].BoxBundle * this.service.quoteItems[0].StrapNumber / 1000)) * (this.service.formData.AverageOrder/this.service.quoteItems[0].BoxBundle)).toFixed(2)); 
      }else if(this.service.quoteItems[0].StrapDirection == "al largo del atado"){
        consStrapping5mm = parseFloat((((2 * this.service.lengthBundle * this.service.quoteItems[0].StrapNumber / 1000) + (4 * thickness * this.service.quoteItems[0].BoxBundle * this.service.quoteItems[0].StrapNumber / 1000)) * (this.service.formData.AverageOrder/this.service.quoteItems[0].BoxBundle)).toFixed(2));
      }else{
        consStrapping5mm = 0;
      };
      this.service.quoteItems[0].CostStrapping5mm = parseFloat((costStrapping01 * consStrapping5mm / 1000).toFixed(4));  
      costStrapping02 = this.auxiliaryList.find(Strapping02 => Strapping02.AuxMatID == 2).AuxMatCost * (1 + (this.auxiliaryList.find(Strapping02 => Strapping02.AuxMatID == 2).AuxMatWaste / 100))
      consStrapping12mm = parseFloat((20 * this.service.formData.PalletProduced).toFixed(2));        
      this.service.quoteItems[0].CostStrapping12mm = parseFloat((costStrapping02 * consStrapping12mm / 1000).toFixed(4));        
      this.service.quoteItems[0].SubTotCorrugator = parseFloat((this.service.quoteItems[0].CostMP + this.service.quoteItems[0].CostAuxCorr + this.service.quoteItems[0].CostCorrToConv + this.service.quoteItems[0].CostFixed + this.service.quoteItems[0].CostTape).toFixed(4));        
      this.service.quoteItems[0].CostWasteConv = parseFloat((this.service.quoteItems[0].SubTotCorrugator * 1.5 / 100).toFixed(4));
      if (this.service.quoteItems[0].PlateDie == true){
        this.service.quoteItems[0].SubTotConverting = parseFloat((this.service.quoteItems[0].CostMacConverting + this.service.quoteItems[0].CostInk + this.service.quoteItems[0].CostPVA + this.service.quoteItems[0].CostPlate + this.service.quoteItems[0].CostDie + this.service.quoteItems[0].CostCover + this.service.quoteItems[0].CostFilm + this.service.quoteItems[0].CostBoardPackaging + this.service.quoteItems[0].CostPallet +this.service.quoteItems[0].CostStrapping5mm + this.service.quoteItems[0].CostStrapping12mm + this.service.quoteItems[0].CostWasteConv).toFixed(4)); 
      } else {        
        this.service.quoteItems[0].SubTotConverting = parseFloat((this.service.quoteItems[0].CostMacConverting + this.service.quoteItems[0].CostInk + this.service.quoteItems[0].CostPVA + this.service.quoteItems[0].CostCover + this.service.quoteItems[0].CostFilm + this.service.quoteItems[0].CostBoardPackaging + this.service.quoteItems[0].CostPallet +this.service.quoteItems[0].CostStrapping5mm + this.service.quoteItems[0].CostStrapping12mm + this.service.quoteItems[0].CostWasteConv).toFixed(4)); 
      };
      this.service.quoteItems[0].Total = parseFloat((this.service.quoteItems[0].SubTotConverting + this.service.quoteItems[0].SubTotCorrugator).toFixed(2));
      //consumptions materials
      if (this.boardList[indexSelected].TypeStarch == "AI"){
        this.service.quoteItems[0].ConsStarch = parseFloat(((this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired) * ((this.auxiliaryList.find(consstarch => consstarch.AuxMatID == 13).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(consstarch => consstarch.AuxMatID == 13).AuxMatWaste)/100))).toFixed(2));
      }else {
        this.service.quoteItems[0].ConsStarch = parseFloat(((this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired) * ((this.auxiliaryList.find(consStarch => consStarch.AuxMatID == 14).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(consStarch => consStarch.AuxMatID == 14).AuxMatWaste)/100))).toFixed(2));
      };
      if (this.boardList[indexSelected].CoatingTo == "XX"){
        this.service.quoteItems[0].ConsCoating = 0;
      }else if(this.boardList[indexSelected].CoatingTo == "RL"){
          this.service.quoteItems[0].ConsCoating = parseFloat(((0.3334 * this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired) * ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatWaste)/100))).toFixed(2));
      } else if (this.boardList[indexSelected].CoatingTo == "RLM") {
          this.service.quoteItems[0].ConsCoating = parseFloat(((0.6664 * this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired) * ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatWaste)/100))).toFixed(2));
      } else if(this.boardList[indexSelected].CoatingTo == "RLMM"){
          this.service.quoteItems[0].ConsCoating = parseFloat(((this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired) * ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatWaste)/100))).toFixed(2));
      };

      if (this.service.quoteItems[0].ClosureID > 1){
        this.service.quoteItems[0].ConsPVA = parseFloat(((this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired / 1000) * (this.auxiliaryList.find(conspva => conspva.AuxMatID == 8).AuxMatRatCon) * (1 + ((this.auxiliaryList.find(conspva => conspva.AuxMatID == 8).AuxMatWaste)/100))).toFixed(2));
      } else {
        this.service.quoteItems[0].ConsPVA = 0;
      };
      if(this.service.quoteItems[0].StyleID == 5){
        this.service.quoteItems[0].ConsDieRDC = parseFloat((consDieCM * (1 + ((this.auxiliaryList.find(consdie => consdie.AuxMatID == 11).AuxMatWaste)/100))).toFixed(2));
        this.service.quoteItems[0].ConsDieFDC = 0;
      }else if(this.service.quoteItems[0].StyleID == 7 || this.service.quoteItems[0].StyleID == 11){
        this.service.quoteItems[0].ConsDieRDC = 0;
        this.service.quoteItems[0].ConsDieFDC = parseFloat((consDieCM * (1 + ((this.auxiliaryList.find(consdie => consdie.AuxMatID == 12).AuxMatWaste)/100))).toFixed(2));
      }else if(this.service.quoteItems[0].StyleID == 10){
        this.service.quoteItems[0].ConsDieRDC = parseFloat((consDieCM * (1 + ((this.auxiliaryList.find(consdie => consdie.AuxMatID == 11).AuxMatWaste)/100))).toFixed(2));
        this.service.quoteItems[0].ConsDieFDC = 0;
      };
      if(this.service.quoteItems[0].TapeID == 4){
        this.service.quoteItems[0].ConsStringKing = 0;
        this.service.quoteItems[0].ConsOpenSesame = parseFloat(consTape.toFixed(2));
      }else if (this.service.quoteItems[0].TapeID == 5){
        this.service.quoteItems[0].ConsOpenSesame = 0;
        this.service.quoteItems[0].ConsStringKing = parseFloat(consTape.toFixed(2));
      } else {
        this.service.quoteItems[0].ConsStringKing = 0;
        this.service.quoteItems[0].ConsOpenSesame = 0;
      };
      this.service.quoteItems[0].ConsCover = parseFloat(consCover.toFixed(2));
      if(this.service.quoteItems[0].PerInk01 > 0){
        this.service.quoteItems[0].ConsInk01 = parseFloat(((this.service.quoteItems[0].PerInk01 * inkCostTotalArea / 100) + 3).toFixed(2));
      } else {
        this.service.quoteItems[0].ConsInk01 = parseFloat(((this.service.quoteItems[0].PerInk01 * inkCostTotalArea / 100) + 0).toFixed(2));
      };
      if(this.service.quoteItems[0].PerInk02 > 0){
        this.service.quoteItems[0].ConsInk02 = parseFloat(((this.service.quoteItems[0].PerInk02 * inkCostTotalArea / 100) + 3).toFixed(2));
      } else {
        this.service.quoteItems[0].ConsInk02 = parseFloat(((this.service.quoteItems[0].PerInk02 * inkCostTotalArea / 100) + 0).toFixed(2));
      };
      if(this.service.quoteItems[0].PerInk03 > 0){
        this.service.quoteItems[0].ConsInk03 = parseFloat(((this.service.quoteItems[0].PerInk03 * inkCostTotalArea / 100) + 3).toFixed(2));
      } else {
        this.service.quoteItems[0].ConsInk03 = parseFloat(((this.service.quoteItems[0].PerInk03 * inkCostTotalArea / 100) + 0).toFixed(2));
      };
      if(this.service.quoteItems[0].PerInk04 > 0){
        this.service.quoteItems[0].ConsInk04 = parseFloat(((this.service.quoteItems[0].PerInk04 * inkCostTotalArea / 100) + 3).toFixed(2));
      } else {
        this.service.quoteItems[0].ConsInk04 = parseFloat(((this.service.quoteItems[0].PerInk04 * inkCostTotalArea / 100) + 0).toFixed(2));
      };
      if(this.service.quoteItems[0].PerInk05 > 0){
        this.service.quoteItems[0].ConsInk05 = parseFloat(((this.service.quoteItems[0].PerInk05 * inkCostTotalArea / 100) + 3).toFixed(2));
      } else {
        this.service.quoteItems[0].ConsInk05 = parseFloat(((this.service.quoteItems[0].PerInk05 * inkCostTotalArea / 100) + 0).toFixed(2));
      };
      if(this.service.quoteItems[0].PerInk06 > 0){
        this.service.quoteItems[0].ConsInk06 = parseFloat(((this.service.quoteItems[0].PerInk06 * inkCostTotalArea / 100) + 3).toFixed(2));
      } else {
        this.service.quoteItems[0].ConsInk06 = parseFloat(((this.service.quoteItems[0].PerInk06 * inkCostTotalArea / 100) + 0).toFixed(2));
      };
      this.service.quoteItems[0].ConsPlate01 = parseFloat(((+this.service.quoteItems[0].PerPrintingPlate01 / 100) * (this.service.quoteItems[0].WidthBoard * this.service.quoteItems[0].LengthBoard / 100)).toFixed(2));
      this.service.quoteItems[0].ConsPlate02 = parseFloat(((+this.service.quoteItems[0].PerPrintingPlate02 / 100) * (this.service.quoteItems[0].WidthBoard * this.service.quoteItems[0].LengthBoard / 100)).toFixed(2));;
      this.service.quoteItems[0].ConsPlate03 = parseFloat(((+this.service.quoteItems[0].PerPrintingPlate03 / 100) * (this.service.quoteItems[0].WidthBoard * this.service.quoteItems[0].LengthBoard / 100)).toFixed(2));;
      this.service.quoteItems[0].ConsPlate04 = parseFloat(((+this.service.quoteItems[0].PerPrintingPlate04 / 100) * (this.service.quoteItems[0].WidthBoard * this.service.quoteItems[0].LengthBoard / 100)).toFixed(2));;
      this.service.quoteItems[0].ConsPlate05 = parseFloat(((+this.service.quoteItems[0].PerPrintingPlate05 / 100) * (this.service.quoteItems[0].WidthBoard * this.service.quoteItems[0].LengthBoard / 100)).toFixed(2));;
      this.service.quoteItems[0].ConsPlate06 = parseFloat(((+this.service.quoteItems[0].PerPrintingPlate06 / 100) * (this.service.quoteItems[0].WidthBoard * this.service.quoteItems[0].LengthBoard / 100)).toFixed(2));;
      this.service.quoteItems[0].ConsStrapping5mm = consStrapping5mm;
      this.service.quoteItems[0].ConsStrapping12mm = consStrapping12mm;
      this.service.quoteItems[0].ConsStrechFilm = consStrechFilm;
      this.service.quoteItems[0].ConsPallet = consPallet;
      this.service.quoteItems[0].ConsBoardPackaging = consBoardPackaging;
      this.service.quoteItems[0].ConsWater = parseFloat(((this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired) * ((this.auxiliaryList.find(conswater => conswater.AuxMatID == 16).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conswater => conswater.AuxMatID == 16).AuxMatWaste)/100)) / 1000).toFixed(2));
      this.service.quoteItems[0].ConsGasForklift = parseFloat(((this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired) * ((this.auxiliaryList.find(consgasforklift => consgasforklift.AuxMatID == 17).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(consgasforklift => consgasforklift.AuxMatID == 17).AuxMatWaste)/100))).toFixed(2));
      this.service.quoteItems[0].ConsGasBoiler = parseFloat(((this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired / 1000) * ((this.auxiliaryList.find(consgasboiler => consgasboiler.AuxMatID == 18).AuxMatRatCon)) * (1 + ((this.auxiliaryList.find(consgasboiler => consgasboiler.AuxMatID == 18).AuxMatWaste)/100))).toFixed(2)); 
    } else {
        if (this.boardList[indexSelected].TypeDensity == 0) {
          productionTimeCorr = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenLow * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
        } else if(this.boardList[indexSelected].TypeDensity == 1) {
          productionTimeCorr = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * (1 + +(this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenMed * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
        } else {
          productionTimeCorr = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenHig * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
        };
      
      //in this block, we will calculate production cost
      //if (this.boardCost.BoardID != null){
        productionTimeCorr = productionTimeCorr + 1;
        this.service.quoteItems[0].CostMP = parseFloat((this.boardCost.BoardPaperCostMM2 * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100)) * this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder / 1000).toFixed(4));
        this.service.quoteItems[0].CostMPNeto = this.service.quoteItems[0].CostMP;
        if (smOrder <= 1000){
          productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr01;
          this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 2).Waste01 / 100));
        } else if(smOrder <= 2000){
          productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr02;
          this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 2).Waste02 / 100));
        } else if(smOrder <= 3000){
          productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr03;
          this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 2).Waste03 / 100));
        } else if(smOrder <= 4000){
          productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr04;
          this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 2).Waste04 / 100));
        }; 
        this.service.quoteItems[0].ProductionTimeCorr = productionTimeCorr;
        //The CostAuxCorrM2 is the cost by production sheetboard and sales sheetboard.
        this.service.quoteItems[0].CostAuxCorr = parseFloat(((this.boardCost.StarchCostMM2 + this.boardCost.CoatingCostMM2 + this.boardCost.OtherAuxCostMM2) * (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder / 1000)).toFixed(4));        
        this.service.quoteItems[0].CostCorrToConv = parseFloat(((this.costrateList[0].DirLaborCostHR + this.costrateList[0].IndLaborCostHR + this.costrateList[0].MaintenanceCostHR + this.costrateList[0].EnergyCostoHR + this.costrateList[0].WaterCostHR + this.costrateList[0].GasCostHR + this.costrateList[0].IndManCostHR) * (productionTimeCorr / 60)).toFixed(4));
        this.service.quoteItems[0].CostFixed = parseFloat((this.costrateList[0].CorpMiscCostHR * productionTimeCorr / 60).toFixed(4));
                
        //this.formData.CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + this.costrateList[workstation].IndLaborCostHR + this.costrateList[workstation].MaintenanceCostHR + this.costrateList[workstation].EnergyCostoHR + this.costrateList[workstation].WaterCostHR + this.costrateList[workstation].GasCostHR + this.costrateList[workstation].IndManCostHR + this.costrateList[workstation].CorpMiscCostHR) * (productionTimeConv / 60)).toFixed(4));        
        
        //in this block, we are going to calculated the Strech Film cost.
        if (this.service.quoteItems[0].StrechFilm == true){
          consStrechFilm =  (this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatRatCon) * (1 + (this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatWaste / 100)) * this.service.formData.PalletProduced;
          costFilm = this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatCost;
          this.service.quoteItems[0].CostFilm = parseFloat((costFilm * consStrechFilm).toFixed(4));
        }else {
          this.service.quoteItems[0].CostFilm = 0;
          consStrechFilm = 0;
        };
  
        //in this block, we are gonna calculated the Board for Packaging cost
        consBoardPackaging = this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatRatCon * (1 + (this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatWaste / 100)) * (this.service.formData.PalletProduced);
        costBoardPackaging = this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatCost;
        this.service.quoteItems[0].CostBoardPackaging = parseFloat((costBoardPackaging * consBoardPackaging).toFixed(4));
        //in this block, we are gonna calculated the Pallet cost
        if (this.service.quoteItems[0].Pallet == true){
          consPallet = Math.ceil(this.service.formData.PalletProduced);
          costPallet = this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatCost * this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatRatCon * (1 + (this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatWaste / 100));
          this.service.quoteItems[0].CostPallet = parseFloat((costPallet * consPallet).toFixed(4));
        }else {
          this.service.quoteItems[0].CostPallet = 0;
          consPallet = 0;
        };
        
        //in this block we are calculated the Tape
        if (this.service.quoteItems[0].TapeID != 1){
          costTape = this.auxiliaryList.find(tape => tape.AuxMatID == this.service.quoteItems[0].TapeID).AuxMatCost;
          consTape = this.auxiliaryList.find(constape => constape.AuxMatID == this.service.quoteItems[0].TapeID).AuxMatRatCon * (1 + (this.auxiliaryList.find(constape => constape.AuxMatID == this.service.quoteItems[0].TapeID).AuxMatWaste / 100));
          consTape = parseFloat((consTape * this.service.quoteItems[0].LengthBoard * this.service.formData.AverageOrder * this.service.quoteItems[0].TapeQuantity / 1000).toFixed(4));
          this.service.quoteItems[0].CostTape =  parseFloat((consTape * costTape).toFixed(4));
        } else {
          this.service.quoteItems[0].CostTape = 0;
          this.service.quoteItems[0].TapeQuantity = 0;
          consTape = 0;
        };
        thickness = this.boardList.find(thicknes => thicknes.BoardID == this.service.quoteItems[0].BoardID).Thickness; 
        costStrapping02 = this.auxiliaryList.find(Strapping02 => Strapping02.AuxMatID == 2).AuxMatCost * (1 + (this.auxiliaryList.find(Strapping02 => Strapping02.AuxMatID == 2).AuxMatWaste / 100))
        consStrapping12mm = parseFloat((20 * this.service.formData.PalletProduced).toFixed(2));        
        this.service.quoteItems[0].CostStrapping12mm = parseFloat((costStrapping02 * consStrapping12mm / 1000).toFixed(4));        
        this.service.quoteItems[0].CostWasteConv = 0;
        this.service.quoteItems[0].SubTotCorrugator = parseFloat((this.service.quoteItems[0].CostMP + this.service.quoteItems[0].CostAuxCorr + this.service.quoteItems[0].CostCorrToConv + this.service.quoteItems[0].CostFixed + this.service.quoteItems[0].CostTape + this.service.quoteItems[0].CostFilm + this.service.quoteItems[0].CostPallet + this.service.quoteItems[0].CostBoardPackaging + this.service.quoteItems[0].CostStrapping12mm + this.service.quoteItems[0].CostWasteConv).toFixed(4));     
        this.service.quoteItems[0].SubTotConverting = 0; 
        this.service.quoteItems[0].Total = parseFloat((this.service.quoteItems[0].SubTotConverting + this.service.quoteItems[0].SubTotCorrugator).toFixed(2));
    
          //consumptions materials
        if (this.boardList[indexSelected].TypeStarch == "AI"){
          this.service.quoteItems[0].ConsStarch = parseFloat(((this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired) * ((this.auxiliaryList.find(consstarch => consstarch.AuxMatID == 13).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(consstarch => consstarch.AuxMatID == 13).AuxMatWaste)/100))).toFixed(2));
        }else {
          this.service.quoteItems[0].ConsStarch = parseFloat(((this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired) * ((this.auxiliaryList.find(consStarch => consStarch.AuxMatID == 14).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(consStarch => consStarch.AuxMatID == 14).AuxMatWaste)/100))).toFixed(2));
        };
        if (this.boardList[indexSelected].CoatingTo == "XX"){
          this.service.quoteItems[0].ConsCoating = 0;
        } else if(this.boardList[indexSelected].CoatingTo == "RL"){
            this.service.quoteItems[0].ConsCoating = parseFloat(((0.3334 * this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired) * ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatWaste)/100))).toFixed(2));
        } else if (this.boardList[indexSelected].CoatingTo == "RLM") {
            this.service.quoteItems[0].ConsCoating = parseFloat(((0.6664 * this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired) * ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatWaste)/100))).toFixed(2));
        } else if(this.boardList[indexSelected].CoatingTo == "RLMM"){
            this.service.quoteItems[0].ConsCoating = parseFloat(((this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired) * ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatWaste)/100))).toFixed(2));
        };
        this.service.quoteItems[0].ConsPVA = 0;
        this.service.quoteItems[0].ConsDieFDC = 0;
        this.service.quoteItems[0].ConsDieRDC = 0;
        if(this.service.quoteItems[0].TapeID == 4){
          this.service.quoteItems[0].ConsStringKing = 0;
          this.service.quoteItems[0].ConsOpenSesame = parseFloat(consTape.toFixed(2));
        }else if (this.service.quoteItems[0].TapeID == 5){
          this.service.quoteItems[0].ConsOpenSesame = 0;
          this.service.quoteItems[0].ConsStringKing = parseFloat(consTape.toFixed(2));
        } else {
          this.service.quoteItems[0].ConsStringKing = 0;
          this.service.quoteItems[0].ConsOpenSesame = 0;
        };
        this.service.quoteItems[0].ConsCover = 0; 
        this.service.quoteItems[0].ConsInk01 = 0;
        this.service.quoteItems[0].ConsInk02 = 0;
        this.service.quoteItems[0].ConsInk03 = 0;
        this.service.quoteItems[0].ConsInk04 = 0;
        this.service.quoteItems[0].ConsInk05 = 0;
        this.service.quoteItems[0].ConsInk06 = 0;
        this.service.quoteItems[0].ConsPlate01 = 0;
        this.service.quoteItems[0].ConsPlate02 = 0;
        this.service.quoteItems[0].ConsPlate03 = 0;
        this.service.quoteItems[0].ConsPlate04 = 0;
        this.service.quoteItems[0].ConsPlate05 = 0;
        this.service.quoteItems[0].ConsPlate06 = 0;
        this.service.quoteItems[0].ConsStrapping5mm = 0;
        this.service.quoteItems[0].ConsStrapping12mm = consStrapping12mm;
        this.service.quoteItems[0].ConsStrechFilm = consStrechFilm;
        this.service.quoteItems[0].ConsPallet = consPallet;
        this.service.quoteItems[0].ConsBoardPackaging = consBoardPackaging;
        this.service.quoteItems[0].ConsWater = parseFloat(((this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired) * ((this.auxiliaryList.find(conswater => conswater.AuxMatID == 16).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conswater => conswater.AuxMatID == 16).AuxMatWaste)/100)) / 1000).toFixed(2));
        this.service.quoteItems[0].ConsGasForklift = parseFloat(((this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired) * ((this.auxiliaryList.find(consgasforklift => consgasforklift.AuxMatID == 17).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(consgasforklift => consgasforklift.AuxMatID == 17).AuxMatWaste)/100))).toFixed(2));
        this.service.quoteItems[0].ConsGasBoiler = parseFloat(((this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired / 1000) * ((this.auxiliaryList.find(consgasboiler => consgasboiler.AuxMatID == 18).AuxMatRatCon)) * (1 + ((this.auxiliaryList.find(consgasboiler => consgasboiler.AuxMatID == 18).AuxMatWaste)/100))).toFixed(2));
    };      
  };

  onQuoteBoxOrSheet(){
    if(this.service.qBoxOrSheet  == true){
      this.service.quoteBoxOrSheet = false;
    } else {
      this.service.quoteBoxOrSheet = true;
    };
  };

  onQuantityMin(){
    if(this.service.quoteItems.length == 0){
      this.toastr.error('No se tiene el item agregado a la cotización',  'Corrugated Quote.');
    }else {
    this.toastr.warning('La cantidad mínima de pedido para este simbolo deberá ser: ' + Math.floor(3000 / this.service.quoteItems[0].BoxArea) + " Piezas", 'Corrugated Quote.');
    };
  };

  onContactName(){
    this.service.formData.CustomerName = this.customerList.find(customerName => customerName.CustomerID == this.service.formData.CustomerID).CustomerName;
    this.service.formData.ContactName = this.customerList.find(contactName => contactName.CustomerID == this.service.formData.CustomerID).ContactName;
    this.service.formData.TargetProfit = this.customerList.find(targetProfit => targetProfit.CustomerID == this.service.formData.CustomerID).TargetProfitability;
  };

  onFocusBoardList(){
    this.service.boardflute = this.boardList.find(boardFlute => boardFlute.BoardID == this.service.quoteItems[0].BoardID).BoardFlute;
    this.boardService.getBoardListBoardFluteAlternative(this.service.boardflute, "quoteSales", 1).then(res => this.boardQuote = res as Board[]);
  };

  onEditBoardList(editBoardID){
    this.service.formData.BoardID = this.boardQuote[editBoardID.selectedIndex-1].BoardID;
    this.service.quoteItems[0].BoardID = this.service.formData.BoardID;
    this.boardcostService.getBoardCostListId(this.service.quoteItems[0].BoardID).subscribe((boardCosts: Boardcost) => {
      this.boardCost = boardCosts; this.updateQuoteItem()}, error => console.error(error));
  };

  onQuantityByTruck(){
    if(this.service.quoteItems.length == 0){
      this.toastr.error('No se tiene el item agregado a la cotización',  'Corrugated Quote.');
    }else {
      this.service.editUsoCapacityTruck = false;
      if(this.service.quoteItems[0].StyleID == 6){
        this.onCargoTypeQuoteSheet();
      }else {
        this.onCargoTypeQuote();
      };
      this.onFreightList();
      this.onBoardCost();
      this.updateTotalCost();
      this.toastr.info('La cantidad de piezas por tipo de Unidad para este simbolo deberán ser: ' + ' ' +
      'Full: ' + this.service.fullCapacity + ' ' +
      'Trailer: ' + this.service.trailerCapacity + ' ' +
      'Torton: ' + this.service.tortonCapacity + ' ' +
      'Rabón: ' + this.service.rabonCapacity + ' ' +
      'Camioneta: ' + this.service.camionetaCapacity, 'Corrugated Quote.');
    };
  };

  onPalletType(ctrl){
    if(this.service.formData.CargoType == "Granel" || this.service.formData.CargoType == "Sin Tarima"){
      this.service.formData.CargoType = "Con Tarima";
    };
    if(this.service.formData.CargoType == "Con Tarima"){
      this.service.widthPallet = this.palletList[ctrl.selectedIndex-1].Width;
      this.service.lengthPallet = this.palletList[ctrl.selectedIndex-1].Length;
      this.service.heightPallet = this.palletList[ctrl.selectedIndex-1].Height;
      this.service.quoteItems[0].Pallet = true;
    };

    if (this.service.quoteItems.length == 1){
      this.service.editUsoCapacityTruck = false;
      if(this.service.quoteItems[0].StyleID == 6){
        this.onCargoTypeQuoteSheet();
      }else {
        this.onCargoTypeQuote();
      };
      
      this.onBoardCost();
      
      this.service.quoteItems[0].Total = parseFloat((this.service.quoteItems[0].SubTotConverting + this.service.quoteItems[0].SubTotCorrugator).toFixed(4));
      this.service.formData.ManufacturingCost = this.service.quoteItems[0].Total;
      this.onFreightList();
      //this.updateTotalCost();
    };
  };

  onTypeCargo(){
    if(this.service.formData.CargoType == "Granel" || this.service.formData.CargoType == "Sin Tarima"){
      this.service.formData.PalletID = 0;
      this.service.quoteItems[0].Pallet = false;
    };
    if (this.service.quoteItems.length == 1){
      this.service.editUsoCapacityTruck = false;
      //this.service.quoteItems[0].Pallet = false;
      if(this.service.quoteItems[0].StyleID == 6){
        this.onCargoTypeQuoteSheet();
      }else {
        this.onCargoTypeQuote();
      };
      this.onFreightList();
      this.onBoardCost();
      this.service.quoteItems[0].Total = parseFloat((this.service.quoteItems[0].SubTotConverting + this.service.quoteItems[0].SubTotCorrugator).toFixed(4));
      this.service.formData.ManufacturingCost = this.service.quoteItems[0].Total;
      this.updateTotalCost();
    };
  };

  onTypeCargoTarima(){
    if(this.service.formData.CargoType == "Con Tarima" && this.service.formData.PalletID == 0){
      this.toastr.error('Por favor seleccione un tipo de Tarima',  'Corrugated Quote.');
      exit();
    }
  };

  onviewBoxSheetBotton(){
    if(this.service.quoteItems.length == 0 || this.service.quoteItems.length == null){
      this.service.viewBoxSheetBotton = true;
    } else {
      this.service.viewBoxSheetBotton = false;
    };
  };

  onviewLayer(){
    if(this.service.formData.CargoType == "Granel"){
      this.service.viewLayer = false;
    }else {
      this.service.viewLayer = true;
    };
  };

  onCargoTypeQuote(){
    let areaBundle: number; let numberBundle: number; let volBundle: number = 0;
    let heightBundle: number; let thickness: number; let volCargo: number;
    //Variables paletizations
    let xlengthPlengthB: number;  let ywidthPlengthB: number; 
    let wlengthPwidthB: number;  let zwidthPwidthB: number;
    let lengthB: number; let widthB: number; //let lengthBundle: number; let widthBundle: number;
    let lengthP: number = 1.2; let widthP: number = 1.05; let heightP: number = 1.2;
    let layerP: number; //let numPalletCargo: number; 
    let maxBundle01: number; let maxBundle02: number; let maxBundleP: number;
    let numBundleWidth: number; let numBundleLength: number;
    let lengthPalletF: number; let widthPalletF: number; let heightPalletF: number;
    let blocksB: number; let blocks02B: number;
    let numBunBlock: number; let numBunBlocks02: number;
    let lengthCargo: number;
    let boxPallet: number; let indexTruck: number = 0;
    let indexTruckSelected: number = 0; let layerPallet: number = 4;
    let palletArrangement: number = 0;
    //end
    //lineal meters, caliper.
    this.service.NewOrEdit = true;
    thickness = this.boardList.find(thicknes => thicknes.BoardID == this.service.quoteItems[0].BoardID).Thickness / 1000;
    if((this.service.quoteItems[0].StyleID <= 4) || (this.service.quoteItems[0].StyleID >= 8 && this.service.quoteItems[0].StyleID <= 10)){
      this.service.lengthBundle = parseFloat((((this.service.quoteItems[0].LengthBoard - 40) / 2) / 1000).toFixed(3));
      this.service.widthBundle = parseFloat((this.service.quoteItems[0].WidthBoard / 1000).toFixed(3));
      areaBundle = parseFloat((this.service.lengthBundle * this.service.widthBundle).toFixed(3));
      heightBundle = parseFloat((2 * thickness * this.service.quoteItems[0].BoxBundle).toFixed(3));
    }else if(this.service.quoteItems[0].StyleID == 5 || this.service.quoteItems[0].StyleID == 7 || this.service.quoteItems[0].StyleID == 11){
      if(this.service.quoteItems[0].DieType == "Curvo pegado" || this.service.quoteItems[0].DieType == "Plano pegado"){
        this.service.lengthBundle = parseFloat((((this.service.quoteItems[0].Length - 35) / 2) / 1000).toFixed(3));
        this.service.widthBundle = parseFloat((this.service.quoteItems[0].Width / 1000).toFixed(3));
        areaBundle = parseFloat((this.service.lengthBundle * this.service.widthBundle).toFixed(3));
        heightBundle = parseFloat((2 * thickness * this.service.quoteItems[0].BoxBundle).toFixed(3));
      }else {
        this.service.lengthBundle = parseFloat((this.service.quoteItems[0].Length / 1000).toFixed(3));
        this.service.widthBundle = parseFloat((this.service.quoteItems[0].Width / 1000).toFixed(3));
        areaBundle = parseFloat((this.service.lengthBundle * this.service.widthBundle).toFixed(3));
        heightBundle = parseFloat((thickness * this.service.quoteItems[0].BoxBundle).toFixed(3));
      };
    };
    //Volum one bundle
    volBundle = parseFloat((areaBundle * heightBundle).toFixed(3));
    //number bundle for shipment/freigh
    numberBundle = Math.ceil(this.service.formData.BoxShipment / this.service.quoteItems[0].BoxBundle)
    indexTruckSelected = this.truckTypeList.findIndex(indextruck => indextruck.TruckID == this.service.formData.TruckID); 
    //unitization
    if (this.service.formData.CargoType == "Granel"){
      volCargo = volBundle * numberBundle
      //this part is for determineted the Ideal Track
      for(let index = 0 ; index < this.truckTypeList.length && volCargo <= this.truckTypeList[index].CapacityM3; index++){
        this.service.formData.IdealTruck = this.truckTypeList[index].TruckName;
        this.service.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / this.truckTypeList[index].CapacityM3);
        this.service.formData.TruckID = this.truckTypeList[index].TruckID;
      };
      this.service.formData.PalletID = 0
      //test
      this.service.fullCapacity = Math.floor(this.truckTypeList[0].CapacityM3 / volBundle) * this.service.quoteItems[0].BoxBundle;
      this.service.trailerCapacity = Math.floor(this.truckTypeList[1].CapacityM3 / volBundle) * this.service.quoteItems[0].BoxBundle;
      this.service.tortonCapacity = Math.floor(this.truckTypeList[2].CapacityM3 / volBundle) * this.service.quoteItems[0].BoxBundle;
      this.service.rabonCapacity = Math.floor(this.truckTypeList[3].CapacityM3 / volBundle) * this.service.quoteItems[0].BoxBundle;
      this.service.camionetaCapacity = Math.floor(this.truckTypeList[4].CapacityM3 / volBundle) * this.service.quoteItems[0].BoxBundle;
      widthP = 1.15;
      lengthP = 1.20;
      heightP = 1.20;
      layerP = Math.round(heightP / heightBundle);
      heightPalletF = parseFloat((layerP * heightBundle).toFixed(3));
      lengthB = Math.max(this.service.lengthBundle, this.service.widthBundle);
      widthB = Math.min(this.service.lengthBundle, this.service.widthBundle);

      areaBundle = this.service.lengthBundle * this.service.widthBundle;
      xlengthPlengthB = Math.floor(lengthP / lengthB);
      ywidthPlengthB = Math.floor(widthP / lengthB);
      wlengthPwidthB = Math.floor(lengthP / widthB);
      zwidthPwidthB = Math.floor(widthP / widthB);
      if(xlengthPlengthB == 0 && ywidthPlengthB == 0 && wlengthPwidthB == 0 && zwidthPwidthB == 0 ){
        ywidthPlengthB = 1;
        wlengthPwidthB = 1;
      };
      if(widthB > 1.25 && lengthB > widthB){
        layerPallet = 2;
      }else {
        layerPallet = 4;
      };
        //number maximun boundle per pallet
      maxBundle01 = xlengthPlengthB * zwidthPwidthB;
      maxBundle02 = ywidthPlengthB * wlengthPwidthB;
      //end
      if ((lengthB + widthB) <= widthP) {
          blocksB = Math.floor(widthP / (lengthB + widthB))
          blocks02B = Math.floor(widthP / (lengthB + (2 * widthB)))
          numBunBlock = blocksB * (xlengthPlengthB + wlengthPwidthB)
          numBunBlocks02 = blocks02B * (wlengthPwidthB + (2 * xlengthPlengthB))
          maxBundleP = Math.max(numBunBlock, numBunBlocks02, maxBundle01, maxBundle02)
        if ((maxBundle01 >= maxBundle02) && (maxBundle01 >= numBunBlock) && (maxBundle01 >= numBunBlocks02)){
          maxBundleP = maxBundle01;
          numBundleLength = xlengthPlengthB;
          numBundleWidth = zwidthPwidthB;
          lengthPalletF = numBundleLength * lengthB
          widthPalletF = numBundleWidth * widthB
        }else if ((maxBundle02 >= maxBundle01) && (maxBundle02 >= numBunBlock) && (maxBundle02 >= numBunBlocks02)){
          maxBundleP = maxBundle02;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = ywidthPlengthB;
          lengthPalletF = numBundleLength * widthB;
          widthPalletF = numBundleWidth * lengthB;
        }else if ((numBunBlock >= maxBundle01) && (numBunBlock >= maxBundle02) && (numBunBlock >= numBunBlocks02)){
          maxBundleP = numBunBlock;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = 2 * blocksB;
          lengthPalletF = numBundleLength * widthB;
          widthPalletF = blocksB * (lengthB + widthB);
        }else if ((numBunBlocks02 >= maxBundle01) && (numBunBlocks02 >= maxBundle02) && (numBunBlocks02 >= numBunBlock)){
          maxBundleP = numBunBlocks02;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = 3 * blocks02B;
          lengthPalletF = numBundleLength * widthB
          widthPalletF = blocks02B * (lengthB + (2 * widthB))
        };
      }else if (((lengthB + widthB) > widthP) && (widthB <= (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
        maxBundleP = numBundleLength + numBundleWidth;
        widthPalletF = 2 * widthB;
        lengthPalletF = lengthB + widthB;
      }else if (((lengthB + widthB) > widthP) && (widthB > (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
        maxBundleP = numBundleLength * numBundleWidth;
        widthPalletF = numBundleLength * widthB;
        lengthPalletF = numBundleWidth * lengthB;
      }else if (((lengthB + widthB) > lengthP) && (lengthB < lengthP)) {
        maxBundleP = wlengthPwidthB;
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
        lengthPalletF = maxBundleP * widthB;
        widthPalletF = lengthB; 
      }else if (((lengthB + widthB) > lengthP) && (lengthB > lengthP)){
        maxBundleP = wlengthPwidthB;
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
        if(widthB > 1.25){
          lengthPalletF = maxBundleP * widthB;
          widthPalletF =  lengthB;
        }else {
          lengthPalletF = lengthB;
          widthPalletF = maxBundleP * widthB; 
        }; 
      };
      //test orientation of pallet on the truck
      if((lengthPalletF <= 1.2) && (lengthPalletF > widthPalletF)){
        let newlengthPalletF: number = widthPalletF;
        widthPalletF = lengthPalletF;
        lengthPalletF = newlengthPalletF;
      };
      //ende test
      boxPallet = (maxBundleP * this.service.quoteItems[0].BoxBundle * layerP);
      this.service.numPalletCargo = Math.ceil(this.service.formData.BoxShipment / (boxPallet))
      this.service.quoteItems[0].Pallet = false;
      if(this.service.editUsoCapacityTruck == true){
        this.service.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / this.truckTypeList[indexTruckSelected].CapacityM3);
      };
         
    if(volCargo > this.truckTypeList[0].CapacityM3){
      this.toastr.error('La cantidad de piezas sobrepasa la capadidad de la unidad mas grande: FULL, '+ 
      'por favor consulte en la etiqueta TRANSPORTE cuánto puede embarcar por cada tipo de unidad ' +
      'para este símbolo:', 'Corrugated Quote.');
      this.service.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / this.truckTypeList[0].CapacityM3)
    };

    //end test
  }else {  
    if (this.service.formData.CargoType == "Sin Tarima"){
      widthP = 1.15;
      lengthP = 1.20;
      heightP = 1.20;
      layerP = Math.round(heightP / heightBundle);
      heightPalletF = parseFloat((layerP * heightBundle).toFixed(3));
      lengthB = Math.max(this.service.lengthBundle, this.service.widthBundle);
      widthB = Math.min(this.service.lengthBundle, this.service.widthBundle);

      areaBundle = this.service.lengthBundle * this.service.widthBundle;
      xlengthPlengthB = Math.floor(lengthP / lengthB);
      ywidthPlengthB = Math.floor(widthP / lengthB);
      wlengthPwidthB = Math.floor(lengthP / widthB);
      zwidthPwidthB = Math.floor(widthP / widthB);
      if(xlengthPlengthB == 0 && ywidthPlengthB == 0 && wlengthPwidthB == 0 && zwidthPwidthB == 0 ){
        ywidthPlengthB = 1;
        wlengthPwidthB = 1;
      };
      if(widthB > 1.25 && lengthB > widthB){
        layerPallet = 2;
      }else {
        layerPallet = 4;
      };
      //number maximun boundle per pallet
      maxBundle01 = xlengthPlengthB * zwidthPwidthB;
      maxBundle02 = ywidthPlengthB * wlengthPwidthB;

      //end
      if ((lengthB + widthB) <= widthP) {
        blocksB = Math.floor(widthP / (lengthB + widthB))
        blocks02B = Math.floor(widthP / (lengthB + (2 * widthB)))
        numBunBlock = blocksB * (xlengthPlengthB + wlengthPwidthB)
        numBunBlocks02 = blocks02B * (wlengthPwidthB + (2 * xlengthPlengthB))
        maxBundleP = Math.max(numBunBlock, numBunBlocks02, maxBundle01, maxBundle02)

        if ((maxBundle01 >= maxBundle02) && (maxBundle01 >= numBunBlock) && (maxBundle01 >= numBunBlocks02)){
          maxBundleP = maxBundle01;
          numBundleLength = xlengthPlengthB;
          numBundleWidth = zwidthPwidthB;
          lengthPalletF = numBundleLength * lengthB
          widthPalletF = numBundleWidth * widthB
        }else if ((maxBundle02 >= maxBundle01) && (maxBundle02 >= numBunBlock) && (maxBundle02 >= numBunBlocks02)){
          maxBundleP = maxBundle02;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = ywidthPlengthB;
          lengthPalletF = numBundleLength * widthB;
          widthPalletF = numBundleWidth * lengthB;
        }else if ((numBunBlock >= maxBundle01) && (numBunBlock >= maxBundle02) && (numBunBlock >= numBunBlocks02)){
          maxBundleP = numBunBlock;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = 2 * blocksB;
          lengthPalletF = numBundleLength * widthB;
          widthPalletF = blocksB * (lengthB + widthB);
        }else if ((numBunBlocks02 >= maxBundle01) && (numBunBlocks02 >= maxBundle02) && (numBunBlocks02 >= numBunBlock)){
          maxBundleP = numBunBlocks02;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = 3 * blocks02B;
          lengthPalletF = numBundleLength * widthB
          widthPalletF = blocks02B * (lengthB + (2 * widthB))
        };
      }else if (((lengthB + widthB) > widthP) && (widthB <= (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
        maxBundleP = numBundleLength + numBundleWidth;
        widthPalletF = 2 * widthB;
        lengthPalletF = lengthB + widthB;
      }else if (((lengthB + widthB) > widthP) && (widthB > (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
        maxBundleP = numBundleLength * numBundleWidth;
        widthPalletF = numBundleLength * widthB;
        lengthPalletF = numBundleWidth * lengthB;
      }else if (((lengthB + widthB) > lengthP) && (lengthB < lengthP)) {
        maxBundleP = wlengthPwidthB;
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
        lengthPalletF = maxBundleP * widthB;
        widthPalletF = lengthB; 
      }else if (((lengthB + widthB) > lengthP) && (lengthB > lengthP)){
        maxBundleP = wlengthPwidthB;
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
        if(widthB > 1.25){
          lengthPalletF = maxBundleP * widthB;
          widthPalletF =  lengthB;
        }else {
          lengthPalletF = lengthB;
          widthPalletF = maxBundleP * widthB; 
        }; 
      };
      //test orientation of pallet on the truck
      if((lengthPalletF <= 1.2) && (lengthPalletF > widthPalletF)){
        let newlengthPalletF: number = widthPalletF;
        widthPalletF = lengthPalletF;
        lengthPalletF = newlengthPalletF;
      };
      //ende test
      this.service.quoteItems[0].Pallet = false;
    }else if (this.service.formData.CargoType == "Con Tarima"){
      this.onTypeCargoTarima();
      this.service.widthPallet = this.palletList.find(listPallet => listPallet.PalletID == this.service.formData.PalletID).Width;
      this.service.lengthPallet = this.palletList.find(listPallet => listPallet.PalletID == this.service.formData.PalletID).Length;
      this.service.heightPallet = this.palletList.find(listPallet => listPallet.PalletID == this.service.formData.PalletID).Height;
      widthP = this.service.widthPallet / 100;
      lengthP = this.service.lengthPallet / 100;
      heightP = 1.2 - (this.service.heightPallet / 100);
      widthPalletF = widthP;
      lengthPalletF = lengthP;
      //test orientation of pallet on the truck
      if((lengthPalletF <= 1.2) && (lengthPalletF > widthPalletF)){
        let newlengthPalletF: number = widthPalletF;
        widthPalletF = lengthPalletF;
        lengthPalletF = newlengthPalletF;
      };
      //ende test
      layerP = Math.round(heightP / heightBundle);
      heightPalletF = parseFloat((layerP * heightBundle).toFixed(3));
      lengthB = Math.max(this.service.lengthBundle, this.service.widthBundle);
      widthB = Math.min(this.service.lengthBundle, this.service.widthBundle);
      if((lengthB > lengthP) || (widthB > widthP)){
        this.toastr.error('Revise por favor favor el tipo de TARIMA o las MEDIDAS de la caja, '+ 
          'las dimensiones finales del ATADO sobrepasan las medidas de la TARIMA ' +
          'para este símbolo:', 'Corrugated Quote.');
        exit();
      };

      areaBundle = this.service.lengthBundle * this.service.widthBundle;
      xlengthPlengthB = Math.floor(lengthP / lengthB);
      ywidthPlengthB = Math.floor(widthP / lengthB);
      wlengthPwidthB = Math.floor(lengthP / widthB);
      zwidthPwidthB = Math.floor(widthP / widthB);
      if(xlengthPlengthB == 0 && ywidthPlengthB == 0 && wlengthPwidthB == 0 && zwidthPwidthB == 0 ){
        ywidthPlengthB = 1;
        wlengthPwidthB = 1;
      };
      if(widthB > 1.25 && lengthB > widthB){
        layerPallet = 2;
      }else {
        layerPallet = 4;
      };
      //number maximun boundle per pallet
      maxBundle01 = xlengthPlengthB * zwidthPwidthB;
      maxBundle02 = ywidthPlengthB * wlengthPwidthB;
      //end
      if ((lengthB + widthB) <= widthP) {
        blocksB = Math.floor(widthP / (lengthB + widthB))
        blocks02B = Math.floor(widthP / (lengthB + (2 * widthB)))
        numBunBlock = blocksB * (xlengthPlengthB + wlengthPwidthB)
        numBunBlocks02 = blocks02B * (wlengthPwidthB + (2 * xlengthPlengthB))
        maxBundleP = Math.max(numBunBlock, numBunBlocks02, maxBundle01, maxBundle02)
        if ((maxBundle01 >= maxBundle02) && (maxBundle01 >= numBunBlock) && (maxBundle01 >= numBunBlocks02)){
          maxBundleP = maxBundle01;
          numBundleLength = xlengthPlengthB;
          numBundleWidth = zwidthPwidthB;
        }else if ((maxBundle02 >= maxBundle01) && (maxBundle02 >= numBunBlock) && (maxBundle02 >= numBunBlocks02)){
          maxBundleP = maxBundle02;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = ywidthPlengthB;  
        }else if ((numBunBlock >= maxBundle01) && (numBunBlock >= maxBundle02) && (numBunBlock >= numBunBlocks02)){
          maxBundleP = numBunBlock;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = 2 * blocksB;  
        }else if ((numBunBlocks02 >= maxBundle01) && (numBunBlocks02 >= maxBundle02) && (numBunBlocks02 >= numBunBlock)){
          maxBundleP = numBunBlocks02;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = 3 * blocks02B;    
        };
      }else if (((lengthB + widthB) > widthP) && (widthB <= (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
        maxBundleP = numBundleLength + numBundleWidth;
      }else if (((lengthB + widthB) > widthP) && (widthB > (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
        maxBundleP = numBundleLength * numBundleWidth;
      }else if (((lengthB + widthB) > lengthP) && (lengthB < lengthP)) {
        maxBundleP = wlengthPwidthB;
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
      };
    };
          
      boxPallet = (maxBundleP * this.service.quoteItems[0].BoxBundle * layerP);
      this.service.numPalletCargo = Math.ceil(this.service.formData.BoxShipment / (boxPallet))
      lengthCargo = Math.ceil(this.service.numPalletCargo / layerPallet) * lengthPalletF;
    //test orientation of pallet on the trucks
      if(((2 * widthPalletF) < (widthPalletF + lengthPalletF)) && ((widthPalletF + lengthPalletF) <= (2.4))){
        //lengthCargo = Math.min((Math.ceil(this.service.numPalletCargo / layerPallet) * lengthPalletF), (Math.ceil(this.service.numPalletCargo / layerPallet) * widthPalletF));
        let sideWidth: number = 0; let sideLength: number = 0;
        let newPalletComb: number = 0; let lengthCargoWidth: number = 0; 
        let numPalletWidth: number = 0; let numPalletLength: number = 0; 
        palletArrangement = 1;
        lengthCargo = 0;
        for(let indexPallet: number = 1; newPalletComb <= (this.service.numPalletCargo - layerPallet); indexPallet++){
          sideWidth = sideWidth + (boxPallet * layerPallet / 2);
          sideLength = sideLength + (boxPallet * layerPallet / 2);
          newPalletComb = newPalletComb + layerPallet;
          lengthCargoWidth = lengthCargoWidth + widthPalletF
          lengthCargo = lengthCargo + lengthPalletF
          if ((lengthCargo - lengthCargoWidth) > widthPalletF) {
            sideWidth = sideWidth + (boxPallet * layerPallet / 2);
            newPalletComb = newPalletComb + 1;
            lengthCargoWidth = lengthCargoWidth + widthPalletF;
          };
        };
        numPalletWidth = sideWidth / boxPallet;
        numPalletLength = sideLength / boxPallet;
        let leftPallet: number = 0;
        leftPallet = this.service.numPalletCargo - (numPalletWidth + numPalletLength);
        if(leftPallet < 0){
          if((leftPallet == -4) || (leftPallet == -3)){
            lengthCargo = ((numPalletLength - 2) / (layerPallet / 2)) * lengthPalletF;
            lengthCargoWidth = ((numPalletWidth - 2) / (layerPallet / 2)) * widthPalletF;
          }else if((leftPallet == -2) || (leftPallet == -1)) {
            lengthCargoWidth = lengthCargoWidth;
            lengthCargo = lengthCargoWidth;
          };
        };
        if(leftPallet > 0){
          if((leftPallet > 2) && ((lengthCargo - lengthCargoWidth) < widthPalletF)){
            lengthCargo = lengthCargo + lengthPalletF;
            lengthCargoWidth = lengthCargoWidth + widthPalletF;
          }else if((leftPallet > 2) && ((lengthCargo - lengthCargoWidth) > widthPalletF)) {
            lengthCargoWidth = lengthCargoWidth + (2 * widthPalletF);
            lengthCargo = lengthCargoWidth;
          }else {
            lengthCargoWidth = lengthCargoWidth + widthPalletF;
            lengthCargo = lengthCargoWidth;
          };
        };
        palletArrangement = 1;
      }; 
      //end
      if(lengthCargo <= 2){
        indexTruck = 4;
      }else if (lengthCargo > 2 && lengthCargo <= 6.4){
        indexTruck = 3;
      }else if (lengthCargo > 6.4 && lengthCargo <= 6.9){
        indexTruck = 2;
      }else if (lengthCargo > 6.9 && lengthCargo <= 14.4){
        indexTruck = 1;
      }else if (lengthCargo > 14.4 && lengthCargo <= 24){
        indexTruck = 0;
      };
        this.service.formData.TruckName = this.truckTypeList[indexTruck].TruckName;
      volCargo = Math.floor(this.truckTypeList[indexTruck].Length / lengthPalletF) * layerPallet
      //test volum when you need aprovechar the capacity truck
      if (palletArrangement == 1){
        //volCargo = (Math.floor(this.truckTypeList[indexTruck].Length / lengthPalletF) * (layerPallet / 2)) + (Math.floor(this.truckTypeList[indexTruck].Length / widthPalletF) * (layerPallet / 2));
        volCargo = (layerPallet / 2) * (Math.floor(this.truckTypeList[indexTruck].Length / lengthPalletF) + Math.floor(this.truckTypeList[indexTruck].Length / widthPalletF));
        //volCargo =  Math.floor(this.service.numPalletCargo * this.truckTypeList[indexTruck].Length / lengthCargo);
      };
      //end
      this.service.formData.TruckID = this.truckTypeList[indexTruck].TruckID;
      this.service.formData.IdealTruck = this.service.formData.TruckName;
      this.service.formData.UsedCapacityTruck = Math.ceil(this.service.numPalletCargo * 100 / volCargo);
      //test
      this.service.fullCapacity = Math.floor(this.truckTypeList[0].Length / lengthPalletF) * layerPallet * boxPallet;
      this.service.trailerCapacity = Math.floor(this.truckTypeList[1].Length / lengthPalletF) * layerPallet * boxPallet;
      this.service.tortonCapacity = Math.floor(this.truckTypeList[2].Length / lengthPalletF) * layerPallet * boxPallet;
      this.service.rabonCapacity = Math.floor(this.truckTypeList[3].Length / lengthPalletF) * layerPallet * boxPallet;
      this.service.camionetaCapacity = Math.floor(this.truckTypeList[4].Length / lengthPalletF) * layerPallet * boxPallet;
      //end test
      if (palletArrangement == 1){
        this.service.fullCapacity = (layerPallet / 2) * boxPallet * (Math.floor(this.truckTypeList[0].Length / lengthPalletF) + Math.floor(this.truckTypeList[0].Length / widthPalletF));
        this.service.trailerCapacity = (layerPallet / 2) * boxPallet * (Math.floor(this.truckTypeList[1].Length / lengthPalletF) + Math.floor(this.truckTypeList[1].Length / widthPalletF));
        this.service.tortonCapacity = (layerPallet / 2) * boxPallet * (Math.floor(this.truckTypeList[2].Length / lengthPalletF) + Math.floor(this.truckTypeList[2].Length / widthPalletF));
        this.service.rabonCapacity = (layerPallet / 2) * boxPallet * (Math.floor(this.truckTypeList[3].Length / lengthPalletF) + Math.floor(this.truckTypeList[3].Length / widthPalletF));
        this.service.camionetaCapacity = (layerPallet / 2) * boxPallet * (Math.floor(this.truckTypeList[4].Length / lengthPalletF) + Math.floor(this.truckTypeList[4].Length / widthPalletF));
      };
      if(this.service.editUsoCapacityTruck == true){
        this.service.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / (Math.ceil(this.truckTypeList[indexTruckSelected].Length / lengthPalletF) * layerPallet));
        if (palletArrangement == 1){
          this.service.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / Math.ceil(this.truckTypeList[indexTruckSelected].Length / lengthCargo));
        };
      };
          
      if(this.service.formData.UsedCapacityTruck > 100){
        this.toastr.error('La cantidad de piezas sobrepasa la capadidad de la unidad mas grande: FULL, '+ 
        'por favor consulte en la etiqueta TRANSPORTE cuánto puede embarcar por cada tipo de unidad ' +
        'para este símbolo:', 'Corrugated Quote.');
      };
    };
    this.service.quoteItems[0].BundleAlongPallet = numBundleLength;
    this.service.quoteItems[0].BundleWidthPallet = numBundleWidth;
    this.service.quoteItems[0].Layer = layerP;
    this.service.layerBundle = maxBundleP;
    this.service.originalQuantityShipment = this.service.formData.BoxShipment;
    this.service.formData.PalletLength = lengthPalletF;
    this.service.formData.PalletWidth = widthPalletF;
    this.service.formData.PalletHeight = heightPalletF;
    this.service.formData.PalletProduced = Math.ceil(this.service.formData.AverageOrder / (boxPallet))
    this.service.formData.PalletNumber = this.service.numPalletCargo;
    this.service.formData.BundleVolum = volBundle;
    this.service.formData.BundlePallet = (boxPallet / this.service.quoteItems[0].BoxBundle);
    this.service.editUsoCapacityTruck = true;
  };

  onCargoEditTruck(ctrl){
    let numberBundle: number; let volBundle: number = 0;
    let volCargo: number;
    let numNewPalletCargo: number;
    let palletbox: number;

    this.service.NewOrEdit = true;
    if(this.service.quoteItems[0].StyleID == 6){
      volBundle = this.service.formData.BundleVolum * this.service.quoteItems[0].Layer;
      numberBundle = Math.ceil(this.service.formData.BoxShipment / this.service.quoteItems[0].Layer)
      palletbox = this.service.quoteItems[0].Layer * this.service.layerBundle;
    }else {
      volBundle = this.service.formData.BundleVolum;
      numberBundle = Math.ceil(this.service.formData.BoxShipment / this.service.quoteItems[0].BoxBundle)
      palletbox = this.service.quoteItems[0].BoxBundle * this.service.formData.BundlePallet;
    };
    if (this.service.formData.CargoType == "Granel"){
      volCargo = volBundle * numberBundle
      this.service.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / this.truckTypeList[ctrl.selectedIndex-1].CapacityM3);
      for(let index: number = 0 ; index < this.truckTypeList.length && volCargo <= this.truckTypeList[index].CapacityM3; index++){
        this.service.formData.IdealTruck = this.truckTypeList[index].TruckName;
      };
      this.service.formData.PalletID = 0;
      if(volCargo > this.truckTypeList[0].CapacityM3){
        this.toastr.error('La cantidad de piezas sobrepasa la capadidad de la unidad mas grande: FULL, '+ 
        'por favor consulte en la etiqueta TRANSPORTE cuánto puede embarcar por cada tipo de unidad ' +
        'para este símbolo:', 'Corrugated Quote.');
        //this.service.formData.UsedCapacityTruck = Math.floor(volCargo * 100 / this.truckTypeList[0].CapacityM3)
      };
    }else {
      let layerPallet: number = 4;
      if((this.service.formData.PalletLength > 1.25) && (this.service.formData.PalletLength < this.service.formData.PalletWidth)){
        layerPallet = 2;
        numNewPalletCargo = layerPallet * Math.floor(this.truckTypeList[ctrl.selectedIndex-1].Length / this.service.formData.PalletLength);
      }else {
        layerPallet = 4;
        numNewPalletCargo = layerPallet * Math.floor(this.truckTypeList[ctrl.selectedIndex-1].Length / this.service.formData.PalletLength);
      };
      //test orientation of pallet on the trucks
      if(((2 * this.service.formData.PalletWidth) < (this.service.formData.PalletWidth + this.service.formData.PalletLength)) && ((this.service.formData.PalletWidth + this.service.formData.PalletLength) <= (2.4))){
        numNewPalletCargo = (layerPallet / 2) * (Math.floor(this.truckTypeList[ctrl.selectedIndex-1].Length / this.service.formData.PalletLength) + Math.floor(this.truckTypeList[ctrl.selectedIndex-1].Length / this.service.formData.PalletWidth))
      };
      this.service.formData.UsedCapacityTruck = Math.ceil(this.service.formData.PalletNumber * 100 / numNewPalletCargo);  
      //end
    };
    if(this.service.formData.UsedCapacityTruck > 100){
      this.toastr.error('La cantidad de piezas sobrepasa la capadidad de la unidad SELECCIONADA, '+ 
      'por favor consulte en la etiqueta TRANSPORTE cuánto puede embarcar por cada tipo de unidad ' +
      'para este símbolo:', 'Corrugated Quote.');
    };
  };

  onCargoEditShipment(){
    let numberBundle: number; let volBundle: number = 0;
    let volCargo: number; let volTruck: number = 0;
    let numNewPalletCargo: number; let lengthCargo: number;
    let indexTruck: number = 0; let layerPallet: number = 4;

    this.service.NewOrEdit = true;
      //Volum one bundle
    volTruck = this.truckTypeList.find(trucktypeList => trucktypeList.TruckID == this.service.formData.TruckID).CapacityM3;
      //number bundle for shipment/freigh
    if(this.service.quoteItems[0].StyleID == 6){
      volBundle = this.service.formData.BundleVolum * this.service.quoteItems[0].Layer;
      numberBundle = Math.ceil(this.service.formData.BoxShipment / this.service.quoteItems[0].Layer);
    }else {
      volBundle = this.service.formData.BundleVolum;
      numberBundle = Math.ceil(this.service.formData.BoxShipment / this.service.quoteItems[0].BoxBundle);
    };
    //unitization
    if (this.service.formData.CargoType == "Granel"){
      volCargo = volBundle * numberBundle
      this.service.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / volTruck);
      for(let index: number = 0 ; index < this.truckTypeList.length && volCargo <= this.truckTypeList[index].CapacityM3; index++){
        this.service.formData.IdealTruck = this.truckTypeList[index].TruckName;
      };
      this.service.formData.PalletID = 0;
      if(volCargo > this.truckTypeList[0].CapacityM3){
        this.toastr.error('La cantidad de piezas sobrepasa la capadidad de la unidad mas grande: FULL, '+ 
        'por favor consulte en la etiqueta TRANSPORTE cuánto puede embarcar por cada tipo de unidad ' +
        'para este símbolo:', 'Corrugated Quote.');
      };
    }else {
      let palletbox: number;
      if(this.service.quoteItems[0].StyleID == 6){
        palletbox = this.service.quoteItems[0].Layer * this.service.layerBundle;
        numNewPalletCargo = Math.ceil(this.service.formData.BoxShipment / (this.service.quoteItems[0].Layer * this.service.layerBundle));
      }else {
        palletbox = this.service.quoteItems[0].BoxBundle * this.service.formData.BundlePallet;
        numNewPalletCargo = Math.ceil(this.service.formData.BoxShipment / (this.service.quoteItems[0].BoxBundle * this.service.formData.BundlePallet));
      };   
      this.service.numPalletCargo = numNewPalletCargo;
      if((this.service.formData.PalletLength > 1.25) && (this.service.formData.PalletLength < this.service.formData.PalletWidth)){
        layerPallet = 2;
        //lengthCargo = Math.ceil(this.service.numPalletCargo / layerPallet) * this.service.formData.PalletLength;
      }else {
        layerPallet = 4;
      };
      lengthCargo = Math.ceil(this.service.numPalletCargo / layerPallet) * this.service.formData.PalletLength;
      //test orientation of pallet on the trucks
      if(((2 * this.service.formData.PalletWidth) < (this.service.formData.PalletWidth + this.service.formData.PalletLength)) && ((this.service.formData.PalletWidth + this.service.formData.PalletLength) <= (2.4))){         
        let sideWidth: number = 0; let sideLength: number = 0;
        let newPalletComb: number = 0; let lengthCargoWidth: number = 0; 
        let numPalletWidth: number = 0; let numPalletLength: number = 0; 
        lengthCargo = 0;
        for(let indexPallet: number = 1; newPalletComb <= (this.service.numPalletCargo - layerPallet); indexPallet++){
          sideWidth = sideWidth + (palletbox * layerPallet / 2);
          sideLength = sideLength + (palletbox * layerPallet / 2);
          newPalletComb = newPalletComb + layerPallet;
          lengthCargoWidth = lengthCargoWidth + this.service.formData.PalletWidth
          lengthCargo = lengthCargo + this.service.formData.PalletLength
          if ((lengthCargo - lengthCargoWidth) > this.service.formData.PalletWidth) {
            sideWidth = sideWidth + (palletbox * layerPallet / 2);
            newPalletComb = newPalletComb + 1;
            lengthCargoWidth = lengthCargoWidth + this.service.formData.PalletWidth;
          };
        };
        numPalletWidth = sideWidth / palletbox;
        numPalletLength = sideLength / palletbox;
        let leftPallet: number = 0;
        leftPallet = this.service.numPalletCargo - (numPalletWidth + numPalletLength);
        if(leftPallet < 0){
          if((leftPallet == -4) || (leftPallet == -3)){
            lengthCargo = ((numPalletLength - 2) / (layerPallet / 2)) * this.service.formData.PalletLength;
            lengthCargoWidth = ((numPalletWidth - 2) / (layerPallet / 2)) * this.service.formData.PalletWidth;
          }else if((leftPallet == -2) || (leftPallet == -1)) {
            lengthCargoWidth = lengthCargoWidth;
            lengthCargo = lengthCargoWidth;
          };
        };
        if(leftPallet > 0){
          if((leftPallet > 2) && ((lengthCargo - lengthCargoWidth) < this.service.formData.PalletWidth)){
            lengthCargo = lengthCargo + this.service.formData.PalletLength;
            lengthCargoWidth = lengthCargoWidth + this.service.formData.PalletWidth;
          }else if((leftPallet > 2) && ((lengthCargo - lengthCargoWidth) > this.service.formData.PalletWidth)) {
            lengthCargoWidth = lengthCargoWidth + (2 * this.service.formData.PalletWidth);
            lengthCargo = lengthCargoWidth;
          }else {
            lengthCargoWidth = lengthCargoWidth + this.service.formData.PalletWidth;
            lengthCargo = lengthCargoWidth;
          };
        };
        //palletArrangement = 1;
        volCargo = (layerPallet / 2) * (Math.floor(this.truckTypeList.find(lengths => lengths.TruckID == this.service.formData.TruckID).Length / this.service.formData.PalletLength) + Math.floor(this.truckTypeList.find(lengths => lengths.TruckID == this.service.formData.TruckID).Length / this.service.formData.PalletWidth));
      }; 
      //end
      if(lengthCargo <= 2){
        indexTruck = 4;
      }else if (lengthCargo > 2 && lengthCargo <= 6.4){
        indexTruck = 3;
      }else if (lengthCargo > 6.4 && lengthCargo <= 6.9){
        indexTruck = 2;
      }else if (lengthCargo > 6.9 && lengthCargo <= 14.4){
        indexTruck = 1;
      }else if (lengthCargo > 14.4){
        indexTruck = 0;
      };
      this.service.formData.IdealTruck = this.truckTypeList[indexTruck].TruckName;
      //this.service.formData.UsedCapacityTruck = Math.ceil(lengthCargo * 100 / this.truckTypeList.find(trucktypeList => trucktypeList.TruckID == this.service.formData.TruckID).Length);
      this.service.formData.UsedCapacityTruck = Math.ceil(this.service.numPalletCargo * 100 / volCargo);
    };
      
    if(this.service.quoteItems[0].StyleID == 6){
      this.service.formData.PalletProduced = Math.ceil(this.service.formData.AverageOrder / this.service.layerBundle)
    }else {
      this.service.formData.PalletProduced = Math.ceil(this.service.formData.AverageOrder / this.service.formData.BundlePallet)
    };  
    this.service.formData.PalletNumber = this.service.numPalletCargo;
    if(this.service.formData.UsedCapacityTruck > 100){
      this.toastr.error('La cantidad de piezas sobrepasa la capadidad de la unidad SELECCIONADA, '+ 
      'por favor consulte en la etiqueta TRANSPORTE cuánto puede embarcar por cada tipo de unidad ' +
      'para este símbolo:', 'Corrugated Quote.');
    };
  };
//codig for sheet cargo

  onCargoTypeQuoteSheet(){
    let areaBundle: number; let numberBundle: number; let volBundle: number = 0;
    let heightBundle: number; let thickness: number; let volCargo: number;
    //Variables paletizations
    let xlengthPlengthB: number;  let ywidthPlengthB: number; 
    let wlengthPwidthB: number;  let zwidthPwidthB: number;
    let lengthB: number; let widthB: number; //let lengthBundle: number; let widthBundle: number;
    let lengthP: number = 1.2; let widthP: number = 1.05; let heightP: number = 1.2;
    let layerP: number; //let numPalletCargo: number; 
    let maxBundle01: number; let maxBundle02: number; let maxBundleP: number;
    let numBundleWidth: number; let numBundleLength: number;
    let lengthPalletF: number; let widthPalletF: number; let heightPalletF: number;
    let blocksB: number; let blocks02B: number;
    let numBunBlock: number; let numBunBlocks02: number;
    let lengthCargo: number;
    let boxPallet: number; let indexTruck: number = 0;
    let indexTruckSelected: number = 0;
    //end
    //lineal meters, caliper.
    this.service.NewOrEdit = true;
    thickness = this.boardList.find(thicknes => thicknes.BoardID == this.service.quoteItems[0].BoardID).Thickness / 1000;
    this.service.lengthBundle = parseFloat((this.service.quoteItems[0].LengthBoard / 1000).toFixed(3));
    this.service.widthBundle = parseFloat((this.service.quoteItems[0].WidthBoard / 1000).toFixed(3));
    areaBundle = parseFloat((this.service.lengthBundle * this.service.widthBundle).toFixed(3));
    heightBundle = parseFloat((thickness).toFixed(3));

      //Volum one bundleSheet
      volBundle = parseFloat((areaBundle * heightBundle).toFixed(3));
      //number bundle for shipment/freigh
      
      indexTruckSelected = this.truckTypeList.findIndex(indextruck => indextruck.TruckID == this.service.formData.TruckID); 
      //unitization
      if (this.service.formData.CargoType == "Granel"){
        widthP = 1.15;
        lengthP = 1.20;
        heightP = 1.20;
        layerP = Math.round(heightP / heightBundle);
        this.service.quoteItems[0].Layer = layerP;
        numberBundle = Math.ceil(this.service.formData.BoxShipment / this.service.quoteItems[0].Layer)
        volCargo = volBundle * numberBundle * this.service.quoteItems[0].Layer;
        //this part is for determineted the Ideal Track
        for(let index = 0 ; index < this.truckTypeList.length && volCargo <= this.truckTypeList[index].CapacityM3; index++){
          this.service.formData.IdealTruck = this.truckTypeList[index].TruckName;
          this.service.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / this.truckTypeList[index].CapacityM3);
          this.service.formData.TruckID = this.truckTypeList[index].TruckID;
        };
        this.service.formData.PalletID = 0
        //test
        this.service.fullCapacity = Math.floor((this.truckTypeList[0].CapacityM3 / volBundle));
        this.service.trailerCapacity = Math.floor((this.truckTypeList[1].CapacityM3 / volBundle));
        this.service.tortonCapacity = Math.floor((this.truckTypeList[2].CapacityM3 / volBundle));
        this.service.rabonCapacity = Math.floor((this.truckTypeList[3].CapacityM3 / volBundle));
        this.service.camionetaCapacity = Math.floor((this.truckTypeList[4].CapacityM3 / volBundle));
        
        
        heightPalletF = parseFloat((layerP * heightBundle).toFixed(3));
        lengthB = Math.max(this.service.lengthBundle, this.service.widthBundle);
        widthB = Math.min(this.service.lengthBundle, this.service.widthBundle);

          areaBundle = this.service.lengthBundle * this.service.widthBundle;
          xlengthPlengthB = Math.floor(lengthP / lengthB);
          ywidthPlengthB = Math.floor(widthP / lengthB);
          wlengthPwidthB = Math.floor(lengthP / widthB);
          zwidthPwidthB = Math.floor(widthP / widthB);

          //number maximun boundle per pallet
          maxBundle01 = xlengthPlengthB * zwidthPwidthB;
          maxBundle02 = ywidthPlengthB * wlengthPwidthB;

          //end
          if ((lengthB + widthB) <= widthP) {
            blocksB = Math.floor(widthP / (lengthB + widthB))
            blocks02B = Math.floor(widthP / (lengthB + (2 * widthB)))
            numBunBlock = blocksB * (xlengthPlengthB + wlengthPwidthB)
            numBunBlocks02 = blocks02B * (wlengthPwidthB + (2 * xlengthPlengthB))
            maxBundleP = Math.max(numBunBlock, numBunBlocks02, maxBundle01, maxBundle02)


            if ((maxBundle01 >= maxBundle02) && (maxBundle01 >= numBunBlock) && (maxBundle01 >= numBunBlocks02)){
              maxBundleP = maxBundle01;
              numBundleLength = xlengthPlengthB;
              numBundleWidth = zwidthPwidthB;
              lengthPalletF = numBundleLength * lengthB
              widthPalletF = numBundleWidth * widthB
            }else if ((maxBundle02 >= maxBundle01) && (maxBundle02 >= numBunBlock) && (maxBundle02 >= numBunBlocks02)){
              maxBundleP = maxBundle02;
              numBundleLength = wlengthPwidthB;
              numBundleWidth = ywidthPlengthB;
              lengthPalletF = numBundleLength * widthB;
              widthPalletF = numBundleWidth * lengthB;
            }else if ((numBunBlock >= maxBundle01) && (numBunBlock >= maxBundle02) && (numBunBlock >= numBunBlocks02)){
              maxBundleP = numBunBlock;
              numBundleLength = wlengthPwidthB;
              numBundleWidth = 2 * blocksB;
              lengthPalletF = numBundleLength * widthB;
              widthPalletF = blocksB * (lengthB + widthB);
            }else if ((numBunBlocks02 >= maxBundle01) && (numBunBlocks02 >= maxBundle02) && (numBunBlocks02 >= numBunBlock)){
              maxBundleP = numBunBlocks02;
              numBundleLength = wlengthPwidthB;
              numBundleWidth = 3 * blocks02B;
              lengthPalletF = numBundleLength * widthB
              widthPalletF = blocks02B * (lengthB + (2 * widthB))
            };
          }else if (((lengthB + widthB) > widthP) && (widthB <= (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
            numBundleLength = wlengthPwidthB;
            numBundleWidth = ywidthPlengthB;
            maxBundleP = numBundleLength + numBundleWidth;
            widthPalletF = 2 * widthB;
            lengthPalletF = lengthB + widthB;
          }else if (((lengthB + widthB) > widthP) && (widthB > (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
            numBundleLength = wlengthPwidthB;
            numBundleWidth = ywidthPlengthB;
            maxBundleP = numBundleLength * numBundleWidth;
            widthPalletF = numBundleLength * widthB;
            lengthPalletF = numBundleWidth * lengthB;
          }else if (((lengthB + widthB) > lengthP) && (lengthB < lengthP)) {
            maxBundleP = wlengthPwidthB;
            numBundleLength = wlengthPwidthB;
            numBundleWidth = ywidthPlengthB;
            lengthPalletF = maxBundleP * widthB;
            widthPalletF = lengthB; 
          }else if (((lengthB + widthB) > lengthP) && (lengthB > lengthP)){
            maxBundleP = wlengthPwidthB;
            numBundleLength = wlengthPwidthB;
            numBundleWidth = ywidthPlengthB;
            lengthPalletF = lengthB;
            widthPalletF = maxBundleP * widthB; 
          };
          //test orientation of pallet on the truck
          if((lengthPalletF <= 1.2) && (lengthPalletF > widthPalletF)){
            let newlengthPalletF: number = widthPalletF;
            widthPalletF = lengthPalletF;
            lengthPalletF = newlengthPalletF;
          };
          //ende test
          boxPallet = (maxBundleP * this.service.quoteItems[0].Layer * layerP);
          this.service.numPalletCargo = Math.ceil(this.service.formData.BoxShipment / (boxPallet))
          this.service.quoteItems[0].Pallet = false;
          if(this.service.editUsoCapacityTruck == true){
            this.service.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / this.truckTypeList[indexTruckSelected].CapacityM3);
          };
        
        if(volCargo > this.truckTypeList[0].CapacityM3){
          this.toastr.error('La cantidad de piezas sobrepasa la capadidad de la unidad mas grande: FULL, '+ 
          'por favor consulte en la etiqueta TRANSPORTE cuánto puede embarcar por cada tipo de unidad ' +
          'para este símbolo:', 'Corrugated Quote.');
          this.service.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / this.truckTypeList[0].CapacityM3)
        };

        //end test
      }else {  
        if (this.service.formData.CargoType == "Sin Tarima"){
          widthP = 1.15;
          lengthP = 1.20;
          heightP = 1.20;
          layerP = Math.round(heightP / heightBundle);
          this.service.quoteItems[0].Layer = layerP;
          numberBundle = Math.ceil(this.service.formData.BoxShipment / this.service.quoteItems[0].Layer);

          heightPalletF = parseFloat((layerP * heightBundle).toFixed(3));
          lengthB = Math.max(this.service.lengthBundle, this.service.widthBundle);
          widthB = Math.min(this.service.lengthBundle, this.service.widthBundle);

            areaBundle = this.service.lengthBundle * this.service.widthBundle;
            xlengthPlengthB = Math.floor(lengthP / lengthB);
            ywidthPlengthB = Math.floor(widthP / lengthB);
            wlengthPwidthB = Math.floor(lengthP / widthB);
            zwidthPwidthB = Math.floor(widthP / widthB);

            //number maximun boundle per pallet
            maxBundle01 = xlengthPlengthB * zwidthPwidthB;
            maxBundle02 = ywidthPlengthB * wlengthPwidthB;

            //end
            if ((lengthB + widthB) <= widthP) {
              blocksB = Math.floor(widthP / (lengthB + widthB))
              blocks02B = Math.floor(widthP / (lengthB + (2 * widthB)))
              numBunBlock = blocksB * (xlengthPlengthB + wlengthPwidthB)
              numBunBlocks02 = blocks02B * (wlengthPwidthB + (2 * xlengthPlengthB))
              maxBundleP = Math.max(numBunBlock, numBunBlocks02, maxBundle01, maxBundle02)


              if ((maxBundle01 >= maxBundle02) && (maxBundle01 >= numBunBlock) && (maxBundle01 >= numBunBlocks02)){
                maxBundleP = maxBundle01;
                numBundleLength = xlengthPlengthB;
                numBundleWidth = zwidthPwidthB;
                lengthPalletF = numBundleLength * lengthB
                widthPalletF = numBundleWidth * widthB
              }else if ((maxBundle02 >= maxBundle01) && (maxBundle02 >= numBunBlock) && (maxBundle02 >= numBunBlocks02)){
                maxBundleP = maxBundle02;
                numBundleLength = wlengthPwidthB;
                numBundleWidth = ywidthPlengthB;
                lengthPalletF = numBundleLength * widthB;
                widthPalletF = numBundleWidth * lengthB;
              }else if ((numBunBlock >= maxBundle01) && (numBunBlock >= maxBundle02) && (numBunBlock >= numBunBlocks02)){
                maxBundleP = numBunBlock;
                numBundleLength = wlengthPwidthB;
                numBundleWidth = 2 * blocksB;
                lengthPalletF = numBundleLength * widthB;
                widthPalletF = blocksB * (lengthB + widthB);
              }else if ((numBunBlocks02 >= maxBundle01) && (numBunBlocks02 >= maxBundle02) && (numBunBlocks02 >= numBunBlock)){
                maxBundleP = numBunBlocks02;
                numBundleLength = wlengthPwidthB;
                numBundleWidth = 3 * blocks02B;
                lengthPalletF = numBundleLength * widthB
                widthPalletF = blocks02B * (lengthB + (2 * widthB))
              };
            }else if (((lengthB + widthB) > widthP) && (widthB <= (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
              numBundleLength = wlengthPwidthB;
              numBundleWidth = ywidthPlengthB;
              maxBundleP = numBundleLength + numBundleWidth;
              widthPalletF = 2 * widthB;
              lengthPalletF = lengthB + widthB;
            }else if (((lengthB + widthB) > widthP) && (widthB > (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
              numBundleLength = wlengthPwidthB;
              numBundleWidth = ywidthPlengthB;
              maxBundleP = numBundleLength * numBundleWidth;
              widthPalletF = numBundleLength * widthB;
              lengthPalletF = numBundleWidth * lengthB;
            }else if (((lengthB + widthB) > lengthP) && (lengthB < lengthP)) {
              maxBundleP = wlengthPwidthB;
              numBundleLength = wlengthPwidthB;
              numBundleWidth = ywidthPlengthB;
              lengthPalletF = maxBundleP * widthB;
              widthPalletF = lengthB; 
            }else if (((lengthB + widthB) > lengthP) && (lengthB > lengthP)){
              maxBundleP = wlengthPwidthB;
              numBundleLength = wlengthPwidthB;
              numBundleWidth = ywidthPlengthB;
              lengthPalletF = lengthB;
              widthPalletF = maxBundleP * widthB; 
            };
            //test orientation of pallet on the truck
           if((lengthPalletF <= 1.2) && (lengthPalletF > widthPalletF)){
            let newlengthPalletF: number = widthPalletF;
            widthPalletF = lengthPalletF;
            lengthPalletF = newlengthPalletF;
          };
          //ende test
            this.service.quoteItems[0].Pallet = false;
        }else if (this.service.formData.CargoType == "Con Tarima"){
          this.onTypeCargoTarima();
          this.service.widthPallet = this.palletList.find(listPallet => listPallet.PalletID == this.service.formData.PalletID).Width;
          this.service.lengthPallet = this.palletList.find(listPallet => listPallet.PalletID == this.service.formData.PalletID).Length;
          this.service.heightPallet = this.palletList.find(listPallet => listPallet.PalletID == this.service.formData.PalletID).Height;
          widthP = this.service.widthPallet / 100;
          lengthP = this.service.lengthPallet / 100;
          heightP = 1.2 - (this.service.heightPallet / 100);
          widthPalletF = widthP;
          lengthPalletF = lengthP;
          //test orientation of pallet on the truck
          if((lengthPalletF <= 1.2) && (lengthPalletF > widthPalletF)){
            let newlengthPalletF: number = widthPalletF;
            widthPalletF = lengthPalletF;
            lengthPalletF = newlengthPalletF;
          };
          //ende test
          layerP = Math.round(heightP / heightBundle);
          this.service.quoteItems[0].Layer = layerP;
          numberBundle = Math.ceil(this.service.formData.BoxShipment / this.service.quoteItems[0].Layer);
          heightPalletF = parseFloat((layerP * heightBundle).toFixed(3));
          lengthB = Math.max(this.service.lengthBundle, this.service.widthBundle);
          widthB = Math.min(this.service.lengthBundle, this.service.widthBundle);

          if((lengthB > lengthP) || (widthB > widthP)){
            this.toastr.error('Revise por favor favor el tipo de TARIMA o las MEDIDAS de la Lámina, '+ 
              'las dimensiones finales de las Pilas sobrepasan las medidas de la TARIMA ' +
              'para este símbolo:', 'Corrugated Quote.');
              exit();
          };

            areaBundle = this.service.lengthBundle * this.service.widthBundle;
            xlengthPlengthB = Math.floor(lengthP / lengthB);
            ywidthPlengthB = Math.floor(widthP / lengthB);
            wlengthPwidthB = Math.floor(lengthP / widthB);
            zwidthPwidthB = Math.floor(widthP / widthB);

            //number maximun boundle per pallet
            maxBundle01 = xlengthPlengthB * zwidthPwidthB;
            maxBundle02 = ywidthPlengthB * wlengthPwidthB;

            //end
            if ((lengthB + widthB) <= widthP) {
              blocksB = Math.floor(widthP / (lengthB + widthB))
              blocks02B = Math.floor(widthP / (lengthB + (2 * widthB)))
              numBunBlock = blocksB * (xlengthPlengthB + wlengthPwidthB)
              numBunBlocks02 = blocks02B * (wlengthPwidthB + (2 * xlengthPlengthB))
              maxBundleP = Math.max(numBunBlock, numBunBlocks02, maxBundle01, maxBundle02)
              
              if ((maxBundle01 >= maxBundle02) && (maxBundle01 >= numBunBlock) && (maxBundle01 >= numBunBlocks02)){
                maxBundleP = maxBundle01;
                numBundleLength = xlengthPlengthB;
                numBundleWidth = zwidthPwidthB;
                //lengthPalletF = numBundleLength * lengthB
                //widthPalletF = numBundleWidth * widthB
              }else if ((maxBundle02 >= maxBundle01) && (maxBundle02 >= numBunBlock) && (maxBundle02 >= numBunBlocks02)){
                maxBundleP = maxBundle02;
                numBundleLength = wlengthPwidthB;
                numBundleWidth = ywidthPlengthB;
                //lengthPalletF = numBundleLength * widthB;
                //widthPalletF = numBundleWidth * lengthB;
              }else if ((numBunBlock >= maxBundle01) && (numBunBlock >= maxBundle02) && (numBunBlock >= numBunBlocks02)){
                maxBundleP = numBunBlock;
                numBundleLength = wlengthPwidthB;
                numBundleWidth = 2 * blocksB;
                //lengthPalletF = numBundleLength * widthB;
                //widthPalletF = blocksB * (lengthB + widthB);
              }else if ((numBunBlocks02 >= maxBundle01) && (numBunBlocks02 >= maxBundle02) && (numBunBlocks02 >= numBunBlock)){
                maxBundleP = numBunBlocks02;
                numBundleLength = wlengthPwidthB;
                numBundleWidth = 3 * blocks02B;
                //lengthPalletF = numBundleLength * widthB
                //widthPalletF = blocks02B * (lengthB + (2 * widthB))
              };
            }else if (((lengthB + widthB) > widthP) && (widthB <= (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
              numBundleLength = wlengthPwidthB;
              numBundleWidth = ywidthPlengthB;
              maxBundleP = numBundleLength + numBundleWidth;
              //widthPalletF = 2 * widthB;
              //lengthPalletF = lengthB + widthB;
            }else if (((lengthB + widthB) > widthP) && (widthB > (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
              numBundleLength = wlengthPwidthB;
              numBundleWidth = ywidthPlengthB;
              maxBundleP = numBundleLength * numBundleWidth;
              //widthPalletF = numBundleLength * widthB;
              //lengthPalletF = numBundleWidth * lengthB;
            }else if (((lengthB + widthB) > lengthP) && (lengthB < lengthP)) {
              maxBundleP = wlengthPwidthB;
              numBundleLength = wlengthPwidthB;
              numBundleWidth = ywidthPlengthB;
              //lengthPalletF = maxBundleP * widthB;
              //widthPalletF = lengthB; 
            };
        };
          
          boxPallet = (maxBundleP * this.service.quoteItems[0].Layer);
          this.service.numPalletCargo = Math.ceil(this.service.formData.BoxShipment / (boxPallet))
          lengthCargo = Math.ceil(this.service.numPalletCargo / 4) * lengthPalletF;
          
          if(lengthCargo <= 2){
            indexTruck = 4;
          }else if (lengthCargo > 2 && lengthCargo <= 6.4){
            indexTruck = 3;
          }else if (lengthCargo > 6.4 && lengthCargo <= 6.9){
            indexTruck = 2;
          }else if (lengthCargo > 6.9 && lengthCargo <= 14.4){
            indexTruck = 1;
          }else if (lengthCargo > 14.4 && lengthCargo <= 24){
            indexTruck = 0;
          };
          this.service.formData.TruckName = this.truckTypeList[indexTruck].TruckName;
          volCargo = Math.floor(this.truckTypeList[indexTruck].Length / lengthPalletF) * 4
          this.service.formData.TruckID = this.truckTypeList[indexTruck].TruckID;
          this.service.formData.IdealTruck = this.service.formData.TruckName;
          this.service.formData.UsedCapacityTruck = Math.ceil(this.service.numPalletCargo * 100 / volCargo);
          //test
          this.service.fullCapacity = Math.floor(this.truckTypeList[0].Length / lengthPalletF) * 4 * boxPallet
          this.service.trailerCapacity = Math.floor(this.truckTypeList[1].Length / lengthPalletF) * 4 * boxPallet
          this.service.tortonCapacity = Math.floor(this.truckTypeList[2].Length / lengthPalletF) * 4 * boxPallet
          this.service.rabonCapacity = Math.floor(this.truckTypeList[3].Length / lengthPalletF) * 4 * boxPallet
          this.service.camionetaCapacity = Math.floor(this.truckTypeList[4].Length / lengthPalletF) * 4 * boxPallet
          //end test
          if(this.service.editUsoCapacityTruck == true){
            this.service.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / (Math.ceil(this.truckTypeList[indexTruckSelected].Length / lengthPalletF) * 4));
          };
          
          if(this.service.formData.UsedCapacityTruck > 100){
            this.toastr.error('La cantidad de piezas sobrepasa la capadidad de la unidad mas grande: FULL, '+ 
            'por favor consulte en la etiqueta TRANSPORTE cuánto puede embarcar por cada tipo de unidad ' +
            'para este símbolo:', 'Corrugated Quote.');
          };

      };
      this.service.quoteItems[0].BundleAlongPallet = numBundleLength;
      this.service.quoteItems[0].BundleWidthPallet = numBundleWidth;
      //this.service.quoteItems[0].Layer = layerP;
      this.service.layerBundle = maxBundleP;
      this.service.originalQuantityShipment = this.service.formData.BoxShipment;
      this.service.formData.PalletLength = lengthPalletF;
      this.service.formData.PalletWidth = widthPalletF;
      this.service.formData.PalletHeight = heightPalletF;
      this.service.formData.PalletProduced = Math.ceil(this.service.formData.AverageOrder / (boxPallet))
      this.service.formData.PalletNumber = this.service.numPalletCargo;
      this.service.formData.BundleVolum = volBundle;
      this.service.formData.BundlePallet = (boxPallet / this.service.quoteItems[0].Layer);
      this.service.editUsoCapacityTruck = true;
  };

  onCopyQuote(){
    this.service.formData.QuoteID = null;
    this.service.formData.QuoteNumber = Math.floor(1000000 + Math.random()*9000000).toString();
    this.service.formData.QuoteDate = this.dateQuote;
    this.service.quoteItems[0].QuoteItemID = null;
    this.service.quoteItems[0].QuoteID = this.service.formData.QuoteID;
    this.onsaveQuote();
    this.service.openNewQuote = true;
    this.service.numOpenNewQuote = this.service.numOpenNewQuote + 1;
  };

  confirmCopyQuote(){
  this.dialog
  .open(DialogConfirm, {
    data: '¿Estas seguro de realizar una copia de la cotización?',
  })
  .afterClosed()
  .subscribe((confirmado: Boolean) => {
    if (confirmado) {
      this.onCopyQuote();
    }
      });
  /*this.toastr.success("ACEPTAR",'Deseas realizar una copia de la cotización?',
  {
      closeButton: false,
      enableHtml: true,
  });*/
  };

  okok(){
    this.toastr.success("Acepto","Titulo");
  };
  
   onProfitabilityQuote(){
    let totalIncome: number;
    totalIncome = this.service.formData.PriceMBox * this.service.formData.AverageOrder / 1000;
    this.service.formData.AddedValue = parseFloat((((totalIncome - this.service.quoteItems[0].CostMP) * 100) / totalIncome).toFixed(2));
    this.service.formData.ContributionMargin = parseFloat((((this.service.formData.PriceMBox - (this.service.formData.CostMBox - ((this.service.quoteItems[0].CostFixed + this.service.formData.SaleCommission + this.service.formData.FinancialExpense) * 1000 / this.service.formData.AverageOrder))) * 100) / this.service.formData.PriceMBox).toFixed(2));
    this.toastr.info('Valor Agregado: ' + this.service.formData.AddedValue + '%' + '__' + 
      ' Margen de Contribución: ' + this.service.formData.ContributionMargin + '%', 'Corrugated Quote.');
  };
};