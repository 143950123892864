<h4 class="display-5">Mantenimiento de Fletes </h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <div class="form-row">
        <div class="form-group col-md-1">
            <label>ID Flete</label>
            <input name="FreightID" #FreightID="ngModel" [(ngModel)]="freightService.freightList.FreightID" class="form-control"
            readonly>  
        </div>
        <div class="form-group col-md-7">
            <label>Destino</label>
            <select name="DestinyID" id="DestinyID" #DestinyID="ngModel" [(ngModel)]="freightService.freightList.DestinyID" 
            class="form-control">
                <option value="">Select</option>
                <option *ngFor="let item of destinyList" value="{{item.DestinyID}}">{{'Municipio: ' + item.Municipality + ', Ciudad: ' + item.City + ', Estado: ' + item.stateName + ', Cod. Postal: ' +  item.ZipCode}}</option>
            </select>    
        </div>
        <div class="form-group col-md-2">
            <label>Tipo de Unidad</label>
            <select name="TruckID" id="TruckID" #TruckID="ngModel" [(ngModel)]="freightService.freightList.TruckID" 
            class="form-control">
                <option value="">Select</option>
                <option *ngFor="let item of truckList" value="{{item.TruckID}}">{{item.TruckName}}</option>
            </select>    
        </div>         
        <div class="form-group col-md-2">
            <label>Costo</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">MXN</div>
                </div> 
                <input name="FreightCost" #FreightCost="ngModel" [(ngModel)]="freightService.freightList.FreightCost" 
                class="form-control">  
            </div>
        </div>

    </div>  

    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i> Guardar</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i> Cerrar</button>
        
    </div>
</form>
 
