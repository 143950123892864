<h4 class="display-5">Mantenimiento de Resistencias </h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <input type="hidden" name="BoardFlute" #BoardFlute="ngModel" [(ngModel)]="boardService.boardList.BoardFlute">
    <input type="hidden" name="NumBoard" #NumBoard="ngModel" [(ngModel)]="boardService.boardList.NumBoard">
    <div class="form-row">
        <div class="form-group col-md-2">
            <label style="font-weight: bold; color: darkgreen; background-color: lightgreen;">Resistencia: {{boardService.boardList.TypePapers}}</label> 
            <input name="BoardID" #BoardID="ngModel" [(ngModel)]="boardService.boardList.BoardID" class="form-control"
            readonly style="font-weight: bold;">  
        </div>
        <div class="form-group col-md-1">
            <label>Alternativa</label>
            <input name="Alternative" #Alternative="ngModel" [(ngModel)]="boardService.boardList.Alternative" 
            class="form-control" readonly> 
        </div>
        <div class="form-group col-md-2">
            <label>Origen</label>
            <select name="SheetOrigin" id="SheetOrigin" #SheetOrigin="ngModel" [(ngModel)]="boardService.boardList.SheetOrigin" 
            class="form-control" (change)="onGetSupplier()">
                <option value="">-Select-</option>
                <option value="Propia">Propia</option>
                <option value="Terceros">Terceros</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Proveedor</label>
            <select name="SupplierID" #SupplierID="ngModel" [(ngModel)]="boardService.boardList.SupplierID" class="form-control"
            [class.is-invalid]="!isValid && boardService.boardList.SupplierID==0">
                <option value="0">-Select-</option>
                <option *ngFor="let item of supplierList" value={{item.SupplierID}}>{{item.SupplierName}}</option>
            </select>
        </div>
        <div class="form-group col-md-1">
            <label>Flauta</label>
            <select name="FluteID" id="FluteID" #FluteID="ngModel" [(ngModel)]="boardService.boardList.FluteID" class="form-control"
                (change)="onGetBoardFlute($event.target)">
                <option value=0>-Select-</option>
                <option *ngFor="let item of fluteList" value="{{item.FluteID}}">{{item.FluteNomenclature}}</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>ECT</label>
            <div class="input-group">
                <select name="ECT" id="ECT" #ECT="ngModel" [(ngModel)]="boardService.boardList.ECT" class="form-control">
                    <option value=0>-Select-</option>
                    <option *ngFor="let item of ectList" value="{{item.EctNumber}}">{{item.EctNumber}}</option>
                </select>
                <div class="input-group-prepend">
                    <div class="input-group-text">lb/pulg</div>
                </div>
            </div>   
        </div>
        <div class="form-group col-md-2">
            <label>Papel Exterior</label>
            <select name="ColorPaperExt" id="ColorPaperExt" #ColorPaperExt="ngModel" [(ngModel)]="boardService.boardList.ColorPaperExt" 
                class="form-control">
                <option value="">-Select-</option>
                <option value="B">Blanco</option>
                <option value="K">Kraft</option>
            </select>  
        </div>
    </div>  

    <div class="form-row">
        <div class="form-group col-md-2">
            <label>Tipo de Mercado</label>
            <select name="TypeMarket" id="TypeMarket" #TypeMarket="ngModel" [(ngModel)]="boardService.boardList.TypeMarket" 
            class="form-control">
                <option value="">-Select-</option>
                <option value="MI">Industrial</option>
                <option value="MA">Agrícola</option>
                <option value="MO">Offset</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Tipo de Adhesivo</label>
            <select name="TypeStarch" id="TypeStarch" #TypeStarch="ngModel" [(ngModel)]="boardService.boardList.TypeStarch" 
            class="form-control">
                <option value="">-Select-</option>
                <option value="AI">Industrial</option>
                <option value="AA">Agrícola</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Recubrimiento</label>
            <select name="CoatingTo" id="CoatingTo" #CoatingTo="ngModel" [(ngModel)]="boardService.boardList.CoatingTo" class="form-control"
            (change)="onGetCoating()">
                <option value="">-Select-</option>
                <option value="XX">NA</option>
                <option value="RL">Liner</option>
                <option value="RLM">Liner_Medium</option>
                <option value="RLMM">Liner_Medium_Medium</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Recubrimiento a Flauta</label>
            <select name="FluteCoating" id="FluteCoating" #FluteCoating="ngModel" [(ngModel)]="boardService.boardList.FluteCoating" 
            class="form-control">
                <option value="">NULL</option>
                <option *ngFor="let item of fluteList" value="{{item.FluteNomenclature}}">{{item.FluteNomenclature}}</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label (click)="onCalculatedDensity()" style="font-weight: bold;">ECT Estimado</label>
            <div class="input-group">
                <input name="EstimatedECT" #EstimatedECT="ngModel" [(ngModel)]="boardService.boardList.EstimatedECT" class="form-control"
                readonly type="number" min="0"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">lb/pulg</div>
                </div>
            </div>  
        </div>
        <div class="form-group col-md-2">
            <label>Gramaje</label>
            <div class="input-group">
                <input name="Density" #Density="ngModel" [(ngModel)]="boardService.boardList.Density"
                class="form-control" type="number" min="0"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">gr/m2</div>
                </div>
            </div>  
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-md-2">
            <label>Liner 01</label>
            <select name="PaperID01" id="PaperID01" #PaperID01="ngModel" [(ngModel)]="boardService.boardList.PaperID01" 
            class="form-control">
                <option value="">-Select-</option>
                <option *ngFor="let item of paperList" value="{{item.PaperNomenclature}}">{{item.PaperNomenclature}}</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Medium 01</label>
            <select name="PaperID02" id="PaperID02" #PaperID02="ngModel" [(ngModel)]="boardService.boardList.PaperID02" class="form-control"
                >
                <option value="">-Select-</option>
                <option *ngFor="let item of paperList" value="{{item.PaperNomenclature}}">{{item.PaperNomenclature}}</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Liner 02</label>
            <select name="PaperID03" id="PaperID03" #PaperID03="ngModel" [(ngModel)]="boardService.boardList.PaperID03" class="form-control">
                <option value="">-Select-</option>
                <option *ngFor="let item of paperList" value="{{item.PaperNomenclature}}">{{item.PaperNomenclature}}</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Medium 02</label>
            <select name="PaperID04" id="PaperID04" #PaperID04="ngModel" [(ngModel)]="boardService.boardList.PaperID04" class="form-control">
                <option value="">-Select-</option>
                <option *ngFor="let item of paperList" value="{{item.PaperNomenclature}}">{{item.PaperNomenclature}}</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Liner 03</label>
            <select name="PaperID05" id="PaperID05" #PaperID05="ngModel" [(ngModel)]="boardService.boardList.PaperID05" class="form-control">
                <option value="">-Select-</option>
                <option *ngFor="let item of paperList" value="{{item.PaperNomenclature}}">{{item.PaperNomenclature}}</option>
            </select>  
        </div>
        <div class="form-group col-md-2" style="text-align: center;">
            <label>Recubrimiento</label>
            <div class="form-group col-md-2" style="text-align: center;">
            <input style="width: 200px; height: 30px;" type="checkbox" name="Coating" #Coating="ngModel" [(ngModel)]="boardService.boardList.Coating"> 
        </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-2">
            <label>Calibre</label>
            <div class="input-group">
                <input name="Thickness" #Thickness="ngModel" [(ngModel)]="boardService.boardList.Thickness"
                class="form-control" type="number" min="0" readonly> 
                <div class="input-group-prepend">
                    <div class="input-group-text">mm</div>
                </div>
            </div>  
        </div>
        <div class="form-group col-md-2">
            <label>Tipo de Densidad</label>
            <select name="TypeDensity" id="TypeDensity" #TypeDensity="ngModel" [(ngModel)]="boardService.boardList.TypeDensity" 
            class="form-control" disabled>
                <option value="">-Select-</option>
                <option value=0>Baja</option>
                <option value=1>Media</option>
                <option value=2>Alta</option>
            </select>
        </div>
        <div class="form-group col-md-2">
            <label>Fecha</label>
            <input name="BoardDate" #BoardDate="ngModel" [(ngModel)]="boardService.boardList.BoardDate" class="form-control" readonly>
        </div>     
        <div class="form-group col-md-2">
            <label>Merma</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">%</div>
                </div>
                <input name="WasteCorrugatorSTD" id="WasteCorrugatorSTD" #WasteCorrugatorSTD="ngModel" 
                [(ngModel)]="boardService.boardList.WasteCorrugatorSTD" type="number" min="0" class="form-control">  
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Corrugadora</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">%</div>
                </div>
                <input name="WasteCorrugatorOtherApp" id="WasteCorrugatorOtherApp" #WasteCorrugatorOtherApp="ngModel" 
                [(ngModel)]="boardService.boardList.WasteCorrugatorOtherApp" type="number" min="0" class="form-control">  
            </div>
        </div>
        <div class="form-group col-md-2" style="text-align: center;">
            <label>Aprobado</label>
            <div class="form-group col-md-2" style="text-align: center;">
                <input style="width: 200px; height: 30px;" type="checkbox" name="ApprovedBoard" #ApprovedBoard="ngModel" [(ngModel)]="boardService.boardList.ApprovedBoard" > 
            </div>    
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-12">
            <label>Notas</label>
            <input name="Note" #Note="ngModel" [(ngModel)]="boardService.boardList.Note"
            class="form-control">  
        </div>
    </div>
    <!--test
    
    -->
    <div class="form-row" *ngIf="boardService.boardList.SheetOrigin == 'Terceros'">
        <form class="border border-success rounded form-group col-md-5" style="font-weight: bold; font-size: small;">Tipos de Papeles:
            &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blanco 
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Virgen 
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reciclado
            <br>
            <br>
            <label class="col-md-3" style="vertical-align: middle;">Liner exterior</label>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Liner10" id="Liner10" value="B"
                #Liner10="ngModel" [(ngModel)]="boardService.boardList.Liner10"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Liner10" id="Liner11" value="V"
                #Liner10="ngModel" [(ngModel)]="boardService.boardList.Liner10"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Liner10" id="Liner12" value="R"
                #Liner10="ngModel" [(ngModel)]="boardService.boardList.Liner10"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
            <br>
            <label class="col-md-3" style="vertical-align: middle;">Medium exterior</label>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Medium10" id="Medium10" value="B"
                #Medium10="ngModel" [(ngModel)]="boardService.boardList.Medium10"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Medium10" id="Medium11" value="V"
                #Medium10="ngModel" [(ngModel)]="boardService.boardList.Medium10"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Medium10" id="Medium12" value="R"
                #Medium10="ngModel" [(ngModel)]="boardService.boardList.Medium10"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
            <br>
            <label class="col-md-3" style="vertical-align: middle;">Liner central</label>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Liner20" id="Liner20" value="B"
                #Liner20="ngModel" [(ngModel)]="boardService.boardList.Liner20"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Liner20" id="Liner21" value="V"
                #Liner20="ngModel" [(ngModel)]="boardService.boardList.Liner20"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Liner20" id="Liner23" value="R"
                #Liner20="ngModel" [(ngModel)]="boardService.boardList.Liner20"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
            <br>
            <label class="col-md-3" style="vertical-align: middle;">Medium interior</label>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Medium20" id="Medium20" value="B"
                #Medium20="ngModel" [(ngModel)]="boardService.boardList.Medium20"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Medium20" id="Medium21" value="V"
                #Medium20="ngModel" [(ngModel)]="boardService.boardList.Medium20"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Medium20" id="Medium22" value="R"
                #Medium20="ngModel" [(ngModel)]="boardService.boardList.Medium20"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
            <br>
            <label class="col-md-3" style="vertical-align: middle;">Liner interior</label>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Liner30" id="Liner30" value="B"
                #Liner30="ngModel" [(ngModel)]="boardService.boardList.Liner30"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Liner30" id="Liner31" value="V"
                #Liner30="ngModel" [(ngModel)]="boardService.boardList.Liner30"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
            <div class="form-check form-check-inline col-md-2">
                <input class="form-check-input" type="radio" name="Liner30" id="Liner32" value="R"
                #Liner30="ngModel" [(ngModel)]="boardService.boardList.Liner30"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;">
            </div>
        </form>
          
        <!-- <div class="form-group col-md-7">
            <label>Notas</label>
            <textarea name="Note" #Note="ngModel" [(ngModel)]="boardService.boardList.Note"
            class="form-control" style="height: 165px;" maxlength="500"></textarea> 
        </div> -->
    </div>
    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i> Guardar</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i> Cerrar</button>
    </div>
</form>
 
