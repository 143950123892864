import { Component } from '@angular/core';
import { QuoteService } from './shared/quote.service';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
//arreglo con los datos obtenidos de azure
type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
  //styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Corrugated Quote';
  public uid;
  profile!: ProfileType;

  constructor(public service: QuoteService,
    private authservice: MsalService,
    private http: HttpClient) {}
    
    ngOnInit(){
      this.getProfile();
    }
  
    getProfile() {
      this.http.get(GRAPH_ENDPOINT)
        .subscribe(profile => {
          this.profile = profile;
          //console.log(profile['id']);
          this.uid = profile['id'];
        });
    }
    
    logout(){
      this.authservice.logoutRedirect({
        //postLogoutRedirectUri: 'https://localhost:4200/quotehome'
        postLogoutRedirectUri: 'https://carton2me.cartonero.digital/quotehome'
      });
    }
  /* onAppQuoteBox(){
    this.service.viewBoxSheetBotton = true;
  }; */

  //onAppQuoteSheet(){
  //  this.service.qBoxOrSheet = false;
  //};
};


