<h4 class="display-5">Mantenimiento de Maquinas </h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <div class="form-row">
        <div class="form-group col-md-1">
            <label>ID</label>
            <input name="MachineID" #MachineID="ngModel" [(ngModel)]="workstationService.workstationList.MachineID" 
            class="form-control" type="text">  
        </div>
        <div class="form-group col-md-3">
            <label>Nombre</label>
            <input name="MachineName" #MachineName="ngModel" [(ngModel)]="workstationService.workstationList.MachineName" 
            class="form-control">  
        </div>
        <div class="form-group col-md-2">
            <label>Tipo</label>
            <input name="Type" id="Type" #Type="ngModel" [(ngModel)]="workstationService.workstationList.Type" 
            class="form-control">  
        </div>  
        <div class="form-group col-md-2">
            <label>Trim de Corrugadora</label>
            <div class="input-group">
                <input name="TrimCorrugator" id="TrimCorrugator" #TrimCorrugator="ngModel" [(ngModel)]="workstationService.workstationList.TrimCorrugator" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">cm</div>
                </div> 
            </div>
        </div>       
        <div class="form-group col-md-2">
            <label>Eficiencia</label>
            <div class="input-group">
                <input name="OperatingEfficiency" id="OperatingEfficiency" #OperatingEfficiency="ngModel" [(ngModel)]="workstationService.workstationList.OperatingEfficiency" 
                type="number" min="0" class="form-control" style="text-align: right;">
                <div class="input-group-prepend">
                    <div class="input-group-text">%</div>
                </div>
            </div>
        </div>
    </div>  
    <div class="form-row">
        <form class="border border-primary rounded form-group col-md-7" style="font-weight: bold; font-size: small;">Información del Fabricante
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label class="small">Fabricado por</label>
                    <input name="ManufacturedBy" #ManufacturedBy="ngModel" [(ngModel)]="workstationService.workstationList.ManufacturedBy" 
                    class="form-control">  
                </div>
                <div class="form-group col-md-3">
                    <label class="small">Marca</label>
                    <input name="Brand" #Brand="ngModel" [(ngModel)]="workstationService.workstationList.Brand" class="form-control">  
                </div>
                <div class="form-group col-md-3">
                    <label class="small">Modelo</label>
                    <input name="Model" id="Model" #Model="ngModel" [(ngModel)]="workstationService.workstationList.Model" 
                    class="form-control">  
                </div>
                <div class="form-group col-md-2">
                    <label class="small">Colores</label>
                    <input name="NumColor" id="NumColor" #NumColor="ngModel" [(ngModel)]="workstationService.workstationList.NumColor" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
            </div>
        </form>
        <form class="border border-primary rounded form-group col-md-5" style="font-weight: bold; font-size: small;">Máximos y Mínimos
            <div class="form-row">
                <div class="form-group col-md-3">
                    <label class="small">Ancho mínimo</label>
                    <div class="input-group">
                        <input name="WidthMin" id="WidthMin" #WidthMin="ngModel" [(ngModel)]="workstationService.workstationList.WidthMin" 
                        class="form-control" type="number" min="0" style="text-align: right;">
                        <div class="input-group-prepend">
                            <div class="input-group-text">cm</div>
                        </div> 
                    </div>  
                </div>
                <div class="form-group col-md-3">
                    <label class="small">Ancho máximo</label>
                    <div class="input-group">
                        <input name="WidthMax" id="WidthMax" #WidthMax="ngModel" [(ngModel)]="workstationService.workstationList.WidthMax" 
                        class="form-control" type="number" min="0" style="text-align: right;">
                        <div class="input-group-prepend">
                            <div class="input-group-text">cm</div>
                        </div> 
                    </div>  
                </div>
                <div class="form-group col-md-3">
                    <label class="small">Largo mínimo</label>
                    <div class="input-group">
                        <input name="LenghtMin" id="LenghtMin" #LenghtMin="ngModel" [(ngModel)]="workstationService.workstationList.LenghtMin" 
                        class="form-control" type="number" min="0" style="text-align: right;">
                        <div class="input-group-prepend">
                            <div class="input-group-text">cm</div>
                        </div> 
                    </div>  
                </div>
                <div class="form-group col-md-3">
                    <label class="small">Largo máximo</label>
                    <div class="input-group">
                        <input name="LenghtMax" id="LenghtMax" #LenghtMax="ngModel" [(ngModel)]="workstationService.workstationList.LenghtMax" 
                        class="form-control" type="number" min="0" style="text-align: right;">
                        <div class="input-group-prepend">
                            <div class="input-group-text">cm</div>
                        </div> 
                    </div>  
                </div>
            </div>
        </form>
    </div>

    <hr>
    <h5 class="display-7">Velocidad MPM/GPM </h5>
    <br>
    <div class="form-row">
        <form class="border border-primary rounded form-group col-md-4" style="font-weight: bold; font-size: small;">Registro de Impresión Alto
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label class="small">Gramaje del Cartón Alto</label>
                    <input name="SpeedRegHigDenHig" id="SpeedRegHigDenHig" #SpeedRegHigDenHig="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegHigDenHig" 
                        class="form-control" type="number" min="0" style="text-align: right;">
                </div>
                <div class="form-group col-md-4">
                    <label class="small">Gramaje del Cartón Medio</label>
                    <input name="SpeedRegHigDenMed" id="SpeedRegHigDenMed" #SpeedRegHigDenMed="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegHigDenMed" 
                    class="form-control" type="number" min="0"style="text-align: right;" >
                </div>
                <div class="form-group col-md-4">
                    <label class="small">Gramaje del Cartón Bajo</label>
                    <input name="SpeedRegHigDenLow" id="SpeedRegHigDenLow" #SpeedRegHigDenLow="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegHigDenLow" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
            </div>
        </form>
        <form class="border border-primary rounded form-group col-md-4" style="font-weight: bold; font-size: small;">Registro de Impresión Medio
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label class="small">Gramaje del Cartón Alto</label>
                    <input name="SpeedRegMedDenHig" id="SpeedRegMedDenHig" #SpeedRegMedDenHig="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegMedDenHig" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
                <div class="form-group col-md-4">
                    <label class="small">Gramaje del Cartón Medio</label>
                    <input name="SpeedRegMedDenMed" id="SpeedRegMedDenMed" #SpeedRegMedDenMed="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegMedDenMed" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
                <div class="form-group col-md-4">
                    <label class="small">Gramaje del Cartón Bajo</label>
                    <input name="SpeedRegMedDenLow" id="SpeedRegMedDenLow" #SpeedRegMedDenLow="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegMedDenLow" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
            </div>
        </form>
        <form class="border border-primary rounded form-group col-md-4" style="font-weight: bold; font-size: small;">Registro de Impresión Bajo
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label class="small">Gramaje del Cartón Alto</label>
                    <input name="SpeedRegLowDenHig" id="SpeedRegLowDenHig" #SpeedRegLowDenHig="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegLowDenHig" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
                <div class="form-group col-md-4">
                    <label class="small">Gramaje del Cartón Medio</label>
                    <input name="SpeedRegLowDenMed" id="SpeedRegLowDenMed" #SpeedRegLowDenMed="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegLowDenMed" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
                <div class="form-group col-md-4">
                    <label class="small">Gramaje del Cartón Bajo</label>
                    <input name="SpeedRegLowDenLow" id="SpeedRegLowDenLow" #SpeedRegLowDenLow="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegLowDenLow" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
            </div>
        </form>
    </div>
    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i> Guardar</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i> Cerrar</button>
    </div>
</form>
 
