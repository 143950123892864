<h4 class="display-5">Mantenimiento de Clientes </h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <div class="form-row">
        <div class="form-group col-md-2">
            <label>ID Cliente</label>
            <input name="CustomerID" #CustomerID="ngModel" [(ngModel)]="customerService.customerList.CustomerID" class="form-control"
            readonly>  
        </div>
        <div class="form-group col-md-2">
            <label>RFC</label>
            <input name="CustomerRFC" #CustomerRFC="ngModel" [(ngModel)]="customerService.customerList.CustomerRFC" class="form-control"
            ng-readonly="true" style="text-transform:uppercase"  placeholder="HHHH-123456-HH2" (keyup)="onvalidateRFC($event.target.value)" maxlength="13">  
        </div>
        <div class="form-group col-md-4" ng-init="otp=true">
            <label>Razón Social</label>
            <input name="CustomerName" id="CustomerName" #CustomerName="ngModel" [(ngModel)]="customerService.customerList.CustomerName" 
            class="form-control">  
        </div>         
        <div class="form-group col-md-4">
            <label>Nombre Comercial</label>
            <input name="CustomerTradeName" #CustomerTradeName="ngModel" [(ngModel)]="customerService.customerList.CustomerTradeName" 
            class="form-control">  
        </div>

    </div>  

    <div class="form-row">
        <div class="form-group col-md-4">
            <label>Nombre de Contacto</label>
            <input name="ContactName" #ContactName="ngModel" [(ngModel)]="customerService.customerList.ContactName" class="form-control">  
        </div>
        <div class="form-group col-md-4">
            <label>Puesto de Contacto</label>
            <input name="ContactPosition" id="ContactPosition" #ContactPosition="ngModel" [(ngModel)]="customerService.customerList.ContactPosition" class="form-control">  
        </div>
        <div class="form-group col-md-2">
            <label>País</label>
            <select name="CountryID" id="CountryID" #CountryID="ngModel" [(ngModel)]="customerService.customerList.CountryID" class="form-control"
                (change)="onSelectCountry($event.target.value)">
                <option value=0>-Select-</option>
                <option *ngFor="let item of countryList" value="{{item.CountryID}}">{{item.CountryName}}</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Estado</label>
            <select name="StateID" id="StateID" #StateID="ngModel" [(ngModel)]="customerService.customerList.StateID" class="form-control">
                <option value=0>-Select-</option>
                <option *ngFor="let item of stateList" value="{{item.StateID}}">{{item.StateName}}</option>
            </select>   
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-md-4">
            <label>Dirección</label>
            <input name="Address" id="Address" #Address="ngModel" [(ngModel)]="customerService.customerList.Address" class="form-control" placeholder="Calle, Numero, Municipio">  
        </div>
        <div class="form-group col-md-2">
            <label>Código Postal</label>
            <input name="ZipCode" id="ZipCode" #ZipCode="ngModel" [(ngModel)]="customerService.customerList.ZipCode" class="form-control">  
        </div>
        <div class="form-group col-md-2">
            <label>Teléfono</label>
            <input name="Telephone" id="Telephone" #Telephone="ngModel" [(ngModel)]="customerService.customerList.Telephone" class="form-control" 
            type="text" showMaskTyped = "true" mask = "(000) 000-0000">  
        </div> 
        <div class="form-group col-md-4">
            <label>E-mail</label>
            <input name="Email" id="Email" #Email="ngModel" [(ngModel)]="customerService.customerList.Email" 
            class="form-control" type="email" placeholder="name@example.com">  
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-2">
            <label>Consumo</label>
            <select name="CustomerType" id="CustomerType" #CustomerType="ngModel" [(ngModel)]="customerService.customerList.CustomerType" class="form-control">
                <option value="">-Select-</option>
                <option value="A">A-CONSUMO BAJO</option>
                <option value="B">B-CONSUMO MEDIO</option>
                <option value="C">C-CONSUMO ALTO</option>
            </select>
        </div>
        <div class="form-group col-md-2">
            <label>Rentabilidad Objetivo</label>
            <div class="input-group">
                <input name="TargetProfitability" id="TargetProfitability" #TargetProfitability="ngModel" [(ngModel)]="customerService.customerList.TargetProfitability" 
                type="number" min="0" class="form-control">  
                <div class="input-group-prepend">
                    <div class="input-group-text">%</div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i> Guardar</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i> Cerrar</button>
        
    </div>
</form>
 