<h4 class="display-5">Mantenimiento de Tarifas </h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <div class="form-row">
        <div class="form-group col-md-4">
            <label>Máquina</label>
            <input name="MachineID" #MachineID="ngModel" [(ngModel)]="costRateService.costRateList.MachineID" 
            class="form-control" type="text" readonly>  
        </div>
    </div>      
    <div class="form-row">
        <div class="form-group col-md-4">
            <label>Mano de Obra Directa</label>
            <div class="input-group">
                <input name="DirLaborCostHR" id="DirLaborCostHR" #DirLaborCostHR="ngModel" [(ngModel)]="costRateService.costRateList.DirLaborCostHR" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">MXN/hr</div>
                </div> 
            </div>
        </div>       
        <div class="form-group col-md-4">
            <label>Mano de Obra Indirecta</label>
            <div class="input-group">
                <input name="IndLaborCostHR" id="IndLaborCostHR" #IndLaborCostHR="ngModel" [(ngModel)]="costRateService.costRateList.IndLaborCostHR" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">MXN/hr</div>
                </div> 
            </div>
        </div> 
        <div class="form-group col-md-4">
            <label>Corporativo</label>
            <div class="input-group">
                <input name="CorpMiscCostHR" id="CorpMiscCostHR" #CorpMiscCostHR="ngModel" [(ngModel)]="costRateService.costRateList.CorpMiscCostHR" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">MXN/hr</div>
                </div> 
            </div>
        </div> 
    </div>
    <div class="form-row">
        <div class="form-group col-md-4">
            <label>Mantenimiento</label>
            <div class="input-group">
                <input name="MaintenanceCostHR" id="MaintenanceCostHR" #MaintenanceCostHR="ngModel" [(ngModel)]="costRateService.costRateList.MaintenanceCostHR" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">MXN/hr</div>
                </div> 
            </div>
        </div> 
        <div class="form-group col-md-4">
            <label>Energía</label>
            <div class="input-group">
                <input name="EnergyCostoHR" id="EnergyCostoHR" #EnergyCostoHR="ngModel" [(ngModel)]="costRateService.costRateList.EnergyCostoHR" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">MXN/hr</div>
                </div> 
            </div>
        </div> 
        <div class="form-group col-md-4">
            <label>Agua</label>
            <div class="input-group">
                <input name="WaterCostHR" id="WaterCostHR" #WaterCostHR="ngModel" [(ngModel)]="costRateService.costRateList.WaterCostHR" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">MXN/hr</div>
                </div> 
            </div>
        </div> 
    </div>
    <div class="form-row">
        <div class="form-group col-md-4">
            <label>Combustible</label>
            <div class="input-group">
                <input name="GasCostHR" id="GasCostHR" #GasCostHR="ngModel" [(ngModel)]="costRateService.costRateList.GasCostHR" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">MXN/hr</div>
                </div> 
            </div>
        </div> 
        <div class="form-group col-md-4">
            <label>Gastos Indirectos</label>
            <div class="input-group">
                <input name="IndManCostHR" id="IndManCostHR" #IndManCostHR="ngModel" [(ngModel)]="costRateService.costRateList.IndManCostHR" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">MXN/hr</div>
                </div> 
            </div>
        </div> 
    </div>
    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i> Guardar</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i> Cerrar</button>
    </div>
</form>
 
