<h4 class="display-5">Detalles de la Cotización</h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <input type="hidden" name="QuoteItemID" #QuoteItemID="ngModel" [(ngModel)]="formData.QuoteItemID">
    <input type="hidden" name="QuoteID" #QuoteID="ngModel" [(ngModel)]="formData.QuoteID">
    <input type="hidden" name="Overlap" #Overlap="ngModel" [(ngModel)]="formData.Overlap">
    <input type="hidden" name="WidthBoard" #WidthBoard="ngModel" [(ngModel)]="formData.WidthBoard" class="form-control">
    <input type="hidden" name="LengthBoard" #LengthBoard="ngModel" [(ngModel)]="formData.LengthBoard" class="form-control">
    <input type="hidden" name="FluteID" #FluteID="ngModel" [(ngModel)]="formData.FluteID">
    <input type="hidden" name="TapeID" #TapeID="ngModel" [(ngModel)]="formData.TapeID">
    <input type="hidden" name="TapeQuantity" #TapeQuantity="ngModel" [(ngModel)]="formData.TapeQuantity">
    <input type="hidden" name="InkNumber" #InkNumber="ngModel" [(ngModel)]="formData.InkNumber">
    <input type="hidden" name="InkID01" #InkID01="ngModel" [(ngModel)]="formData.InkID01">
    <input type="hidden" name="PerInk01" #PerInk01="ngModel" [(ngModel)]="formData.PerInk01">
    <input type="hidden" name="PerPrintingPlate01" #PerPrintingPlate01="ngModel" [(ngModel)]="formData.PerPrintingPlate01">
    <input type="hidden" name="InkID02" #InkID02="ngModel" [(ngModel)]="formData.InkID02">
    <input type="hidden" name="PerInk02" #PerInk02="ngModel" [(ngModel)]="formData.PerInk02">
    <input type="hidden" name="PerPrintingPlate02" #PerPrintingPlate02="ngModel" [(ngModel)]="formData.PerPrintingPlate02">
    <input type="hidden" name="InkID03" #InkID03="ngModel" [(ngModel)]="formData.InkID03">
    <input type="hidden" name="PerInk03" #PerInk03="ngModel" [(ngModel)]="formData.PerInk03">
    <input type="hidden" name="PerPrintingPlate03" #PerPrintingPlate03="ngModel" [(ngModel)]="formData.PerPrintingPlate03">
    <input type="hidden" name="InkID04" #InkID04="ngModel" [(ngModel)]="formData.InkID04">
    <input type="hidden" name="PerInk04" #PerInk04="ngModel" [(ngModel)]="formData.PerInk04">
    <input type="hidden" name="PerPrintingPlate04" #PerPrintingPlate04="ngModel" [(ngModel)]="formData.PerPrintingPlate04">
    <input type="hidden" name="InkID05" #InkID05="ngModel" [(ngModel)]="formData.InkID05">
    <input type="hidden" name="PerInk05" #PerInk05="ngModel" [(ngModel)]="formData.PerInk05">
    <input type="hidden" name="PerPrintingPlate05" #PerPrintingPlate05="ngModel" [(ngModel)]="formData.PerPrintingPlate05">
    <input type="hidden" name="InkID06" #InkID06="ngModel" [(ngModel)]="formData.InkID06">
    <input type="hidden" name="PerInk06" #PerInk06="ngModel" [(ngModel)]="formData.PerInk06">
    <input type="hidden" name="PerPrintingPlate06" #PerPrintingPlate06="ngModel" [(ngModel)]="formData.PerPrintingPlate06">
    <input type="hidden" name="ProductionTimeCorr" #ProductionTimeCorr="ngModel" [(ngModel)]="formData.ProductionTimeCorr">
    <input type="hidden" name="ProductionTimeConv" #ProductionTimeConv="ngModel" [(ngModel)]="formData.ProductionTimeConv">
    <input type="hidden" name="CostMP" #CostMP="ngModel" [(ngModel)]="formData.CostMP">
    <input type="hidden" name="CostMPNeto" #CostMPNeto="ngModel" [(ngModel)]="formData.CostMPNeto">
    <input type="hidden" name="CostAuxCorr" #CostAuxCorr="ngModel" [(ngModel)]="formData.CostAuxCorr">
    <input type="hidden" name="CostCorrToConv" #CostCorrToConv="ngModel" [(ngModel)]="formData.CostCorrToConv">
    <input type="hidden" name="CostFixed" #CostFixed="ngModel" [(ngModel)]="formData.CostFixed">
    <input type="hidden" name="SubTotCorrugator" #SubTotCorrugator="ngModel" [(ngModel)]="formData.SubTotCorrugator">
    <input type="hidden" name="CostMacConverting" #CostMacConverting="ngModel" [(ngModel)]="formData.CostMacConverting">
    <input type="hidden" name="CostInk" #CostInk="ngModel" [(ngModel)]="formData.CostInk">
    <input type="hidden" name="CostPlateTotal" #CostPlateTotal="ngModel" [(ngModel)]="formData.CostPlateTotal">
    <input type="hidden" name="CostDieTotal" #CostDieTotal="ngModel" [(ngModel)]="formData.CostDieTotal">
    <input type="hidden" name="CostPlate" #CostPlate="ngModel" [(ngModel)]="formData.CostPlate">
    <input type="hidden" name="CostDie" #CostDie="ngModel" [(ngModel)]="formData.CostDie">
    <input type="hidden" name="CostPVA" #CostPVA="ngModel" [(ngModel)]="formData.CostPVA">
    <input type="hidden" name="CostTape" #CostTape="ngModel" [(ngModel)]="formData.CostTape">
    <input type="hidden" name="CostCover" #CostCover="ngModel" [(ngModel)]="formData.CostCover">
    <input type="hidden" name="CostFilm" #CostFilm="ngModel" [(ngModel)]="formData.CostFilm">
    <input type="hidden" name="CostBoardPackaging" #CostBoardPackaging="ngModel" [(ngModel)]="formData.CostBoardPackaging">
    <input type="hidden" name="CostPallet" #CostPallet="ngModel" [(ngModel)]="formData.CostPallet">
    <input type="hidden" name="CostStrapping5mm" #CostStrapping5mm="ngModel" [(ngModel)]="formData.CostStrapping5mm">
    <input type="hidden" name="CostStrapping12mm" #CostStrapping12mm="ngModel" [(ngModel)]="formData.CostStrapping12mm">
    <input type="hidden" name="CostWasteConv" #CostWasteConv="ngModel" [(ngModel)]="formData.CostWasteConv">
    <input type="hidden" name="SubTotConverting" #SubTotConverting="ngModel" [(ngModel)]="formData.SubTotConverting">
    <input type="hidden" name="Total" #Total="ngModel" [(ngModel)]="formData.Total">
    <!-- consumptions materials -->
    <input type="hidden" name="ConsStarch" #ConsStarch="ngModel" [(ngModel)]="formData.ConsStarch">
    <input type="hidden" name="ConsCoating" #ConsCoating="ngModel" [(ngModel)]="formData.ConsCoating">
    <input type="hidden" name="ConsPVA" #ConsPVA="ngModel" [(ngModel)]="formData.ConsPVA">
    <input type="hidden" name="ConsDieRDC" #ConsDieRDC="ngModel" [(ngModel)]="formData.ConsDieRDC">
    <input type="hidden" name="ConsDieFDC" #ConsDieFDC="ngModel" [(ngModel)]="formData.ConsDieFDC">
    <input type="hidden" name="ConsStringKing" #ConsStringKing="ngModel" [(ngModel)]="formData.ConsStringKing">
    <input type="hidden" name="ConsOpenSesame" #ConsOpenSesame="ngModel" [(ngModel)]="formData.ConsOpenSesame">
    <input type="hidden" name="ConsCover" #ConsCover="ngModel" [(ngModel)]="formData.ConsCover">
    <input type="hidden" name="ConsInk01" #ConsInk01="ngModel" [(ngModel)]="formData.ConsInk01">
    <input type="hidden" name="ConsInk02" #ConsInk02="ngModel" [(ngModel)]="formData.ConsInk02">
    <input type="hidden" name="ConsInk03" #ConsInk03="ngModel" [(ngModel)]="formData.ConsInk03">
    <input type="hidden" name="ConsInk04" #ConsInk04="ngModel" [(ngModel)]="formData.ConsInk04">
    <input type="hidden" name="ConsInk05" #ConsInk05="ngModel" [(ngModel)]="formData.ConsInk05">
    <input type="hidden" name="ConsInk06" #ConsInk06="ngModel" [(ngModel)]="formData.ConsInk06">
    <input type="hidden" name="ConsPlate01" #ConsPlate01="ngModel" [(ngModel)]="formData.ConsPlate01">
    <input type="hidden" name="ConsPlate02" #ConsPlate02="ngModel" [(ngModel)]="formData.ConsPlate02">
    <input type="hidden" name="ConsPlate03" #ConsPlate03="ngModel" [(ngModel)]="formData.ConsPlate03">
    <input type="hidden" name="ConsPlate04" #ConsPlate04="ngModel" [(ngModel)]="formData.ConsPlate04">
    <input type="hidden" name="ConsPlate05" #ConsPlate05="ngModel" [(ngModel)]="formData.ConsPlate05">
    <input type="hidden" name="ConsPlate06" #ConsPlate06="ngModel" [(ngModel)]="formData.ConsPlate06">
    <input type="hidden" name="ConsStrapping5mm" #ConsStrapping5mm="ngModel" [(ngModel)]="formData.ConsStrapping5mm">
    <input type="hidden" name="ConsStrapping12mm" #ConsStrapping12mm="ngModel" [(ngModel)]="formData.ConsStrapping12mm">
    <input type="hidden" name="ConsStrechFilm" #ConsStrechFilm="ngModel" [(ngModel)]="formData.ConsStrechFilm">
    <input type="hidden" name="ConsPallet" #ConsPallet="ngModel" [(ngModel)]="formData.ConsPallet">
    <input type="hidden" name="ConsBoardPackaging" #ConsBoardPackaging="ngModel" [(ngModel)]="formData.ConsBoardPackaging">
    <input type="hidden" name="ConsWater" #ConsWater="ngModel" [(ngModel)]="formData.ConsWater">
    <input type="hidden" name="ConsGasForklift" #ConsGasForklift="ngModel" [(ngModel)]="formData.ConsGasForklift">
    <input type="hidden" name="ConsGasBoiler" #ConsGasBoiler="ngModel" [(ngModel)]="formData.ConsGasBoiler">

    <div class="form-row">
        <div class="form-group col-md-2">
            <label>Resistencia</label>
            <select name="BoardID" id="BoardID" #BoardID="ngModel" [(ngModel)]="formData.BoardID" class="form-control"
            (change)="updateToleranceBoard($event.target)" [class.is-invalid]="!isValid && formData.BoardID==null">
                <option value=null>-Select-</option>
                <option *ngFor="let board of boardList" value="{{board.BoardID}}">{{board.NumBoard}} {{board.BoardID}}</option>
            </select>
        </div>
        <div class="form-group col-md-2">
            <label>Scores</label>
            <div class="input-group">
                <input name="Score" id="Score" #Score="ngModel" [(ngModel)]="formData.Score" class="form-control" mask="000,000,000" type="text"    
                showMaskTyped = "true">
                <div class="input-group-prepend">
                    <div class="input-group-text">mm</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Ancho Lámina</label>
            <div class="input-group">
                <input name="Width" id="Width" #Width="ngModel" [(ngModel)]="formData.Width" class="form-control" type="number" min="1"
                (keyup)="onDevelopmentBox()" [class.is-invalid]="!isValid && formData.Width==0">
                <div class="input-group-prepend">
                    <div class="input-group-text">mm</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Largo Lámina</label>
            <div class="input-group">
                <input name="Length" id="Length" #Length="ngModel" [(ngModel)]="formData.Length" class="form-control" type="number" min="1"
                (keyup)="onDevelopmentBox()" [class.is-invalid]="!isValid && formData.Length==0">
                <div class="input-group-prepend">
                    <div class="input-group-text">mm</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Trim Adicional</label>
            <div class="input-group">
                <input name="AddTrim" #AddTrim="ngModel" [(ngModel)]="formData.AddTrim" class="form-control" type="number" min="1"
                (keyup)="onDevelopmentBox()">
                <div class="input-group-prepend">
                    <div class="input-group-text">%</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Area Lámina</label>
            <div class="input-group">
                <input name="BoxArea" #BoxArea="ngModel" [(ngModel)]="formData.BoxArea" class="form-control" readonly>
                <div class="input-group-prepend">
                    <div class="input-group-text">m2</div>
                </div>
            </div>
        </div>
    </div>

        <!--medidas de pieza-->
    <div class="form-row">    
        <div class="form-group col-md-2">
            <label>Gramaje</label>
            <div class="input-group">
                <input name="DensityBoard" #DensityBoard="ngModel" [(ngModel)]="formData.DensityBoard" class="form-control" readonly>
                <div class="input-group-prepend">
                    <div class="input-group-text">gr/m2</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Peso Lámina</label>
            <div class="input-group">
                <input name="BoxWeight" #BoxWeight="ngModel" [(ngModel)]="formData.BoxWeight" class="form-control" readonly>
                <div class="input-group-prepend">
                    <div class="input-group-text">gr</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Area Embarque</label>
            <div class="input-group">
                <input name="AreaShipment" #AreaShipment="ngModel" [(ngModel)]="formData.AreaShipment" class="form-control" readonly>
                <div class="input-group-prepend">
                    <div class="input-group-text">MM2</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Area Lámina Requerida</label>
            <div class="input-group">
                <input name="TotalAreaRequired" #TotalAreaRequired="ngModel" [(ngModel)]="formData.TotalAreaRequired" class="form-control" readonly>
                <div class="input-group-prepend">
                    <div class="input-group-text">m2</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Peso Lámina Requerida</label>
            <div class="input-group">
                <input name="TotalWeightRequired" #TotalWeightRequired="ngModel" [(ngModel)]="formData.TotalWeightRequired" class="form-control" readonly>
                <div class="input-group-prepend">
                    <div class="input-group-text">gr</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Niveles</label>
            <input name="Layer" #Layer="ngModel" [(ngModel)]="formData.Layer" class="form-control"
            [class.is-invalid]="!isValid && formData.Layer==0" type="number" min="1">
        </div>
    </div>
    <br>
    <!--end dimentions of box-->
<!--Tape and inks-->
    <div class="form-row">
        <form class="border border-primary rounded form-group col-md-3" style="font-weight: bold; font-size: small;">Cinta de Refuerzo
            <div class="form-row">
                <div class="form-group col-md-8">
                    <label class="small">Tipo</label>
                    <select name="TapeID" #TapeID="ngModel" [(ngModel)]="formData.TapeID" class="form-control"
                        (change) = "onChangeTape()" [class.is-invalid]="!isValid && formData.TapeID==0">
                        <option *ngFor="let tape of tapeList" value="{{tape.TapeID}}">{{tape.TapeName}}</option>
                    </select>
                </div>
                <div class="form-group col-md-4">
                    <label class="small">Cantidad</label>
                    <input name="TapeQuantity" #TapeQuantity="ngModel" [(ngModel)]="formData.TapeQuantity" class="form-control" type="number" min="0"
                    (keyup)="onDevelopmentBox()" (click)="onDevelopmentBox()" [class.is-invalid]="!isValid && formData.TapeQuantity==0">
                </div>
            </div>
        </form>
        <form class="border border-primary rounded form-group col-md-2" style="font-weight: bold; font-size: small;">Incluir Película Estirable:
            <div class="form-group col-md-12" style="text-align: center;">
                <label class="small"></label>
                <input style="width: 30px; height: 30px;" type="checkbox" name="StrechFilm" #StrechFilm="ngModel" [(ngModel)]="formData.StrechFilm" (click)="ongetCheckChange()"> 
            </div>
        </form>
        <form *ngIf = "mostrarPallet" class="border border-primary rounded form-group col-md-2" style="font-weight: bold; font-size: small;">Incluir Tarima:
            <div class="form-group col-md-12"style="text-align: center;">
                <label class="small"></label>
                <input style="width: 30px; height: 30px;" type="checkbox" name="Pallet" #Pallet="ngModel" [(ngModel)]="formData.Pallet"        (click)="ongetCheckChange()"> 
            </div>
        </form>
    </div>


    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i> Guardar</button>
        <button type="reset" class="btn btn-outline-dark ml-1" [mat-dialog-close]><i class="fa fa-close"></i> Cerrar</button>
    </div>
</form>

 
