<h4 class="display-5">Mantenimiento de Tarimas</h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <div class="form-row">
        <div class="form-group col-md-2">
            <label>Tarima ID</label>
            <input name="PalletID" #PalletID="ngModel" [(ngModel)]="palletService.palletList.PalletID" class="form-control"
            readonly>  
        </div>
        <div class="form-group col-md-10">
            <label>Nombre</label>
            <input name="PalletName" id="PalletName" #PalletName="ngModel" [(ngModel)]="palletService.palletList.PalletName" 
            class="form-control">  
        </div>   
    </div>  

    <div class="form-row">
        <div class="form-group col-md-3">
            <label>Ancho</label>
            <div class="input-group"> 
                <input name="Width" #Width="ngModel" [(ngModel)]="palletService.palletList.Width" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">cm</div>
                </div> 
            </div>
        </div>
        <div class="form-group col-md-3">
            <label>Largo</label>
            <div class="input-group"> 
                <input name="Length" #Length="ngModel" [(ngModel)]="palletService.palletList.Length" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">cm</div>
                </div> 
            </div>
        </div>
        <div class="form-group col-md-3">
            <label>Altura</label>
            <div class="input-group"> 
                <input name="Height" #Height="ngModel" [(ngModel)]="palletService.palletList.Height" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">cm</div>
                </div> 
            </div>
        </div>
        <div class="form-group col-md-3">
            <label>Costo</label>
            <div class="input-group"> 
                <input name="Cost" #Cost="ngModel" [(ngModel)]="palletService.palletList.Cost" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">MXN/Pza</div>
                </div> 
            </div>
        </div>
    </div>
    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i> Guardar</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i> Cerrar</button>
    </div>
</form>
 


