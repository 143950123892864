<h4 class="display-6">Símbolo Producto Cliente:&nbsp;  {{quoteService.formData.CustomerSKU}}</h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <input type="hidden" name="QuoteItemID" #QuoteItemID="ngModel" [(ngModel)]="formData.QuoteItemID">
    <input type="hidden" name="QuoteID" #QuoteID="ngModel" [(ngModel)]="formData.QuoteID">
    <input type="hidden" name="FluteID" #FluteID="ngModel" [(ngModel)]="formData.FluteID">
    <input type="hidden" name="TapeID" #TapeID="ngModel" [(ngModel)]="formData.TapeID">
    <input type="hidden" name="Output" #Output="ngModel" [(ngModel)]="formData.Output">
    <input type="hidden" name="outPutRDCL" #outPutRDCL="ngModel" [(ngModel)]="formData.outPutRDCL">
    <input type="hidden" name="outPutRDCW" #outPutRDCW="ngModel" [(ngModel)]="formData.outPutRDCW">
    <input type="hidden" name="TapeQuantity" #TapeQuantity="ngModel" [(ngModel)]="formData.TapeQuantity">
    <input type="hidden" name="InkNumber" #InkNumber="ngModel" [(ngModel)]="formData.InkNumber">
    <input type="hidden" name="InkID01" #InkID01="ngModel" [(ngModel)]="formData.InkID01">
    <input type="hidden" name="PerInk01" #PerInk01="ngModel" [(ngModel)]="formData.PerInk01">
    <input type="hidden" name="PerPrintingPlate01" #PerPrintingPlate01="ngModel" [(ngModel)]="formData.PerPrintingPlate01">
    <input type="hidden" name="InkID02" #InkID02="ngModel" [(ngModel)]="formData.InkID02">
    <input type="hidden" name="PerInk02" #PerInk02="ngModel" [(ngModel)]="formData.PerInk02">
    <input type="hidden" name="PerPrintingPlate02" #PerPrintingPlate02="ngModel" [(ngModel)]="formData.PerPrintingPlate02">
    <input type="hidden" name="InkID03" #InkID03="ngModel" [(ngModel)]="formData.InkID03">
    <input type="hidden" name="PerInk03" #PerInk03="ngModel" [(ngModel)]="formData.PerInk03">
    <input type="hidden" name="PerPrintingPlate03" #PerPrintingPlate03="ngModel" [(ngModel)]="formData.PerPrintingPlate03">
    <input type="hidden" name="InkID04" #InkID04="ngModel" [(ngModel)]="formData.InkID04">
    <input type="hidden" name="PerInk04" #PerInk04="ngModel" [(ngModel)]="formData.PerInk04">
    <input type="hidden" name="PerPrintingPlate04" #PerPrintingPlate04="ngModel" [(ngModel)]="formData.PerPrintingPlate04">
    <input type="hidden" name="InkID05" #InkID05="ngModel" [(ngModel)]="formData.InkID05">
    <input type="hidden" name="PerInk05" #PerInk05="ngModel" [(ngModel)]="formData.PerInk05">
    <input type="hidden" name="PerPrintingPlate05" #PerPrintingPlate05="ngModel" [(ngModel)]="formData.PerPrintingPlate05">
    <input type="hidden" name="InkID06" #InkID06="ngModel" [(ngModel)]="formData.InkID06">
    <input type="hidden" name="PerInk06" #PerInk06="ngModel" [(ngModel)]="formData.PerInk06">
    <input type="hidden" name="PerPrintingPlate06" #PerPrintingPlate06="ngModel" [(ngModel)]="formData.PerPrintingPlate06">
    <input type="hidden" name="ProductionTimeCorr" #ProductionTimeCorr="ngModel" [(ngModel)]="formData.ProductionTimeCorr">
    <input type="hidden" name="ProductionTimeConv" #ProductionTimeConv="ngModel" [(ngModel)]="formData.ProductionTimeConv">
    <input type="hidden" name="CostMP" #CostMP="ngModel" [(ngModel)]="formData.CostMP">
    <input type="hidden" name="CostMPNeto" #CostMPNeto="ngModel" [(ngModel)]="formData.CostMPNeto">
    <input type="hidden" name="CostAuxCorr" #CostAuxCorr="ngModel" [(ngModel)]="formData.CostAuxCorr">
    <input type="hidden" name="CostCorrToConv" #CostCorrToConv="ngModel" [(ngModel)]="formData.CostCorrToConv">
    <input type="hidden" name="CostFixed" #CostFixed="ngModel" [(ngModel)]="formData.CostFixed">
    <input type="hidden" name="SubTotCorrugator" #SubTotCorrugator="ngModel" [(ngModel)]="formData.SubTotCorrugator">
    <input type="hidden" name="CostMacConverting" #CostMacConverting="ngModel" [(ngModel)]="formData.CostMacConverting">
    <input type="hidden" name="CostInk" #CostInk="ngModel" [(ngModel)]="formData.CostInk">
    <input type="hidden" name="CostPlateTotal" #CostPlateTotal="ngModel" [(ngModel)]="formData.CostPlateTotal">
    <input type="hidden" name="CostDieTotal" #CostDieTotal="ngModel" [(ngModel)]="formData.CostDieTotal">
    <input type="hidden" name="CostPlate" #CostPlate="ngModel" [(ngModel)]="formData.CostPlate">
    <input type="hidden" name="CostDie" #CostDie="ngModel" [(ngModel)]="formData.CostDie">
    <input type="hidden" name="CostPVA" #CostPVA="ngModel" [(ngModel)]="formData.CostPVA">
    <input type="hidden" name="CostTape" #CostTape="ngModel" [(ngModel)]="formData.CostTape">
    <input type="hidden" name="CostCover" #CostCover="ngModel" [(ngModel)]="formData.CostCover">
    <input type="hidden" name="CostFilm" #CostFilm="ngModel" [(ngModel)]="formData.CostFilm">
    <input type="hidden" name="CostBoardPackaging" #CostBoardPackaging="ngModel" [(ngModel)]="formData.CostBoardPackaging">
    <input type="hidden" name="CostPallet" #CostPallet="ngModel" [(ngModel)]="formData.CostPallet">
    <input type="hidden" name="CostStrapping5mm" #CostStrapping5mm="ngModel" [(ngModel)]="formData.CostStrapping5mm">
    <input type="hidden" name="CostStrapping12mm" #CostStrapping12mm="ngModel" [(ngModel)]="formData.CostStrapping12mm">
    <input type="hidden" name="CostWasteConv" #CostWasteConv="ngModel" [(ngModel)]="formData.CostWasteConv">
    <input type="hidden" name="SubTotConverting" #SubTotConverting="ngModel" [(ngModel)]="formData.SubTotConverting">
    <input type="hidden" name="Total" #Total="ngModel" [(ngModel)]="formData.Total">
    <!-- consumptions materials -->
    <input type="hidden" name="ConsStarch" #ConsStarch="ngModel" [(ngModel)]="formData.ConsStarch">
    <input type="hidden" name="ConsCoating" #ConsCoating="ngModel" [(ngModel)]="formData.ConsCoating">
    <input type="hidden" name="ConsPVA" #ConsPVA="ngModel" [(ngModel)]="formData.ConsPVA">
    <input type="hidden" name="ConsDieRDC" #ConsDieRDC="ngModel" [(ngModel)]="formData.ConsDieRDC">
    <input type="hidden" name="ConsDieFDC" #ConsDieFDC="ngModel" [(ngModel)]="formData.ConsDieFDC">
    <input type="hidden" name="ConsStringKing" #ConsStringKing="ngModel" [(ngModel)]="formData.ConsStringKing">
    <input type="hidden" name="ConsOpenSesame" #ConsOpenSesame="ngModel" [(ngModel)]="formData.ConsOpenSesame">
    <input type="hidden" name="ConsCover" #ConsCover="ngModel" [(ngModel)]="formData.ConsCover">
    <input type="hidden" name="ConsInk01" #ConsInk01="ngModel" [(ngModel)]="formData.ConsInk01">
    <input type="hidden" name="ConsInk02" #ConsInk02="ngModel" [(ngModel)]="formData.ConsInk02">
    <input type="hidden" name="ConsInk03" #ConsInk03="ngModel" [(ngModel)]="formData.ConsInk03">
    <input type="hidden" name="ConsInk04" #ConsInk04="ngModel" [(ngModel)]="formData.ConsInk04">
    <input type="hidden" name="ConsInk05" #ConsInk05="ngModel" [(ngModel)]="formData.ConsInk05">
    <input type="hidden" name="ConsInk06" #ConsInk06="ngModel" [(ngModel)]="formData.ConsInk06">
    <input type="hidden" name="ConsPlate01" #ConsPlate01="ngModel" [(ngModel)]="formData.ConsPlate01">
    <input type="hidden" name="ConsPlate02" #ConsPlate02="ngModel" [(ngModel)]="formData.ConsPlate02">
    <input type="hidden" name="ConsPlate03" #ConsPlate03="ngModel" [(ngModel)]="formData.ConsPlate03">
    <input type="hidden" name="ConsPlate04" #ConsPlate04="ngModel" [(ngModel)]="formData.ConsPlate04">
    <input type="hidden" name="ConsPlate05" #ConsPlate05="ngModel" [(ngModel)]="formData.ConsPlate05">
    <input type="hidden" name="ConsPlate06" #ConsPlate06="ngModel" [(ngModel)]="formData.ConsPlate06">
    <input type="hidden" name="ConsStrapping5mm" #ConsStrapping5mm="ngModel" [(ngModel)]="formData.ConsStrapping5mm">
    <input type="hidden" name="ConsStrapping12mm" #ConsStrapping12mm="ngModel" [(ngModel)]="formData.ConsStrapping12mm">
    <input type="hidden" name="ConsStrechFilm" #ConsStrechFilm="ngModel" [(ngModel)]="formData.ConsStrechFilm">
    <input type="hidden" name="ConsPallet" #ConsPallet="ngModel" [(ngModel)]="formData.ConsPallet">
    <input type="hidden" name="ConsBoardPackaging" #ConsBoardPackaging="ngModel" [(ngModel)]="formData.ConsBoardPackaging">
    <input type="hidden" name="ConsWater" #ConsWater="ngModel" [(ngModel)]="formData.ConsWater">
    <input type="hidden" name="ConsGasForklift" #ConsGasForklift="ngModel" [(ngModel)]="formData.ConsGasForklift">
    <input type="hidden" name="ConsGasBoiler" #ConsGasBoiler="ngModel" [(ngModel)]="formData.ConsGasBoiler">

    <div class="form-row">
        <div class="form-group col-md-3">
            <label>Estilo</label>
            <select name="StyleID" id="StyleID" #StyleID="ngModel" [(ngModel)]="formData.StyleID" class="form-control" ng-model="mostrar"
            (change)="mostrarClosure()" (change)="onmostrarOverlap()" [class.is-invalid]="!isValid && formData.StyleID==0">
                <option value="0">-Select-</option>
                <option *ngFor="let item of styleList" value="{{item.StyleID}}">{{item.StyleName}}</option>
            </select>
        </div>
        <div *ngIf = "mostrarOverlap" class="form-group col-md-2">
            <label>Traslape</label>
            <div class="input-group">
                <input name="Overlap" #Overlap="ngModel" [(ngModel)]="formData.Overlap" class="form-control" type="number">
                <div class="input-group-prepend">
                    <div class="input-group-text">mm</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-3">
            <label>Resistencia</label>
            <select name="BoardID" id="BoardID" #BoardID="ngModel" [(ngModel)]="formData.BoardID" class="form-control"
            (change)="updateToleranceBoard($event.target)" [class.is-invalid]="!isValid && formData.BoardID==null">
                <option value=null>-Select-</option>
                <option *ngFor="let board of boardList" value="{{board.BoardID}}">{{board.NumBoard}} {{board.BoardID}}</option>
            </select>
        </div>
        <div class="form-group col-md-2">
            <label>Pegado</label>
            <select name="ClosureID" id="ClosureID" #ClosureID="ngModel" [(ngModel)]="formData.ClosureID" class="form-control"
            (change)="updateToleranceClosure($event.target, form)" [class.is-invalid]="!isValid && formData.ClosureID==0" >
                <option value="0">-Select-</option>
                <option *ngFor="let item of closureList" value="{{item.ClosureID}}">{{item.ClosureName}}</option>
            </select>
        </div>
        <div class="form-group col-md-1">
            <label>Registro</label>
            <select name="Register" id="Register" #Register="ngModel" [(ngModel)]="formData.Register" class="form-control"
            [class.is-invalid]="!isValid && formData.Register==''">
            <option value="">-Select-</option>
            <option value="Bajo">Bajo</option>
            <option value="Medio">Medio</option>
            <option value="Alto">Alto</option>
            </select>
        </div>
        <div class="form-group col-md-1">
            <label>Tintas</label>
            <select name="InkNumber" id="InkNumber" #InkNumber="ngModel" [(ngModel)]="formData.InkNumber" class="form-control"
            (change)="onValidateInk($event.target)" (focusout)="onValidateInkOffset()" [class.is-invalid]="!isValid && formData.InkNumber==0" >
                <option value="0">-Select-</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
            </select>
           <!-- <label>Tintas</label>s
            <input name="InkNumber" #InkNumber="ngModel" [(ngModel)]="formData.InkNumber" class="form-control" type="number" min="0" max="6"
            [class.is-invalid]="!isValid && formData.InkNumber==0">-->
        </div>
        <div *ngIf = "mostrarClosureID" class="form-group col-md-2">
            <label>Tipo de Troquel</label>
            <select name="DieType" id="DieType" #DieType="ngModel" [(ngModel)]="formData.DieType" class="form-control"
            [class.is-invalid]="!isValid && formData.DieType==''">
            <option value="">-Select-</option>
            <option *ngIf = "formData.StyleID==5" value="Curvo desplegado">Curvo desplegado</option>
            <option *ngIf = "formData.StyleID==5" value="Curvo desplegado complejo">Curvo desplegado complejo</option>
            <option *ngIf = "formData.StyleID==5" value="Curvo pegado">Curvo pegado</option>
            <option *ngIf = "formData.StyleID==7 || this.formData.StyleID == 11" value="Plano desplegado">Plano desplegado</option>
            <option *ngIf = "formData.StyleID==7 || this.formData.StyleID == 11" value="Plano desplegado complejo">Plano desplegado complejo</option>
            <option *ngIf = "formData.StyleID==7 || this.formData.StyleID == 11" value="Plano pegado">Plano pegado</option>
            <option value="NA">NA</option>
            </select>
        </div>

    </div>
    <!--dimentions of box-->
    <div class="form-row">
        <div class="form-group col-md-2">
            <label>Largo</label>
            <div class="input-group">
                <input name="Length" id="Length" #Length="ngModel" [(ngModel)]="formData.Length" class="form-control" type="number" min="1"
                (keyup)="onDevelopmentBox()" [class.is-invalid]="!isValid && formData.Length==0">
                <div class="input-group-prepend">
                    <div class="input-group-text">mm</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Ancho</label>
            <div class="input-group">
                <input name="Width" id="Width" #Width="ngModel" [(ngModel)]="formData.Width" class="form-control" type="number" min="1"
                (keyup)="onDevelopmentBox()" [class.is-invalid]="!isValid && formData.Width==0">
                <div class="input-group-prepend">
                    <div class="input-group-text">mm</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <div *ngIf = "!mostrarClosureID">
                <label>Altura</label>
                <div class="input-group">
                    <input name="Depth" id="Depth" #Depth="ngModel" [(ngModel)]="formData.Depth" class="form-control" type="number" min="1"
                    (keyup)="onDevelopmentBox()" [class.is-invalid]="!isValid && formData.Depth==0" ng-readonly="mostrar">
                    <div class="input-group-prepend">
                        <div class="input-group-text">mm</div>
                    </div>
                </div>
            </div>
            <div *ngIf = "mostrarClosureID">
                <label>Cajas/GPE</label>
                <div class="input-group">
                    <input name="Output" id="Output" #Output="ngModel" [(ngModel)]="formData.Output" class="form-control" type="number"
                    (focusout)="onDevelopmentBoxRDC()" ng-readonly="mostrar">
                    <div class="input-group-prepend">
                        <div class="input-group-text">Pzas</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Ancho de Lámina</label>
            <div class="input-group">
                <input name="WidthBoard" #WidthBoard="ngModel" [(ngModel)]="formData.WidthBoard" class="form-control" readonly
                [class.is-invalid]="!isValid && formData.WidthBoard==0">
                <div class="input-group-prepend">
                    <div class="input-group-text">mm</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Largo de Lámina</label>
            <div class="input-group">
                <input name="LengthBoard" #LengthBoard="ngModel" [(ngModel)]="formData.LengthBoard" class="form-control" readonly
                [class.is-invalid]="!isValid && formData.LengthBoard==0">
                <div class="input-group-prepend">
                    <div class="input-group-text">mm</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Trim Adicional</label>
            <div class="input-group">
                <input name="AddTrim" #AddTrim="ngModel" [(ngModel)]="formData.AddTrim" class="form-control" type="number" min="1"
                (keyup)="onDevelopmentBoxTrim()">
                <div class="input-group-prepend">
                    <div class="input-group-text">%</div>
                </div>
            </div>
        </div>
    </div>

        <!--medidas de pieza, OCULTAR-->
     
    <div class="form-row">    
        <div class="form-group col-md-2">
            <label>Área Caja</label>
            <div class="input-group">
                <input name="BoxArea" #BoxArea="ngModel" [(ngModel)]="formData.BoxArea" class="form-control" readonly>
                <div class="input-group-prepend">
                    <div class="input-group-text">m2</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Peso Caja</label>
            <div class="input-group">
                <input name="BoxWeight" #BoxWeight="ngModel" [(ngModel)]="formData.BoxWeight" class="form-control" readonly>
                <div class="input-group-prepend">
                    <div class="input-group-text">gr</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Gramaje Resistencia</label>
            <div class="input-group">
                <input name="DensityBoard" #DensityBoard="ngModel" [(ngModel)]="formData.DensityBoard" class="form-control" readonly>
                <div class="input-group-prepend">
                    <div class="input-group-text">gr/m2</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Área Embarque</label>
            <div class="input-group">
                <input name="AreaShipment" #AreaShipment="ngModel" [(ngModel)]="formData.AreaShipment" class="form-control" readonly>
                <div class="input-group-prepend">
                    <div class="input-group-text">MM2</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Área Caja Requerida</label>
            <div class="input-group">
                <input name="TotalAreaRequired" #TotalAreaRequired="ngModel" [(ngModel)]="formData.TotalAreaRequired" class="form-control" readonly>
                <div class="input-group-prepend">
                    <div class="input-group-text">m2</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Peso Caja Requerida</label>
            <div class="input-group">
                <input name="TotalWeightRequired" #TotalWeightRequired="ngModel" [(ngModel)]="formData.TotalWeightRequired" class="form-control" readonly>
                <div class="input-group-prepend">
                    <div class="input-group-text">gr</div>
                </div>
            </div>
        </div>
    </div>

    <!--end dimentions of box-->
<!--Tape and inks-->
 
<div class="form-row" >
        <!--begining inks-->
                <form class="border border-primary rounded form-group col-md-2" style="font-weight: bold; font-size: small;">Tinta 01
                    <div class="form-row" style="line-height: 50%;">
                        <div class="form-group col-md-12">
                            <div>
                                <select name="InkID01" id="InkID01" #InkID01="ngModel" [(ngModel)]="formData.InkID01" class="form-control"
                                (change)="onBoardCost()" aria-hidden="true" [class.is-invalid]="!isValid && formData.InkID01==0">
                                    <option value="0">-Select-</option>
                                    <option *ngFor="let ink of inkList" value="{{ink.InkID}}">{{ink.InkName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-row"  style="line-height: 50%;">
                        <div class="form-group col-md-6">
                            <div>
                                <label class="small">% Tinta01</label>
                                <input name="PerInk01" id="PerInk01" #PerInk01="ngModel" [(ngModel)]="formData.PerInk01" class="form-control" type="number" min="0" max="100"
                                aria-hidden="true" [class.is-invalid]="!isValid && formData.PerInk01==0">
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div>
                                <label class="small">% Placa01</label>
                                <input name="PerPrintingPlate01" id="PerPrintingPlate01" #PerPrintingPlate01="ngModel" [(ngModel)]="formData.PerPrintingPlate01" class="form-control" type="number" min="0" max="110"
                                aria-hidden="true" [class.is-invalid]="!isValid && formData.PerPrintingPlate01==0">
                            </div>
                        </div>
                    </div>
                </form>
                <form class="border border-primary rounded form-group col-md-2" style="font-weight: bold; font-size: small;"> Tinta 02
                    <div class="form-row" style="line-height: 50%;">
                        <div class="form-group col-md-12">
                            <div>
                                <select name="InkID02" id="InkID02" #InkID02="ngModel" [(ngModel)]="formData.InkID02" class="form-control"
                                (change)="onBoardCost()" aria-hidden="true" [class.is-invalid]="!isValid && formData.InkID02==0">
                                    <option value="0">-Select-</option>
                                    <option *ngFor="let ink of inkList" value="{{ink.InkID}}">{{ink.InkName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" style="line-height: 50%;">
                        <div class="form-group col-md-6">
                            <div>
                                <label class="small">% Tinta02</label>
                                <input name="PerInk02" id="PerInk02" #PerInk02="ngModel" [(ngModel)]="formData.PerInk02" class="form-control" type="number" min="0" max="100"
                                aria-hidden="true" [class.is-invalid]="!isValid && formData.PerInk02==0">
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div>
                                <label class="small">% Placa02</label>
                                <input name="PerPrintingPlate02" id="PerPrintingPlate02" #PerPrintingPlate02="ngModel" [(ngModel)]="formData.PerPrintingPlate02" class="form-control" type="number" min="0" max="110"
                                aria-hidden="true" [class.is-invalid]="!isValid && formData.PerPrintingPlate02==0">
                            </div>
                        </div>
                    </div>
                </form>
                <form class="border border-primary rounded form-group col-md-2" style="font-weight: bold; font-size: small;"> Tinta 03
                    <div class="form-row" style="line-height: 50%;">
                        <div class="form-group col-md-12">
                            <div>
                                <select name="InkID03" id="InkID03" #InkID03="ngModel" [(ngModel)]="formData.InkID03" class="form-control"
                                (change)="onBoardCost()" aria-hidden="true" [class.is-invalid]="!isValid && formData.InkID03==0">
                                    <option value="0">-Select-</option>
                                    <option *ngFor="let ink of inkList" value="{{ink.InkID}}">{{ink.InkName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" style="line-height: 50%;">
                        <div class="form-group col-md-6">
                            <div>
                                <label class="small">% Tinta03</label>
                                <input name="PerInk03" id="PerInk03" #PerInk03="ngModel" [(ngModel)]="formData.PerInk03" class="form-control" type="number" min="0" max="100"
                                aria-hidden="true" [class.is-invalid]="!isValid && formData.PerInk03==0">
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div>
                                <label class="small">% Placa03</label>
                                <input name="PerPrintingPlate03"  id="PerPrintingPlate03" #PerPrintingPlate03="ngModel" [(ngModel)]="formData.PerPrintingPlate03" class="form-control" type="number" min="0" max="110"
                                aria-hidden="true" [class.is-invalid]="!isValid && formData.PerPrintingPlate03==0">
                            </div>
                        </div>
                    </div>
                </form>    
                <form class="border border-primary rounded form-group col-md-2" style="font-weight: bold; font-size: small;"> Tinta 04
                    <div class="form-row" style="line-height: 50%;">
                        <div class="form-group col-md-12">
                            <div>
                                <select name="InkID04" id="InkID04" #InkID04="ngModel" [(ngModel)]="formData.InkID04" class="form-control"
                                (change)="onBoardCost()" aria-hidden="true" [class.is-invalid]="!isValid && formData.InkID04==0">
                                    <option value="0">-Select-</option>
                                    <option *ngFor="let ink of inkList" value="{{ink.InkID}}">{{ink.InkName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" style="line-height: 50%;">
                        <div class="form-group col-md-6">
                            <div>
                                <label class="small">% Tinta04</label>
                                <input name="PerInk04" id="PerInk04" #PerInk04="ngModel" [(ngModel)]="formData.PerInk04" class="form-control" type="number" min="0" max="100"
                                aria-hidden="true" [class.is-invalid]="!isValid && formData.PerInk04==0">
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div>
                                <label class="small">% Placa04</label>
                                <input name="PerPrintingPlate04"  id="PerPrintingPlate04" #PerPrintingPlate04="ngModel" [(ngModel)]="formData.PerPrintingPlate04" class="form-control" type="number" min="0" max="110"
                                aria-hidden="true" [class.is-invalid]="!isValid && formData.PerPrintingPlate04==0">
                            </div>
                        </div>
                    </div>
                </form>
                <form class="border border-primary rounded form-group col-md-2" style="font-weight: bold; font-size: small;"> Tinta 05
                    <div class="form-row" style="line-height: 50%;">
                        <div class="form-group col-md-12">
                            <div>
                                <select name="InkID05" id="InkID05" #InkID05="ngModel" [(ngModel)]="formData.InkID05" class="form-control"
                                (change)="onBoardCost()" aria-hidden="true" [class.is-invalid]="!isValid && formData.InkID05==0">
                                    <option value="0">-Select-</option>
                                    <option *ngFor="let ink of inkList" value="{{ink.InkID}}">{{ink.InkName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" style="line-height: 50%;">
                        <div class="form-group col-md-6">
                            <div>
                                <label class="small">% Tinta05</label>
                                <input name="PerInk05" id="PerInk05" #PerInk05="ngModel" [(ngModel)]="formData.PerInk05" class="form-control" type="number" min="0" max="100"
                                aria-hidden="true" [class.is-invalid]="!isValid && formData.PerInk05==0">
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div>
                                <label class="small">% Placa05</label>
                                <input name="PerPrintingPlate05" 
                                id="PerPrintingPlate05" #PerPrintingPlate05="ngModel" [(ngModel)]="formData.PerPrintingPlate05" class="form-control" type="number" min="0" max="110"
                                aria-hidden="true" [class.is-invalid]="!isValid && formData.PerPrintingPlate05==0">
                            </div>
                        </div>
                    </div>
                </form>
                <form class="border border-primary rounded form-group col-md-2" style="font-weight: bold; font-size: small;"> Tinta 06
                    <div class="form-row" style="line-height: 50%;">
                        <div class="form-group col-md-12">
                            <div>
                                <select name="InkID06" id="InkID06" #InkID04="ngModel" [(ngModel)]="formData.InkID06" class="form-control"
                                (change)="onBoardCost()" aria-hidden="true" [class.is-invalid]="!isValid && formData.InkID06==0">
                                    <option value="0">-Select-</option>
                                    <option *ngFor="let ink of inkList" value="{{ink.InkID}}">{{ink.InkName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" style="line-height: 50%;">
                        <div class="form-group col-md-6">
                            <div>
                                <label class="small">% Tinta06</label>
                                <input name="PerInk06" id="PerInk06" #PerInk06="ngModel" [(ngModel)]="formData.PerInk06" class="form-control" type="number" min="0" max="100"
                                aria-hidden="true" [class.is-invalid]="!isValid && formData.PerInk06==0">
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div>
                                <label class="small">% Placa06</label>
                                <input name="PerPrintingPlate06" id="PerPrintingPlate06" #PerPrintingPlate06="ngModel" [(ngModel)]="formData.PerPrintingPlate06" class="form-control" type="number" min="0" max="110"
                                aria-hidden="true" [class.is-invalid]="!isValid && formData.PerPrintingPlate06==0">
                            </div>
                        </div>
                    </div>
                </form>
    </div>

<!--end Tape and Inks-->

<!--begining Bundles-->
<div class="form-row">
    <form class="border border-primary rounded form-group col-md-3" style="font-weight: bold; font-size: small;">Cinta de Refuerzo
        <div class="form-row">
            <div class="form-group col-md-8">
                <label class="small">Tipo</label>
                <select name="TapeID" #TapeID="ngModel" [(ngModel)]="formData.TapeID" class="form-control"
                (change) = "onChangeTape()" [class.is-invalid]="!isValid && formData.TapeID==0">
                    <option *ngFor="let tape of tapeList" value="{{tape.TapeID}}">{{tape.TapeName}}</option>
                </select>
            </div>
            <div class="form-group col-md-4">
                <label class="small">Cantidad</label>
                <input name="TapeQuantity" #TapeQuantity="ngModel" [(ngModel)]="formData.TapeQuantity" class="form-control" type="number" min="0"
                (keyup)="onDevelopmentBox()" (click)="onDevelopmentBox()" [class.is-invalid]="!isValid && formData.TapeQuantity==0">
            </div>
        </div>
    </form>
    <form class="border border-primary rounded form-group col-md-5" style="font-weight: bold; font-size: small;"> Información de Atados
        <div class="form-row">
            <div class="form-group col-md-6">
                <label class="small">Dirección Fleje</label>
                <select name="StrapDirection" #StrapDirection="ngModel" [(ngModel)]="formData.StrapDirection" class="form-control"
                    (change)="onStrapping()" [class.is-invalid]="!isValid && formData.StrapDirection==''">
                    <option value="al ancho del atado">al ancho del atado</option>
                    <option value="al largo del atado">al largo del atado</option>
                    <option value="Sin Fleje">Sin Fleje</option>
                </select>
            </div>
            <div class="form-group col-md-3">
                <label class="small">Número de Flejes</label>
                <select name="StrapNumber" #StrapNumber="ngModel" [(ngModel)]="formData.StrapNumber" class="form-control"
                    (click)="onStrapping()" [class.is-invalid]="!isValid && formData.StrapNumber==0">
                    <option value=2>2</option>
                    <option value=1>1</option>
                    <option value=0>0</option>
                </select>
            </div>
            <div class="form-group col-md-3">
                <label class="small">Cajas/Atado</label>
                <select name="BoxBundle" #BoxBundle="ngModel" [(ngModel)]="formData.BoxBundle" class="form-control"
                (change) = "onCargoType()" [class.is-invalid]="!isValid && formData.BoxBundle==0">
                    <option value="0">-Select-</option>
                    <option value=10>10</option>
                    <option value=15>15</option>
                    <option value=20>20</option>
                    <option value=25>25</option>
                    <option value=30>30</option>
                    <option value=35>35</option>
                    <option value=40>40</option>
                    <option value=50>50</option>
                    <option value=60>60</option>
                </select>
            </div>
        </div>
    </form>    
    <form *ngIf = "quoteService.viewLayer" class="border border-primary rounded form-group col-md-4" style="font-weight: bold; font-size: small;">Información de Estiba - Tarima
        <div class="form-row">
            <div class="form-group col-md-4">
                <label class="small">Atados al Ancho</label>
                <input name="BundleWidthPallet" #BundleWidthPallet="ngModel" [(ngModel)]="formData.BundleWidthPallet" class="form-control"
                [class.is-invalid]="!isValid && formData.BundleWidthPallet==0" type="number" min="1"> 
            </div>
            <div class="form-group col-md-4">
                <label class="small">Atados al Largo</label>
                <input name="BundleAlongPallet" #BundleAlongPallet="ngModel" [(ngModel)]="formData.BundleAlongPallet" class="form-control"
                [class.is-invalid]="!isValid && formData.BundleAlongPallet==0" type="number" min="1">
            </div>
            <div class="form-group col-md-4">
                <label class="small">Niveles</label>
                <input name="Layer" #Layer="ngModel" [(ngModel)]="formData.Layer" class="form-control"
                [class.is-invalid]="!isValid && formData.Layer==0" type="number" min="1">
            </div>
        </div>
    </form>
</div>
<!--end Bundles-->

    <div class="form-row">
        <form class="border border-primary rounded form-group col-md-2" style="font-weight: bold; font-size: small;">Incluir Película Estirable:
            <div class="form-group col-md-12" style="text-align: center;">
                <input style="width: 30px; height: 30px;" type="checkbox" name="StrechFilm" #StrechFilm="ngModel" [(ngModel)]="formData.StrechFilm"  (click)="ongetCheckChange()">
            </div>
        </form>
        <form *ngIf = "mostrarPallet" class="border border-primary rounded form-group col-md-2" style="font-weight: bold; font-size: small;">Incluir Tarima:
            <div class="form-group col-md-12"  style="text-align: center;">
                <input style="width: 30px; height: 30px;" type="checkbox" name="Pallet" #Pallet="ngModel" [(ngModel)]="formData.Pallet"             (click)="ongetCheckChange()">
            </div>
        </form>
        <form class="border border-primary rounded form-group col-md-3" style="font-weight: bold; font-size: small;">Incluir Co$to de Grabado y Suaje:
            <div class="form-group col-md-12" style="text-align: center;">
                <input style="width: 30px; height: 30px;" type="checkbox" id="PlateDie" name="PlateDie" #PlateDie="ngModel" [(ngModel)]="formData.PlateDie" (click)="ongetCheckChange()">
            </div>
        </form>
        <div class="form-group col-md-5" style="text-align: right;">
            <button type="submit" class="btn btn-dark" ><i class="fa fa-database"></i> Guardar</button>
            <button type="reset" class="btn btn-outline-dark ml-1" [mat-dialog-close] (click)="onNewOrEditCancel()"><i class="fa fa-close"></i> Cerrar</button>
        </div>
    </div>
</form>
 