import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuoteItem } from 'src/app/shared/quote-item.model';
import { QuoteService } from 'src/app/shared/quote.service';
import { Board } from 'src/app/shared/board.model';
import { BoardService } from 'src/app/shared/board.service';
import { Boardcost } from 'src/app/shared/boardcost.model';
import { BoardcostService } from 'src/app/shared/boardcost.service';
import { Style } from 'src/app/shared/style.model';
import { Closure } from 'src/app/shared/closure.model';
import { DevelopmentService } from 'src/app/shared/development.service';
import { Development } from 'src/app/shared/development.model';
import { InkService } from 'src/app/shared/ink.service';
import { Ink } from 'src/app/shared/ink.model';
import { TapeService } from 'src/app/shared/tape.service';
import { Tape } from 'src/app/shared/tape.model';
import { WorkstationService } from 'src/app/shared/workstation.service';
import { Workstation } from 'src/app/shared/workstation.model';
import { CostrateService } from 'src/app/shared/costrate.service';
import { Costrate } from 'src/app/shared/costrate.model';
import { AuxiliaryService } from 'src/app/shared/auxiliary.service';
import { Auxiliary } from 'src/app/shared/auxiliary.model';
import { Product } from 'src/app/shared/product.model';
import { ProductService } from 'src/app/shared/product.service';

import { NgForm } from '@angular/forms';
//import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { ToastrService } from 'ngx-toastr';
//import { QuoteComponent } from '../quote/quote.component';
import { exit } from 'process';
import { StyleService } from 'src/app/shared/style.service';
import { ClosureService } from 'src/app/shared/closure.service';
import { TruckTypeService } from 'src/app/shared/TruckType.service';
import { TruckType } from 'src/app/shared/TruckType.model';

@Component({
  selector: 'app-costsummary',
  templateUrl: './costsummary.component.html',
  styles: []
})
export class CostsummaryComponent implements OnInit {
  formData: QuoteItem;
  boardList: Board [];
  boardCost: Boardcost;
  styleList: Style [];
  closureList: Closure [];
  developmentList: Development [];
  tapeList: Tape [];
  inkList: Ink [];
  costrateList: Costrate [];
  workstationList: Workstation [];
  auxiliaryList: Auxiliary [];
  productList: Product [];
  truckTypeList: TruckType [];

  isValid: boolean = true;
  mostrarPallet: boolean;
  mostrarClosureID: boolean;
  viewDepth: boolean;
  viewOutput: boolean;
  outPutDRC: number = 0;
  outPutRDCL: number;
  outPutRDCW: number;
  symbolCustomer: string;
  mostrarOverlap: boolean;
  inknumber2: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data,
    public dialogRef: MatDialogRef<CostsummaryComponent>,
    public quoteService: QuoteService, private boardService: BoardService, 
    private truckTypeService: TruckTypeService,
    private styleService: StyleService, private closureService: ClosureService, 
    private developmentService: DevelopmentService, private inkService: InkService, 
    private tapeService: TapeService, private boardcostService: BoardcostService, 
    private workstationService: WorkstationService, private costrateService: CostrateService, 
    private auxiliaryService: AuxiliaryService, private productService: ProductService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    
  }

}
