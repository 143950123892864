<!-- <br>
<br> -->
<div class="jumbotron jumbotron-fluid" style="text-align: center;">
  <table style="margin: 0 auto; margin-top: 12%;" >
    <tbody>
      <tr>
          <td>
              <img src="https://srvimagenes.azurewebsites.net/LogoC.png" width="380"/>
              <br>
              <br>
              <button type="button" class="btn btn" style="width: 150px; background-color:#1a039e ; color: white;" routerLink="/quote" (click)="onQuoteBox()">CAJA</button>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <button type="button" class="btn btn-outline" style="width: 150px; background-color:rgb(250, 5, 5); color: white" routerLink="/quote" (click)="onQuoteSheet()">LÁMINA</button>
          </td>
        </tr>
    </tbody>
  </table>
</div>