<h4>Lista de Fletes:</h4>
<table class="table table-hover">
    <thead class="thead-light">
        <th>ID</th>
        <th>Destino</th>
        <th>Tipo de Unidad</th>
        <th>Costo $</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" (click)="AddOrEditFreightItem(null, dataFreight.dataFreight.FreightID)"><i class="fa fa-plus-square"></i>  Nuevo</a>
        </th>
    </thead>
    <tbody>
        <!--tr*ngFor="let item of dataFreight.dataFreight; let i=index"-->
        <tr *ngFor="let item of paginatedData; let i=index">
            <th (click)="AddOrEditFreightItem(((currentPage-1)*itemsPerPage)+i, item.FreightID)">{{item.FreightID}}</th>
            <td>{{item.Address}}</td>
            <td>{{item.TruckName}}</td>
            <td style="text-align: right;">{{item.FreightCost}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditFreightItem(((currentPage-1)*itemsPerPage)+i, item.FreightID)"><i class="fa fa-pencil"></i></a>
                <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteFreightItem(item.FreightID)"><i class="fa fa-trash"></i></a>
            </td>
        </tr>
    </tbody>
</table>
<!--código de la paginación-->
<nav>
    <ul class="pagination"> <!--controla el recuadro de anterior-->
        <li class="page-item" style="cursor: pointer;">
            <a class="page-link"
            [ngClass]="{disabled:currentPage ===1 }" 
            (click)="pageClicked(currentPage - 1)">Anterior</a>
        </li>
        <li (click)="pageClicked(page)"
            *ngFor="let page of pages" 
            class="page-item" 
            [ngClass]="{ active: page == currentPage }">
            <a class="page-link" href="javascript:void(0)">{{ page }}</a><!--se pocisiona en la pagina y activa el cuadrito.-->
        </li>
        <li class="page-item" style="cursor: pointer;"> <!--controla el recuadro de siguiente-->
            <a class="page-link" 
            [ngClass]="{ disabled: currentPage === totalPages }" 
            (click)="pageClicked(currentPage + 1)">Siguiente</a>
        </li>
    </ul>
</nav>